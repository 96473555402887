<template>
  <v-overlay absolute :value="popOverlay" opacity="0.6">
    <v-card max-width="500px" max-height="800px">
      <v-data-table
    :headers="headers"
    :items="waypoints"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Area Coordinates</v-toolbar-title>
        <v-dialog
          v-model="dialog"
          max-width="400px"
        >
          <v-card outlined class="editCard">
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="8"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.lat"
                      label="Latitude"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="8"
                    sm="8"
                    md="6"
                  >
                    <v-text-field
                      v-model="editedItem.lon"
                      label="Longitude"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="ml-3"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
    </template>
    <template v-slot:no-data>
      No area selected
    </template>
  </v-data-table>
  <v-card-actions class="justify-center">
        <v-btn color="error" @click.stop="overlay">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import { areaLayer } from '@/esriAPI/EsriMap';
import { generateAreaFromPoints } from '@/esriAPI/AreaManager/AreaEditor';



export default {

    watch: {
      dialog (val) {
        val || this.close()
      },
    },

  methods: {
    initialize () {
      if(areaLayer.graphics.getItemAt(0)){
      const polygon = areaLayer.graphics.getItemAt(0).geometry;
      this.waypoints = [];

      for (let i = 0; i < polygon.rings[0].length-1; i++) {
        const point = polygon.getPoint(0, i);
        this.waypoints.push({
          index: i+1,
          lat: point.latitude.toFixed(9),
          lon: point.longitude.toFixed(9)
        });
      }
    }
    },
    overlay() {
      this.popOverlay = !this.popOverlay;
      if(this.popOverlay){
        this.initialize();
      }else{
        this.waypoints.push(this.waypoints[0]); //re add the last waypoint

        //revert formating
        let waypointsFormated = [];
        for (let i = 0; i < this.waypoints.length; i++) {
        waypointsFormated.push({
          lat:  parseFloat(this.waypoints[i].lat),
          lon: parseFloat(this.waypoints[i].lon)
        });
      }

        generateAreaFromPoints(waypointsFormated);
      }
    },

    editItem (item) {
        this.editedIndex = this.waypoints.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.waypoints[this.editedIndex], this.editedItem)
        } else {
          this.waypoints.push(this.editedItem)
        }
        this.close()
      },

  },
  data() {
    return {
      popOverlay:false,
      dialog: false,
      headers: [
      {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'index',
        },
      {
          text: 'Latitude',
          sortable: false,
          value: 'lat',
        },
        {
          text: 'Longitude',
          sortable: false,
          value: 'lon',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      waypoints: [],
      editedIndex: -1,
      editedItem: {
        lon:0,
        lat:0,
      },
      defaultItem: {
        lon:0,
        lat:0,
      },
    };
  },
};
</script>
<style scoped>
.table {
  background-color: rgb(53, 53, 53);
}

.editCard {
  background-color: rgb(63, 63, 63);
}

</style>


