<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Change Password</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-text-field
                type="password"
                v-model="password"
                :rules="passwordRules"
                label="Password"
                required
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="repeatPassword"
                :rules="repeatPasswordRules"
                label="Repeat Password"
                required
              ></v-text-field>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="changingPassword"
          @click="close"
        >Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid || changingPassword"
          @click="confirm"
        >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "ChangePassword",
  data() {
    return {
      passwordRules: [
        v => !!v || "Password is required.",
        v => (v && v.length <= 72) || "Maximum password length is 72 bytes."
      ],
      repeatPasswordRules: [
        v => !!v || "Repeat password is required.",
        v => (v && v.length <= 72) || "Maximum password length is 72 bytes.",
        v => (v === this.password) || "Passwords must be equal"
      ],
      valid: false,
      dialog: false,
      changingPassword: false,
      password: '',
      repeatPassword: '',
    }
  },
  props: {
    user: {},
  },
  methods: {
    close() {
      this.dialog = false;
      this.changingPassword = false;
      this.password = '';
      this.repeatPassword = '';
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.changingPassword = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.post(
          loc + "/auth/user/" + this.user.id,
          {
            password: this.password
          }
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't change password");
            this.close();
            return;
          }
  
          this.$emit('password-changed');
          this.close();
        }).catch((error) => {
          console.log(error);
          this.close();
          return;
        });
      }
    },
  }
}
</script>

<style>

</style>