<template>
  <v-fade-transition>
    <v-card
      v-if="selectedDrone != null"
      v-show="command != null"
      width="300"
      rounded="lg"
    >
      <!-- class="overflow-y-auto"      max-height="400" -->
      <v-container>
        <v-card-title class="h3 pl-1 py-0">
          <div v-if="ackStatus">
            <v-btn icon v-if="ackOk" :loading="loadingAck">
              <v-icon
                color="green"
                medium
                class="mdi mdi-checkbox-marked-circle"
              ></v-icon>
            </v-btn>

            <v-btn icon v-else :loading="loadingAck">
              <v-icon color="red" medium class="mdi mdi-close-circle"></v-icon>
            </v-btn>
          </div>

          {{ title }}</v-card-title
        >
        <v-divider class="pb-2"></v-divider>
        <v-card
          max-height="200"
          class="overflow-y-auto"
          flat
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-form v-model="valid" lazy-validation ref="form">
            <Takeoff
              v-if="command == 'takeoff'"
              ref="takeoff"
              v-on:command-parameters="sendCommand"
            />
            <Land
              v-if="command == 'land'"
              ref="land"
              v-on:command-parameters="sendCommand"
            />
            <Rth
              v-if="command == 'rth'"
              ref="rth"
              v-on:command-parameters="sendCommand"
            />
            <Loiter
              v-if="command == 'loiter'"
              ref="loiter"
              v-on:command-parameters="sendCommand"
            />
            <TurnYaw
              v-if="command == 'turnyaw'"
              ref="turnyaw"
              v-on:command-parameters="sendCommand"
            />
            <GimbalTurn
              v-if="command == 'gimbalturn'"
              ref="gimbalturn"
              v-on:command-parameters="sendCommand"
            />

            <ChangeSpeed
              v-if="command == 'changespeed' || command == 'updatespeed'"
              ref="changespeed"
              v-on:command-parameters="sendCommand"
            />
            <ChangeAltitude
              v-if="command == 'changealtitude'"
              ref="changealtitude"
              v-on:command-parameters="sendCommand"
            />
            <Upload
              v-if="command == 'upload'"
              ref="upload"
              v-on:command-parameters="sendCommand"
            />
            <Stop
              v-if="command == 'stop'"
              ref="stop"
              v-on:command-parameters="sendCommand"
            />
            <Start
              v-if="command == 'start'"
              ref="start"
              v-on:command-parameters="sendCommand"
            />
            <Pause
              v-if="command == 'pause'"
              ref="pause"
              v-on:command-parameters="sendCommand"
            />
            <Resume
              v-if="command == 'resume'"
              ref="resume"
              v-on:command-parameters="sendCommand"
            />

            <GoTo
              v-if="command == 'goto'"
              ref="goto"
              v-on:command-parameters="sendCommand"
            />

            <RadPoint
              v-if="command == 'radpoint'"
              ref="radpoint"
              v-on:command-parameters="sendCommand"
            />
            <RadStop
              v-if="command == 'radstop'"
              ref="radstop"
              v-on:command-parameters="sendCommand"
            />

            <CaptureInit
              v-if="command == 'captureinit'"
              ref="captureinit"
              v-on:command-parameters="sendCommand"
            />
            <CaptureStop
              v-if="command == 'capturestop'"
              ref="capturestop"
              v-on:command-parameters="sendCommand"
            />
            <SetHome
              v-if="command == 'sethome'"
              ref="sethome"
              v-on:command-parameters="sendCommand"
            />
          </v-form>
        </v-card>
        <v-card flat v-else>
          <v-form v-model="valid" lazy-validation ref="form">
            <Takeoff
              v-if="command == 'takeoff'"
              ref="takeoff"
              v-on:command-parameters="sendCommand"
            />
            <Land
              v-if="command == 'land'"
              ref="land"
              v-on:command-parameters="sendCommand"
            />
            <Rth
              v-if="command == 'rth'"
              ref="rth"
              v-on:command-parameters="sendCommand"
            />
            <Loiter
              v-if="command == 'loiter'"
              ref="loiter"
              v-on:command-parameters="sendCommand"
            />
            <TurnYaw
              v-if="command == 'turnyaw'"
              ref="turnyaw"
              v-on:command-parameters="sendCommand"
            />
            <GimbalTurn
              v-if="command == 'gimbalturn'"
              ref="gimbalturn"
              v-on:command-parameters="sendCommand"
            />

            <ChangeSpeed
              v-if="command == 'changespeed' || command == 'updatespeed'"
              ref="changespeed"
              v-on:command-parameters="sendCommand"
            />
            <ChangeAltitude
              v-if="command == 'changealtitude'"
              ref="changealtitude"
              v-on:command-parameters="sendCommand"
            />
            <Upload
              v-if="command == 'upload'"
              ref="upload"
              v-on:command-parameters="sendCommand"
            />
            <Stop
              v-if="command == 'stop'"
              ref="stop"
              v-on:command-parameters="sendCommand"
            />
            <Start
              v-if="command == 'start'"
              ref="start"
              v-on:command-parameters="sendCommand"
            />
            <Pause
              v-if="command == 'pause'"
              ref="pause"
              v-on:command-parameters="sendCommand"
            />
            <Resume
              v-if="command == 'resume'"
              ref="resume"
              v-on:command-parameters="sendCommand"
            />

            <GoTo
              v-if="command == 'goto'"
              ref="goto"
              v-on:command-parameters="sendCommand"
            />

            <RadPoint
              v-if="command == 'radpoint'"
              ref="radpoint"
              v-on:command-parameters="sendCommand"
            />

            <RadStop
              v-if="command == 'radstop'"
              ref="radstop"
              v-on:command-parameters="sendCommand"
            />
            <CaptureInit
              v-if="command == 'captureinit'"
              ref="captureinit"
              v-on:command-parameters="sendCommand"
            />
            <CaptureStop
              v-if="command == 'capturestop'"
              ref="capturestop"
              v-on:command-parameters="sendCommand"
            />

            <SetHome
              v-if="command == 'sethome'"
              ref="sethome"
              v-on:command-parameters="sendCommand"
            />
          </v-form>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click.stop="getParameters"
            :loading="sending"
            :disabled="sending || !valid || selectedDrone == null"
            >Send</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn small @click.stop="cancelCommand">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-fade-transition>
</template>

<script>
import Takeoff from "./CommandConfirmationDialogs/Takeoff";
import Land from "./CommandConfirmationDialogs/Land";
import Rth from "./CommandConfirmationDialogs/Rth";
import Loiter from "./CommandConfirmationDialogs/Loiter";
import TurnYaw from "./CommandConfirmationDialogs/TurnYaw";
import GimbalTurn from "./CommandConfirmationDialogs/GimbalTurn";

import ChangeSpeed from "./CommandConfirmationDialogs/ChangeSpeed";
import ChangeAltitude from "./CommandConfirmationDialogs/ChangeAltitude";
import Upload from "./CommandConfirmationDialogs/Upload";
import Stop from "./CommandConfirmationDialogs/Stop";
import Start from "./CommandConfirmationDialogs/Start";
import Pause from "./CommandConfirmationDialogs/Pause";
import Resume from "./CommandConfirmationDialogs/Resume";
import GoTo from "./CommandConfirmationDialogs/GoTo";
import RadPoint from "./CommandConfirmationDialogs/RadPoint";
import RadStop from "./CommandConfirmationDialogs/RadStop";
import CaptureInit from "./CommandConfirmationDialogs/CaptureInit";
import CaptureStop from "./CommandConfirmationDialogs/CaptureStop";

import SetHome from "./CommandConfirmationDialogs/SetHome.vue";

import { translateTitle, translateToInt } from "../utils/Commands";
import {
  SocketMessageTypes,
  WebSocketMessage,
  ackRecieved,
} from "../utils/WebsocketProtocol";

import { sendWebSocketMessage } from "@/websocket/websocket";

import { mapGetters } from "vuex";

export default {
  components: {
    Takeoff,
    Land,
    Rth,
    Loiter,
    TurnYaw,
    GimbalTurn,
    ChangeSpeed,
    ChangeAltitude,
    Upload,
    Stop,
    Start,
    Pause,
    Resume,
    GoTo,
    RadPoint,
    RadStop,
    CaptureInit,
    CaptureStop,
    SetHome,
  },

  data() {
    return {
      command: null,
      title: null,
      sending: false,
      valid: true,
      ackStatus: false,
      loadingAck: true,
      ackOk: true,
    };
  },

  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
    selectedDroneId: "addedDroneObject/getSelectedDroneId",
  }),
  watch: {
    command: function () {
      let that = this;
      setTimeout(function () {
        that.$refs.form.validate();
      }, 50);
    },
    selectedDroneId: function (val) {
      if (val) {
        let that = this;
        setTimeout(function () {
          that.$refs.form.validate();
        }, 50);
      }
    },
  },

  methods: {
    openCommandConfirmationDialog(command) {
      this.ackStatus = false;
      if (this.command == command) {
        this.command = null;
      } else {
        this.command = command;
        this.title = translateTitle(command);
      }
    },
    getParameters() {
      this.ackStatus = true;
      this.loadingAck = true;
      var refs = Object.values(this.$refs);
      refs.shift();
      refs.find((el) => el != Object.undefined).emitParameters();

      //this.sending = true;
    },

    cancelCommand() {
      this.ackStatus = false;
      this.command = null;
    },

    sendCommand(parameters) {
      parameters.systemId = this.selectedDrone.systemId;
      parameters.commandType = translateToInt(this.command);
      sendWebSocketMessage(
        new WebSocketMessage(SocketMessageTypes.COMMAND, parameters)
      );

      var component = this;
      ackRecieved.registerListener(function () {
        component.loadingAck = false;
        if (ackRecieved.status == "OK") {
          component.ackOk = true;
          setTimeout(function () {
            if (component.ackOk) {
              component.command = null;
            }
          }, 2900);
        } else {
          component.ackOk = false;
        }
      });
    },
  },
};
</script>
