import { parseMessage, SocketMessageTypes, WebSocketMessage } from "@/utils/WebsocketProtocol";
import { vuexStore } from "@/main";
import { Alert } from "@/utils/DataModels";
import axios from "axios";
import { MissionPlayerProtocolTypes } from "@/mission_player/protocol";

let socket: WebSocket;

const auth = `${process.env.USER_NAME}:${process.env.PASS}`;

const socketUrl =
  process.env.NODE_ENV === "production"
    ? "wss://" + auth + "@" + "rima2.ac.upc.edu:8443/socket"
    : "ws://" + auth + "@" + window.location.hostname + ":8090/socket";

export function connect(): void {
  const loc =
    process.env.NODE_ENV === "production"
      ? "https://rima2.ac.upc.edu:8443"
      : window.location.origin;

  axios.get(
    loc + "/auth/socket"
  ).then((response) => {
    if (response.status !== 200) {
      console.log("Couldn't stablish websocket connection");
      return;
    }

    const token = response.data.token;

    console.log(socketUrl + `?token=${token}`);
    socket = new WebSocket(socketUrl + `?token=${token}`);
    socket.onopen = () => {
      console.log("Socket open");
      vuexStore.dispatch("auth/connect");
      vuexStore.dispatch("alert/popAlert", new Alert(1, "info", "Socket open"));
      vuexStore.dispatch("addedDroneObject/sendAllSubscriptions");
    };

    socket.onmessage = (event) => {
      parseMessage(event.data);
    };

    socket.onclose = (event) => {
      console.log("Socket closed");
      console.log(event);
      vuexStore.dispatch("auth/disconnect");
      vuexStore.dispatch(
        "alert/popAlert",
        new Alert(1, "error", "Socket closed")
      );
      connect();
    };

    setTimeout(() => {
      sendWebSocketMessage(
        new WebSocketMessage(
          SocketMessageTypes.MISSION_PLAYER_PROTOCOL,
          {
            type: MissionPlayerProtocolTypes.GET_SOURCES,
          }
        )
      )
    }, 5000);
  }).catch((error) => {
    console.log("fatal")
    console.log(error);
    vuexStore.dispatch("auth/disconnect");
    vuexStore.dispatch("alert/popAlert", new Alert(0, "error", error), {
      root: true,
    });
    connect();
    return;
  });
}

export function sendWebSocketMessage(msg: WebSocketMessage | any, isProto: boolean = false) {
  if (isProto) {
    socket.send(msg)
  } else {
    // console.log("SENDING FROM FRONTEND", msg);
    socket.send(JSON.stringify(msg));
  }

}


