<template>
  <div>
    <v-checkbox
      v-model="dronePosition"
      @change="changedDronePosition"
      label="Current Drone Position"
    ></v-checkbox>
    <h4>Position:</h4>

    <v-text-field
      v-model="position"
      suffix="º"
      class="mt-0 pt-0 centered-input"
      :rules="[rules.position]"
      readonly
    ></v-text-field>

    <h4>MSL Altitude:</h4>
    <v-text-field
      v-model="altitude"
      suffix="m"
      class="mt-0 pt-0 centered-input"
      :rules="[rules.altitudeMax, rules.altitudeMin]"
      readonly
    ></v-text-field>

    <v-divider class="pb-2"></v-divider>
  </div>
</template>

<script>
import { getMagnitudeBounds } from "../../utils/Commands";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { view, areaLayer, map } from "../../esriAPI/EsriMap";
import { mapGetters } from "vuex";
import Point from "@arcgis/core/geometry/Point";

function newSketchViewModel(that) {
  gotoSketchViewModel = new SketchViewModel({
    view: view,
    layer: areaLayer,
    pointSymbol: {
      type: "simple-marker",
      style: "circle",
      size: 20,
      color: [255, 255, 0],
      outline: {
        color: [50, 50, 50],
        width: 5,
      },
    },
  });
  gotoSketchViewModel.create("point", { hasZ: false });

  graphic = null;

  gotoSketchViewModel.on("create", function(event) {
    if (event.state === "complete") {
      setValues(event.graphic, that);
      gotoSketchViewModel.update(graphic);
    }
  });
  gotoSketchViewModel.on("update", function(event) {
    setValues(event.graphics[0], that);
  });

  gotoSketchViewModel.on("delete", function() {
    gotoSketchViewModel.create("point");
    that.altitude = 0;
    that.latitude = 0;
    that.longitude = 0;
  });
}

function setValues(graph, that) {
  graphic = graph;
  that.latitude = round(graphic.geometry.latitude);
  that.longitude = round(graphic.geometry.longitude);
  map.ground
    .queryElevation(
      new Point({ latitude: that.latitude, longitude: that.longitude })
    )
    .then((res) => {
      that.altitude = roundAlt(res.geometry.z);
    });
}

function round(num) {
  return Math.round(num * 1000000) / 1000000;
}

function roundAlt(num) {
  return Math.round(num * 1000) / 1000;
}

let gotoSketchViewModel = null;
let graphic = null;
export default {
  data() {
    return {
      dronePosition: false,
      latitude: 0,
      longitude: 0,
      altitudeMin: 0,
      altitudeMax: 100,
      altitude: 0,
      rules: {
        altitudeMax: (value) => value <= this.altitudeMax || "Too large",
        altitudeMin: (value) => value >= this.altitudeMin || "Too small",
        position: () =>
          (this.longitude != 0 && this.latitude != 0) ||
          "Click map to select a position",
      },

      clickListener: null,
      lastMapPoint: null,
      noWP: true,
    };
  },

  computed: {
    position: function() {
      return `${this.latitude}, ${this.longitude}`;
    },
    ...mapGetters({
      selectedDrone: "addedDroneObject/getSelectedDrone",
    }),
  },

  created: function() {
    this.altitudeMin = getMagnitudeBounds("sethome").altitude.min;
    this.altitudeMax = getMagnitudeBounds("sethome").altitude.max;
    this.altitude = getMagnitudeBounds("sethome").altitude.default;

    newSketchViewModel(this);
  },
  destroyed: function() {
    areaLayer.remove(graphic);
    gotoSketchViewModel.destroy();
  },

  methods: {
    emitParameters() {
      this.$emit("command-parameters", {
        latitude: this.latitude,
        longitude: this.longitude,
        altitude: this.altitude,
      });
    },
    changedDronePosition() {
      if (this.dronePosition && this.selectedDrone) {
        this.latitude = round(this.selectedDrone.telemetry.latitude);
        this.longitude = round(this.selectedDrone.telemetry.longitude);

        map.ground
          .queryElevation(
            new Point({ latitude: this.latitude, longitude: this.longitude })
          )
          .then((res) => {
            this.altitude = roundAlt(res.geometry.z);
          });
        areaLayer.remove(graphic);
        gotoSketchViewModel.destroy();
      } else {
        newSketchViewModel(this);
      }
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
