<template>
  <v-card flat class="pa-4">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-slider
        v-model="altitude"
        class="align-center"
        :max="maxAlt"
        :min="minAlt"
        hide-details
        label="Altitude"
      >
        <template v-slot:append>
          <v-text-field
            v-model="altitude"
            suffix="m"
            class="mt-0 pt-0"
            single-line
            type="number"
            style="width: 60px"
          ></v-text-field>
        </template>
      </v-slider>

      <v-slider
        v-model="speed"
        class="align-center"
        :max="maxSpeed"
        :min="minSpeed"
        hide-details
        label="Speed"
      >
        <template v-slot:append>
          <v-text-field
            v-model="speed"
            suffix="m/s"
            class="mt-0 pt-0"
            single-line
            type="number"
            style="width: 70px"
          ></v-text-field>
        </template>
      </v-slider>

      <v-slider
        v-model="radius"
        class="align-center"
        :max="maxRadius"
        :min="minRadius"
        hide-details
        label="Sensor Radius"
      >
        <template v-slot:append>
          <v-text-field
            v-model="radius"
            suffix="m"
            class="mt-0 pt-0"
            single-line
            type="number"
            style="width: 60px"
          ></v-text-field>
        </template>
      </v-slider>

      <v-slider
        v-model="fov"
        class="align-center"
        :max="maxFOV"
        :min="minFOV"
        step="0.5"
        hide-details
        label="Sensor FOV"
      >
        <template v-slot:append>
          <v-text-field
            v-model="fov"
            suffix="º"
            class="mt-0 pt-0"
            single-line
            type="number"
            style="width: 60px"
          ></v-text-field>
        </template>
      </v-slider>

      <v-select
        v-model="endMission"
        item-text="text"
        item-value="value"
        :items="items"
        :rules="[(v) => !!v || 'End of mission is required']"
        label="End of mission"
        required
        return-object
      ></v-select>
    </v-form>
  </v-card>
</template>
<script>
import { FPTypes } from "../../utils/FPDataModels";
import { mapGetters } from "vuex";
import { vuexStore } from '@/main';
import { defaultAltitude } from "@/esriAPI/FlightPlanManager/FlightPlanEditor";

export default {
  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
  }),
  data() {
    return {
      valid: true,

      endMission: { text: "Landing", value: FPTypes.LANDING },
      items: [
        { text: "Landing", value: FPTypes.LANDING },
        { text: "Hover", value: FPTypes.HOVER },
        { text: "RTL", value: FPTypes.RTL },
      ],

      minAlt: 0,
      maxAlt: 50,
      altitude: defaultAltitude,

      minSpeed: 1,
      maxSpeed: 15,
      speed: 0,

      minRadius: 1,
      maxRadius: 15,
      radius: 0,
      
      minFOV: 0.5,
      maxFOV: 10,
      fov: vuexStore.getters["addedDroneObject/getSensorFOV"],
    };
  },
};
</script>
