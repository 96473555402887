import { vuexStore } from "../main";
import { FlightPlanProtocolTypes } from "./FlightPlanProtocol";
import { goToPromise, homePromise } from "../esriAPI/EsriMap";
import { AppState } from "./StateUtils";
import { placeRadiationSource } from "@/esriAPI/RadiationManager";
export const SocketMessageTypes = {
  ACK: "ACK",
  COMMAND: "COMMAND",
  GOTO_UPDATE: "GOTO_UPDATE",
  FLIGHT_PLAN_PROTOCOL: "FLIGHT_PLAN_PROTOCOL",
  MQTT_NOTIFICATION: "MQTT_NOTIFICATION",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION",
  TELEMETRY_SENDER_PROTOCOL: "TELEMETRY_SENDER_PROTOCOL",
  MISSION_PLAYER_PROTOCOL: "MISSION_PLAYER_PROTOCOL"
};

let FPsettingsComponent: any;


export class WebSocketMessage {
  type: string;
  message: object;

  constructor(type: string, message: object) {
    this.type = type;
    this.message = message;
  }
}
export function buildMessage(type: string, message: Object) {
  const msg = {
    type: type,
    message: message,
  };
  return msg;
}

export const ackRecieved = {
  status: "",
  ackListener: function () { },
  set Status(val: any) {
    this.status = val;
    this.ackListener();
  },
  registerListener: function (listener: any) {
    this.ackListener = listener;
  },

};

//export let telemetry_num = 0;

export function parseMessage(message: string) {
  console.log(message);
  // const Schema = require("../utils/GPB/FlightPlan_pb");
  // const pbFP = new Schema.FlightPlan();

  // const bytes = Array.prototype.slice.call(message, 0)
  // const testFP = pbFP.deserializeBinary(bytes)
  // console.log(testFP)

  try {
    let messageJSON = JSON.parse(JSON.parse(message)["message"]);
    const key = JSON.parse(message)["type"];
    let idAlert;
    let alertMessage;
    let type;


    switch (key) {
      case SocketMessageTypes.ACK:
        idAlert = alertIdGenerator(messageJSON);
        alertMessage =
          "Drone: " +
          messageJSON["systemId"] +
          " " +
          messageJSON["commandType"] +
          " " +
          messageJSON["commandStatus"];

        type = messageJSON["commandStatus"] == "OK" ? "info" : "warning";
        ackRecieved.Status = messageJSON["commandStatus"];
        vuexStore.dispatch(
          "alert/popAlert",
          { id: idAlert, type: type, message: alertMessage },
          { root: true }
        );

        console.log(messageJSON.commandType);
        if (messageJSON.commandType === "SET_HOME") {
          homePromise(messageJSON.systemId);
        }
        break;

      case SocketMessageTypes.COMMAND:
        break;

      case SocketMessageTypes.GOTO_UPDATE:
        goToPromise(messageJSON.systemId);
        break;
      case SocketMessageTypes.PUSH_NOTIFICATION:
        idAlert = messageJSON["notificationId"];
        alertMessage =
          "Drone: " +
          messageJSON["systemId"] +
          " " +
          messageJSON["notification"];
        type = messageJSON["type"];
        vuexStore.dispatch(
          "alert/popAlert",
          { id: idAlert, type: type, message: alertMessage },
          { root: true }
        );

        break;
      case SocketMessageTypes.FLIGHT_PLAN_PROTOCOL:
        messageJSON = fillNulls(messageJSON);
        switch (messageJSON.type) {
          case FlightPlanProtocolTypes.UPDATED_FP:
            vuexStore.dispatch("state/updateSelectedDroneFlightPlan", {
              systemId: messageJSON.systemId,
              flightPlan: messageJSON.flightPlan,
            });
            break;
          case FlightPlanProtocolTypes.STATISTICS:
            vuexStore.dispatch("state/updateStatistics", {
              systemId: messageJSON.systemId,
              statistics: messageJSON.statistics,
            });
            break;
          case FlightPlanProtocolTypes.COMPUTED_FP:
            vuexStore.dispatch("state/updateSelectedDroneFlightPlan", {
              systemId: messageJSON.systemId,
              flightPlan: messageJSON.flightPlan,
            });
            break;
          case FlightPlanProtocolTypes.IMPORT_FP:
            vuexStore.dispatch("state/updateSelectedDroneFlightPlan", {
              systemId: null,
              flightPlan: messageJSON.flightPlan,
            });
            break;
          case FlightPlanProtocolTypes.LIST_FP:
            vuexStore.dispatch("state/listFiles", {
              systemId: null,
              fileList: messageJSON.fileList,
            });
            break;
          case FlightPlanProtocolTypes.AREA_VALIDATION:
            if (messageJSON.validArea && (vuexStore.getters["state/getAppState"] === AppState.AREA_CREATION)) {
              FPsettingsComponent.overlay();
            }
            break;
          default:
            throw `Unidentified FLIGHT_PLAN_PROTOCOL message type: ${messageJSON.type}`;
        }
        break;

      case SocketMessageTypes.MQTT_NOTIFICATION: //TODO: finish this
        break;
      case SocketMessageTypes.TELEMETRY_SENDER_PROTOCOL:
        /*
        if (isRecording) {
          telemetry_num++;
        }
        */
        if (messageJSON.type === "payload")
          vuexStore.dispatch(
            "addedDroneObject/setTelemetry",
            messageJSON.payload
          );
        break;
      case SocketMessageTypes.MISSION_PLAYER_PROTOCOL:
        if (messageJSON.type === "radiationSources") {
          messageJSON.radiationSources.forEach((source: any) => {
            placeRadiationSource(source.id, source.latitude, source.longitude);
          });
        }
        break;
      default:
        throw 'Unidentified key for message type: "' + key + '"';
    }
  } catch (e) {
    console.error(e);
  }
}

function alertIdGenerator(message: any) {
  let id = 0;
  id += message["systemId"].charCodeAt(0) * 1000;
  id +=
    message["commandType"].length +
    message["commandType"].charCodeAt(0) +
    message["commandType"].charCodeAt(message["commandType"].length - 1);
  id += message["commandStatus"] == "OK" ? 1 : 0;
  return id;
}

function fillNulls(messageJSON: any) {
  if ("flightPlan" in messageJSON) {
    if (!("waypoints" in messageJSON.flightPlan)) {
      messageJSON.flightPlan.waypoints = [];
    }
    if (!("area" in messageJSON.flightPlan)) {
      messageJSON.flightPlan.area = [];
    }
  }
  return messageJSON;
}


export function setFPSettingsSocket(vueComp: any) {
  FPsettingsComponent = vueComp;
}

