import { activateTimeSlider, clearMap, setSliderSpeed } from "@/mission_player/esri/esriMap";
import { updateDrone, updatePath } from "@/mission_player/esri/path";
import { setCylinderHeight } from "@/mission_player/esri/RadiationManager";
import { MissionData } from "@/mission_player/models/MissionData";
import { MissionItem } from "@/mission_player/models/MissionItem";
import { getMissionList, requestMissionData } from "@/mission_player/protocol";
import { Telemetry } from "@/utils/DroneDataModel";

export enum MissionPlayerStateEnum {
  SELECTING_MISSIONS,
  PLAYING_MISSION,
}

interface MissionPlayerState {
  missionPlayerState: MissionPlayerStateEnum;
  missionList: MissionItem[];
  selectedMission: MissionItem | null;
  missionData: MissionData | null;
  currentTime: number;
  currentTelemetry: Telemetry | null;
  speed: number;
  cylinderHeight: boolean;
}

const state: MissionPlayerState = {
  missionPlayerState: MissionPlayerStateEnum.SELECTING_MISSIONS,
  missionList: [],
  selectedMission: null,
  missionData: null,
  currentTime: 0,
  currentTelemetry: null,
  speed: 2,
  cylinderHeight: true,
}

const getters = {
  getState(state: MissionPlayerState) {
    return state.missionPlayerState;
  },
  getMissionList(state: MissionPlayerState) {
    return state.missionList;
  },
  getSelectedMission(state: MissionPlayerState) {
    return state.selectedMission;
  },
  getMissionData(state: MissionPlayerState) {
    return state.missionData;
  },
  getCurrentTime(state: MissionPlayerState) {
    return state.currentTime;
  },
  getCurrentTelemetry(state: MissionPlayerState) {
    return state.currentTelemetry;
  },
  getSpeed(state: MissionPlayerState) {
    return state.speed;
  },
  getCylinderHeight(state: MissionPlayerState) {
    return state.cylinderHeight;
  },
}

const mutations = {
  SET_STATE(state: MissionPlayerState, missionPlayerState: MissionPlayerStateEnum) {
    state.missionPlayerState = missionPlayerState;
  },
  SET_MISSION_LIST(state: MissionPlayerState, missionList: MissionItem[]) {
    state.missionList = missionList;
  },
  SET_SELECTED_MISSION(state: MissionPlayerState, mission: MissionItem) {
    state.selectedMission = mission;
  },
  SET_MISSION_DATA(state: MissionPlayerState, missionData: MissionData) {
    state.missionData = missionData;
  },
  SET_CURRENT_TIME(state: MissionPlayerState, millisEpoch: number) {
    state.currentTime = millisEpoch;
  },
  SET_CURRENT_TELEMETRY(state: MissionPlayerState, telemetry: Telemetry) {
    state.currentTelemetry = telemetry;
  },
  SET_SPEED(state: MissionPlayerState, speed: number) {
    state.speed = speed;
  },
  SET_CYLINDER_HEIGHT(state: MissionPlayerState, isHeight: boolean) {
    state.cylinderHeight = isHeight;
  },
}

const actions = {
  loadMissionList(context: any) {
    getMissionList().then(missionItems => {
      context.dispatch("setMissionList", missionItems);
    });
  },
  setMissionList(context: any, missionList: MissionItem[]) {
    context.commit("SET_MISSION_LIST", missionList);
  },
  selectMission(context: any, mission: MissionItem) {
    context.commit("SET_SELECTED_MISSION", mission);
    context.dispatch("playMission");
    requestMissionData(mission).then(missionData => {
      console.log(missionData);
      context.commit("SET_MISSION_DATA", missionData)
      activateTimeSlider();
    });
  },
  playMission(context: any) {
    context.commit("SET_STATE", MissionPlayerStateEnum.PLAYING_MISSION);
  },
  setCurrentTime(context: any, millisEpoch: number) {
    context.commit("SET_CURRENT_TIME", millisEpoch);

    // TODO: Change for binary search
    let i;
    for (i = 0; i < context.state.missionData.telemetryList.length; i++) {
      if (context.state.missionData.telemetryList[i].timestamp > millisEpoch) break;
    }
    i -= 1;

    context.commit("SET_CURRENT_TELEMETRY", context.state.missionData.telemetryList[i]);

    updatePath(i);
    updateDrone(i);
  },
  unselectMission(context: any) {
    clearMap();
    setTimeout(() => context.commit("SET_STATE", MissionPlayerStateEnum.SELECTING_MISSIONS), 3000);
  },
  speedDown(context: any) {
    const speedFactors = [0.25, 0.5, 1, 2, 3, 4, 8, 16];
    if (context.state.speed > 0) {
      const newSpeed = context.state.speed - 1;
      context.commit("SET_SPEED", newSpeed);
      setSliderSpeed(speedFactors[newSpeed]);
    }
  },
  speedUp(context: any) {
    const speedFactors = [0.25, 0.5, 1, 2, 3, 4, 8, 16];
    if (context.state.speed < speedFactors.length - 1) {
      const newSpeed = context.state.speed + 1;
      context.commit("SET_SPEED", newSpeed);
      setSliderSpeed(speedFactors[newSpeed]);
    }
  },
  setCylinderHeight(context: any, isHeight: boolean) {
    context.commit("SET_CYLINDER_HEIGHT", isHeight);
    setCylinderHeight(isHeight);
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};


