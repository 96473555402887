<template>
  <div>
    <v-input class="center" :rules="[rules.flightPlan]"> </v-input>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      rules: {
        flightPlan: () =>
          (this.selectedDrone != null && "flightPlan" in this.selectedDrone) ||
          "Selected Drone has no FlightPlan",
      },
    };
  },

  computed: mapGetters({ selectedDrone: "addedDroneObject/getSelectedDrone" }),
  methods: {
    emitParameters() {
      this.$emit("command-parameters", {});
    },
  },
};
</script>

<style scoped>
.center {
  margin-left: auto;
  margin-right: auto;
  width: 70%;
}
</style>
