<template>
  <div>
    <v-menu offset-y dark>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on" @click.stop="onClickHanlder">
          <v-icon> mdi-layers-outline </v-icon>
        </v-btn>
      </template>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LayerList from "@arcgis/core/widgets/LayerList";
import { view } from "@/esriAPI/EsriMap";
import { view as viewMP } from "@/mission_player/esri/esriMap";

export default {
  data() {
    return {
      openMenu: false,
      message: "",
      layerList: null,
      mounted: false,
    };
  },
  computed: {
    ...mapGetters({
      appState: "state/getAppState",
    }),
    view() {
      if (this.appState == "MISSION_PLAYER") {
        console.log("MP");
        return viewMP;
      } else {
        console.log("NOT MP");
        return view;
      }
    }
  },

  mounted() {
    if (!this.mounted) {
      this.layerList = new LayerList({
        view: this.view,
        //If layer detected has not title it is not shown in the manager
        listItemCreatedFunction: function (event) {
          let item = event.item;
          if (!item.title) {
            item.layer.listMode = "hide";
          }

          if (item.title === "Flight Plan") {
            item.actionsSections = [
              [
                {
                  title: "Increase opacity",
                  className: "esri-icon-up",
                  id: "flight-plan-incOp"
                },
                {
                  title: "Decrease opacity",
                  className: "esri-icon-down",
                  id: "flight-plan-decOp"
                }
              ]
            ];
          }

        },
      });

      this.layerList.on("trigger-action", (event) => {
        const opacity = event.item.layer.opacity;
        const id = event.action.id;
        if (id === "flight-plan-incOp") {
          if (opacity < 1) {
              event.item.layer.opacity += 0.25;
            //if there is a highlight it will change opacity
            //   view.highlightOptions={
            //     color: [0,255,255,opacity+0.25]
            // }
          }
          } else if (id === "flight-plan-decOp") {
            if (opacity > 0.25) {
              event.item.layer.opacity -= 0.25;
            //if there is a highlight it will change opacity
            //   view.highlightOptions={
            //     color: [0,255,255,opacity-0.25]
            // }
          }
        } 
      });

      this.view.ui.add(this.layerList, {
        position: "top-left",
      });

      this.mounted = true;
      this.layerList.visible = false;
    }
  },
  methods: {
    onClickHanlder() {
      this.layerList.view = this.view;
      this.layerList.visible = !this.layerList.visible;
    },
  },
};
</script>

