<template>
  <div></div>
</template>

<script>
export default {
  methods: {
    emitParameters() {
      this.$emit("command-parameters", {});
    },
  },
};
</script>