<template>
  <div>
    <h4>Position:</h4>

    <v-text-field
      v-model="position"
      suffix="º"
      class="mt-0 pt-0 centered-input"
      :rules="[rules.position]"
      readonly
    ></v-text-field>

    <v-checkbox v-model="lastPoint" label="Last Radiation Point"></v-checkbox>

    <v-divider class="pb-2"></v-divider>
  </div>
</template>

<script>
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import {
  view,
  droneLayer,
  spatialReference,
  map,
  radLayer,
} from "../../esriAPI/EsriMap";
import { Extent, Point } from "@arcgis/core/geometry";
import { mapGetters } from "vuex";
import Graphic from "@arcgis/core/Graphic";

let gotoSketchViewModel = null;
let graphic = null;

function newGotoSketchViewModel(that) {
  gotoSketchViewModel = new SketchViewModel({
    view: view,
    layer: droneLayer,
    pointSymbol: {
      type: "simple-marker",
      style: "circle",
      size: 20,
      color: [255, 255, 0],
      outline: {
        color: [50, 50, 50],
        width: 5,
      },
    },
    defaultCreateOptions: {
      hasZ: false, // default value
    },
    defaultUpdateOptions: {
      enableZ: false,
    },
  });
  gotoSketchViewModel.create("point");

  graphic = null;

  gotoSketchViewModel.on("create", function (event) {
    if (event.state === "complete") {
      setValues(that, event.graphic);
      gotoSketchViewModel.update(graphic);
    }
  });
  gotoSketchViewModel.on("update", function (event) {
    setValues(that, event.graphics[0]);
  });

  gotoSketchViewModel.on("delete", function () {
    gotoSketchViewModel.create("point");
    that.altitude = 0;
    that.latitude = 0;
    that.longitude = 0;
  });
}

function setValues(that, graph) {
  graphic = graph;
  that.latitude = graphic.geometry.latitude;
  that.longitude = graphic.geometry.longitude;
  const point = new Point({
    spatialReference: spatialReference,
    hasZ: true,
    latitude: that.latitude,
    longitude: that.longitude,
    z: 0,
  });
  const extent = new Extent({
    xmax: point.x + 20, xmin: point.x - 20,
    ymax: point.y + 20, ymin: point.y - 20,
    spatialReference: spatialReference
  });

  map.ground
    .load()
    .then(function () {
      // create an elevation sampler from a given extent
      return view.map.ground.createElevationSampler(extent);
    })
    .then(function (elevationSampler) {
      // use the elevation sampler to get z-values for a point, multipoint or polyline geometry
      let zEnrichedGeometry = elevationSampler.queryElevation(point);
      that.altitude = zEnrichedGeometry.z;
      point.x = that.altitude;
    });
}

export default {
  data() {
    return {
      lastPoint: false,
      latitude: 0,
      longitude: 0,
      altitude: 0,
      rules: {
        position: () =>
          (this.longitude != 0 && this.latitude != 0) ||
          "Click map to select a position",
      },

      clickListener: null,
      lastMapPoint: null,
      noWP: true,
    };
  },

  computed: {
    ...mapGetters({ radPoints: "addedDroneObject/getRadPoint" }),
    position: function () {
      return `${this.latitude}, ${this.longitude}`;
    },
  },

  created: function () {
    newGotoSketchViewModel(this);
  },
  destroyed: function () {
    droneLayer.remove(graphic);
    gotoSketchViewModel.destroy();
  },

  methods: {
    emitParameters() {
      if (this.radPoints.length == 0) {
        //todo probably make theoption of last rad point more like "new set"
        radLayer.removeAll();
        radLayer.listMode = "hide";
      }
      radLayer.listMode = "show";

      let newRadPoints = this.radPoints;
      const point = new Point({
        spatialReference: spatialReference,
        latitude: this.latitude,
        longitude: this.longitude,
      });
      newRadPoints.push(point);
      const symbol = {
        type: "point-3d",
        symbolLayers: [
          {
            type: "object",
            width: 0.5,
            height: 2,
            depth: 0.5,
            resource: { primitive: "cylinder" },
            material: { color: "blue" },
          },
        ],
      };
      const graphic = new Graphic({
        geometry: point,
        symbol: symbol,
      });
      radLayer.add(graphic);

      if (this.lastPoint) {
        newRadPoints = [];
      }
      this.$store.dispatch("addedDroneObject/setRadPoint", newRadPoints);

      this.$emit("command-parameters", {
        latitude: this.latitude,
        longitude: this.longitude,
        altitude: this.altitude,
        lastPoint: this.lastPoint,
      });
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
