/**
 *
 * * Alert prototype object:
    id: id, // ? an integer that identifies that particular alert
    type: type, // ? types: [success, info, warning, error] - error type doesn't time out 
    message: message // ? message to be displayed
 */

import { vuexStore } from "../../main";
import { Alert } from "@/utils/DataModels";

const AlertType = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};

interface AlertState {
  alerts: Alert[];
}

const state: AlertState = {
  alerts: [],
};

const getters = {
  getAlerts: (state: AlertState) => {
    return state.alerts;
  },
  getNotDismissed: (state: AlertState) => (id: number) => {
    const alert = state.alerts.filter((item: Alert) => {
      return item.id === id;
    });
    return alert[0];
  },
};

const mutations = {
  ADD_ALERT(state: AlertState, alert: Alert) {
    const timeout =
      alert.type === "error"
        ? undefined
        : setTimeout(
            function() {
              vuexStore.commit("alert/REMOVE_ALERT", alert.id);
            },
            alert.type == "warning" ? 5000 : 3000
          );

    const tempObj: Alert = new Alert(
      alert.id,
      alert.type,
      alert.message,
      timeout
    );
    state.alerts.push(tempObj);
  },

  REMOVE_ALERT(state: AlertState, id: number) {
    state.alerts = state.alerts.filter((item: Alert) => {
      return item.id !== id;
    });
  },
};

const actions = {
  popAlert(context: any, alert: Alert) {
    if (
      context.state.alerts.filter((item: Alert) => {
        return item.id === alert.id;
      }).length === 0 ||
      alert.id < 0
    ) {
      context.commit("ADD_ALERT", alert);
    }
  },

  dismissAlert(context: any, id: number) {
    context.commit("REMOVE_ALERT", id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
