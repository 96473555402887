<template>
  <v-toolbar dense floating max-width="80vw" v-resize="onResize">
    <v-app-bar-nav-icon
      @click.stop="$emit('open-navigation-bar')"
    ></v-app-bar-nav-icon>
    <div id="searchDiv" v-if="!toolsMode"></div>
    <v-divider
      vertical
      class="ml-4"
      v-if="!$vuetify.breakpoint.smAndDown"
    ></v-divider>
    <v-btn
      @click.stop="toolsMode = !toolsMode"
      v-if="$vuetify.breakpoint.smAndDown"
      icon
      fab
      samll
    >
      <v-icon v-if="!toolsMode">mdi-draw</v-icon>
      <v-icon v-else>mdi-magnify</v-icon>
    </v-btn>
    <LayerTools
      v-if="$vuetify.breakpoint.smAndDown ? (toolsMode ? true : false) : true"
      v-on:open-layer-manager="
        (data) => {
          $emit('open-layer-manager', data);
        }
      "
    />
  </v-toolbar>
</template>


<script>
import { newSearch } from "@/esriAPI/EsriMap";
import LayerTools from "../Toolbar/LayerTools";

export default {
  data() {
    return {
      items: [
        {
          name: "Settings",
          icon: "mdi-cog-outline",
          method: this.openMPSettings,
        },
      ],
      toolsMode: true,
      small: null,
    };
  },

  components: {
    LayerTools,
  },
  watch: {
    toolsMode: function (val) {
      if (!val) {
        setTimeout(function () {
          newSearch();
        }, 0);
      }
    },
  },
  methods: {
    openMPSettings() {
      this.$emit("open-MP-settings");
    },
    onResize() {
      if (this.small && !this.$vuetify.breakpoint.smAndDown && this.toolsMode) {
        setTimeout(function () {
          newSearch();
        }, 50);
      }
      this.small = this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
<style scoped>
.esri-search {
  width: 100%;
}
#searchDiv {
  max-width: 200px;
}
</style>
