<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Change Roles</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-checkbox
                v-for="role in this.roles"
                :key="role.name"
                v-model="userRoles"
                :label="role.name"
                :value="role.name"
              ></v-checkbox>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="changingRoles"
          @click="close"
        >Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid || changingRoles"
          @click="confirm"
        >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "ChangePassword",
  data() {
    return {
      dialog: false,
      changingRoles: false,
      valid: false,
      roles: [],
      userRoles: [],
    }
  },
  props: {
    user: {},
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.userRoles = this.user.roles.map((role) => role.name);
        this.getRoles();
      }
    }
  },
  methods: {
    getRoles() {
      const loc =
        process.env.NODE_ENV === "production"
          ? "https://rima2.ac.upc.edu:8443"
          : window.location.origin;
  
      axios.get(
        loc + "/auth/all_roles"
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't stablish connection");
          this.dialog = false;
          return;
        }
        this.roles = response.data.roles;
      }).catch((error) => {
        console.log(error);
        this.dialog = false;
        return;
      });
    },
    close() {
      this.dialog = false;
      this.changingRoles = false;
      this.userRoles = [];
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.changingRoles = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.post(
          loc + "/auth/user/" + this.user.id,
          {
            roles: this.userRoles,
          }
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't change roles");
            this.close();
            return;
          }
  
          this.$emit('roles-changed');
          this.close();
        }).catch((error) => {
          console.log(error);
          this.close();
          return;
        });
      }
    },
  }
}
</script>

<style>

</style>