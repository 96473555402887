<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="deletingUser"
          @click="close"
        >Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="deletingUser"
          @click="confirm"
        >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "DeleteUser",
  data() {
    return {
      dialog: false,
      deletingUser: false,
    }
  },
  props: {
    user: {},
  },
  methods: {
    close() {
      this.dialog = false;
      this.deletingUser = false;
    },
    confirm() {
      this.deletingUser = true;
      const loc =
        process.env.NODE_ENV === "production"
          ? "https://rima2.ac.upc.edu:8443"
          : window.location.origin;
      axios.delete(
        loc + "/auth/user/" + this.user.id,
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't delete user");
          this.dialog = false;
          this.deletingUser = false;
          return;
        }

        this.$emit('user-deleted');
        this.dialog = false;
        this.deletingUser = false;
      }).catch((error) => {
        console.log(error);
        this.dialog = false;
        this.deletingUser = false;
        return;
      });
    },
  }
}
</script>

<style>

</style>