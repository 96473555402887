import { render, staticRenderFns } from "./RadiationSource.vue?vue&type=template&id=8f1dab7c&scoped=true&"
import script from "./RadiationSource.vue?vue&type=script&lang=js&"
export * from "./RadiationSource.vue?vue&type=script&lang=js&"
import style0 from "./RadiationSource.vue?vue&type=style&index=0&id=8f1dab7c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f1dab7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle,VContainer,VDivider,VFadeTransition,VForm,VSpacer,VTextField})
