<template>
  <v-overlay absolute :value="popOverlay" opacity="0.8">
    <v-card min-height="30%" min-width="20%">
      <v-tabs
        v-model="tab"
        background-color="grey darken-4"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Statistics
          <v-icon>mdi-tune</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" :eager="true">
          <v-simple-table dark height="30%" v-if="flightPlan">
            <template v-slot:default>
              <tbody>
                <tr v-for="item in statsData" :key="item.name">
                  <td>{{ item.name }}</td>
                  <td>{{ item.data }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-card v-else min-height="30px" min-width="50px">
            <v-card-text> No existing flight plan </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" class="mr-10" @click.stop="overlay">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
//FIXME: the statistics of the area disapear when more outrpute points are added to the FP, conserve tehm
import { mapGetters } from "vuex";

export default {
  computed: mapGetters({
    flightPlan: "state/getDisplayedFlightPlan",
    selectedDrone: "addedDroneObject/getSelectedDrone",
    selectedDroneId: "addedDroneObject/getSelectedDroneId",
    statsData: "state/getStatistics",
  }),

  methods: {
    overlay() {
      this.popOverlay = !this.popOverlay;
    },
  },

  //TODO: send from bakcedn this json with all the data ready and make sure frontend saves it so it only has to be
  //sent once after each updated fp message
  data() {
    return {
      tab: "tab-1",
      popOverlay: false,
    };
  },
};
</script>


