<template>
  <div>
  <v-menu
    offset-y
    open-on-hover
    transition="slide-y-transition"
    close-delay="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
      icon 
      v-bind="attrs" 
      v-on="on"
      :disabled="appState == 'FLIGHT_PLAN_CREATION' || appState == 'AREA_CREATION'"
      >
        <v-icon> mdi-texture-box </v-icon>
      </v-btn>
    </template>
    <div v-for="(item, index) in items" v-bind:key="index" class="my-1">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="item.method"
            color="default"
            fab
            small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>
  </v-menu>
  <AreaDraw></AreaDraw>
  <v-dialog
          v-model="fileInputVisible"
          max-width="300px"
        >
        <v-card outlined >
        <v-file-input class="fileInput"
        max-width="250px"
              show-size
              dark
              accept=".txt"
              label="File Input"
              v-model="chosenFile"
              @change="importFile"
            ></v-file-input>
          </v-card>
  </v-dialog>
  <v-dialog 
          v-model="fileDownloadVisible"
          max-width="300px"
        >
        <v-card>
  <v-text-field
            hide-details
            class="pa-3"
            v-model="fileNameLocal"
            placeholder="Enter file Name"
            outlined
            suffix=".txt"
          ></v-text-field>
          <v-card-actions>
            <v-btn block class="my-5" color="success" @click="downloadFile">
            Save
          </v-btn>
          </v-card-actions>
        </v-card>
        </v-dialog>

        
        <v-card
        v-if=scaleAreaVisible
          max-width="300px"
          flat
          class="scaleArea"
        >
          <v-subheader>Enter area scale factor
            <v-btn style="margin-left:60px"
          icon
          @click.stop=closeScaleArea
        >
          <v-icon> mdi-close-circle-outline </v-icon>
        </v-btn>
          </v-subheader>
          <v-card-text>
            <v-row >
              <v-col style="padding:5px">
                <v-slider
                  v-model="slider"
                  class="align-center"
                  :max="max"
                  :min="min"
                  hide-details
                  step="0.1"
                  @change="sliderValueChange"
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="slider"
                      readonly
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 40px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AreaDraw from "../Area/AreaDraw.vue";
import { AppState } from "../../utils/StateUtils";
import { areaToCSV, checkAreaExists, csvToArea, resizeAreaPolygon} from "../../esriAPI/AreaManager/AreaUtils";
import {
  createArea,generateAreaFromPoints, generateAreaFromPolygon, removePlotedArea
} from "../../esriAPI/AreaManager/AreaEditor";
import { areaLayer, map, spatialReference, view } from '@/esriAPI/EsriMap';
import { Extent, Point } from '@arcgis/core/geometry';
// import { map, view } from '@/esriAPI/EsriMap';
export default {
    data() {
        return {
          fileNameLocal:"",
          fileDownloadVisible: false,
          fileInputVisible: false,
          scaleAreaVisible: false,
          chosenFile: null,

          min:0.5,
          max:1.5,
          slider:1,
          items: [
            {
              name: "Draw Area",
              method: this.openAreaDraw,
              icon: "mdi-draw",
            },
            {
              name: "Edit Coordinates",
              method: this.areaEditor,
              icon: "mdi-map-marker-multiple-outline",
            },
            {
              name: "Scale Area",
              method: this.openScaleArea,
              icon: "mdi-fit-to-page-outline",
            },
            {
              name: "Load Area",
              method: this.openFileImport,
              icon: "mdi-file-upload-outline",
            },
            {
              name: "Save Area",
              method: this.openFileDownload,
              icon: "mdi-file-download-outline",
            },  
          ],
          message: "",
        };
    },
    computed: {
        ...mapGetters({
            appState: "state/getAppState",
        }),
    },
    methods: {
      areaEditor() {
        this.$emit("open-area-editor");
      },
      openAreaDraw() {
        this.changeAppState(AppState.AREA_CREATION);
        createArea();
        this.$store.dispatch("alert/popAlert", {
          id: 20,
          type: "info",
          message: "Exit Area mode by double-clicking or pressing Enter",
        });
      },
      openScaleArea(){
        if(checkAreaExists(true)){
          this.scaleAreaVisible=true;
        }
      },
      sliderValueChange(newValue){
        if(checkAreaExists(true)){
          const polygon = areaLayer.graphics.getItemAt(0).geometry;
          generateAreaFromPolygon(resizeAreaPolygon(newValue,polygon));
        }
        
      },
      closeScaleArea(){
          this.scaleAreaVisible=false;
          this.slider=1;
      },
      openFileImport(){
        this.fileInputVisible=true;
      },
      importFile(){
        if (this.chosenFile) {
          var reader = new FileReader();
          reader.readAsText(this.chosenFile);
          reader.onload = () => {
            try {
              let waypoints = csvToArea(reader.result);
              this.fileInputVisible=false;

              const point = new Point({
                  spatialReference: spatialReference,
                  hasZ: true,
                  latitude: waypoints[0].lat,
                  longitude: waypoints[0].lon,
                  z: 0,
                });    
                const extent = new Extent({
                  xmax: point.x + 20, xmin: point.x - 20,
                  ymax: point.y + 20, ymin: point.y - 20,
                  spatialReference: spatialReference
                });

              map.ground
              .load()
              .then(function () {
                return view.map.ground.createElevationSampler(extent);
              })
              .then(function (elevationSampler) {
                           
                const zEnrichedGeometry = elevationSampler.queryElevation(point);
                point.z += zEnrichedGeometry.z + 500;
                view.goTo({ position: point, heading: 0, tilt: 0 });
              });

              generateAreaFromPoints(waypoints);
              this.chosenFile=null;
          } catch (e) {
            removePlotedArea();
            this.$store.dispatch("alert/popAlert", {
              id: -1,
              type: "error",
              message: "Error importing file",
            });
          }
        };
      
      }
      },
      openFileDownload()
      {
        const area = checkAreaExists(true);
        if(area){
        this.fileDownloadVisible=true;
        }
      },
      downloadFile(){
        const area = checkAreaExists();
        if(area){
          const csv = areaToCSV(area);
          var element = document.createElement("a");
          element.setAttribute(
            "href",
            "data:text/plain;charset=utf-8," + encodeURIComponent(csv)
          );
          element.setAttribute("download", this.fileNameLocal + ".txt");
          element.style.display = "none";
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        }     

        this.fileDownloadVisible=false;
      },
    ...mapActions({
        changeAppState: "state/changeAppstate",
        setLastAppstate: "state/setLastAppstate",
    }),
  },
  components: { AreaDraw }
};
</script>
<style scoped>
.fileInput{
  max-width: 280px;
}
.scaleArea{
  position: absolute;
  min-width: 300px;
  max-width: 300px;
  top:0px;
  left:333px;
  padding: 10px;
}
</style>

