<template>
  <v-card flat class="pa-4">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-select
        v-model="scanMode"
        item-text="text"
        item-value="value"
        :items="items"
        :rules="[(v) => !!v || 'Type of Scan is required']"
        label="Scan Mode"
        required
        return-object
        single-line
      ></v-select>
      <div v-if="scanMode.text == items[0].text">
        <v-slider
          v-model="heading"
          class="align-center"
          :max="maxHeading"
          :min="minHeading"
          hide-details
          label="Heading"
        >
          <template v-slot:append>
            <v-text-field
              v-model="heading"
              suffix="º"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>

        <v-slider
          v-model="width"
          class="align-center"
          :max="maxWidth"
          :min="minWidth"
          hide-details
          label="Width"
          step="0.1"
        >
          <template v-slot:append>
            <v-text-field
              v-model="width"
              suffix="m"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 70px"
            ></v-text-field>
          </template>
        </v-slider>

        <v-slider
          v-model="offset"
          class="align-center"
          :max="maxOffset"
          :min="minOffset"
          hide-details
          label="Offset"
        >
          <template v-slot:append>
            <v-text-field
              v-model="offset"
              suffix="m"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
        <v-select
          v-model="optimization"
          :items="optimizationItems"
          item-text="text"
          item-value="value"
          label="Optimization"
          required
          return-object
        ></v-select>
        <v-select
          v-model="entryDirection"
          :items="directionItems"
          item-text="text"
          item-value="value"
          label="Entry Directon"
          required
          return-object
        ></v-select>
        <v-select
          v-model="exitDirection"
          :items="directionItems"
          item-text="text"
          item-value="value"
          label="Exit Direction"
          required
          return-object
        ></v-select>
        <v-checkbox
          v-model="checkboxExitCourse"
          label="Same Exit Course"
        ></v-checkbox>
      </div>
      <div v-if="scanMode.text == items[1].text">
        <v-slider
          v-model="passes"
          class="align-center"
          :max="maxPasses"
          :min="minPasses"
          hide-details
          label="Passes"
        >
          <template v-slot:append>
            <v-text-field
              v-model="passes"
              suffix="#"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>

        <v-slider
          v-model="generations"
          class="align-center"
          :max="maxGenerations"
          :min="minGenerations"
          hide-details
          label="Generations"
        >
          <template v-slot:append>
            <v-text-field
              v-model="generations"
              suffix="#"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 80px"
            ></v-text-field>
          </template>
        </v-slider>
        <v-slider
          v-model="time"
          class="align-center"
          :max="maxTime"
          :min="minTime"
          hide-details
          label="Max Time"
        >
          <template v-slot:append>
            <v-text-field
              v-model="time"
              suffix="s"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 80px"
            ></v-text-field>
          </template>
        </v-slider>
      </div>
      <div v-if="scanMode.text == items[2].text">
        <v-slider
          v-model="passes"
          class="align-center"
          :max="maxPasses"
          :min="minPasses"
          hide-details
          label="Passes"
        >
          <template v-slot:append>
            <v-text-field
              v-model="passes"
              suffix="#"
              class="mt-0 pt-0"
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </div>
    </v-form>
  </v-card>
</template>
<script>
import { FPTypes } from "../../utils/FPDataModels";

export default {
  data() {
    return {
      valid: true,
      items: [
        { text: "Back and Forth", value: FPTypes.BACK_AND_FORTH },
        { text: "Smart Random", value: FPTypes.SMART_RANDOM },
        { text: "Random", value: FPTypes.BASIC_RANDOM },
      ],
      scanMode: { text: "Back and Forth", value: FPTypes.BACK_AND_FORTH },

      //TAB 1: BACK AND FORTH
      checkboxExitCourse: false,
      minHeading: 0,
      maxHeading: 359,
      heading: 0,
      minWidth: 0.1,
      maxWidth: 10,
      width: 1,
      minOffset: 0,
      maxOffset: 10,
      offset: 0,
      optimizationItems: [
        { text: "None", value: FPTypes.NONE },
        { text: "Time", value: FPTypes.TIME },
        { text: "Turns", value: FPTypes.TURNS },
      ],
      optimization: { text: "None", value: FPTypes.NONE },
      entryDirection: { text: "Left", value: FPTypes.LEFT },
      exitDirection: { text: "Left", value: FPTypes.LEFT },
      directionItems: [
        { text: "Left", value: FPTypes.LEFT },
        { text: "Right", value: FPTypes.RIGHT },
      ],

      //TAB 2: SMART RANDOM
      minPasses: 1,
      maxPasses: 100,
      passes: 20,
      minGenerations: 1,
      maxGenerations: 5000,
      generations: 1000,
      time: 30,
      minTime: 1,
      maxTime: 3600,
    };
  },
};
</script>
