import ElevationProfile from "@arcgis/core/widgets/ElevationProfile";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { map, view } from "./EsriMap";

let elevationProfile: ElevationProfile;
let visibleElevationProfile = false;
const graphicsLayer = new GraphicsLayer();
export function toggleElevationProfile() {
  if (!visibleElevationProfile) {
    visibleElevationProfile = true;
    elevationProfile = new ElevationProfile({
      view: view,
      profiles: [
        {
          type: "ground",
        },
      ],
      visibleElements: {
        legend: false,
        sketchButton: true,
        selectButton: false,
      },
    });
    map.add(graphicsLayer);
    view.ui.add(elevationProfile, { position: "bottom-right", index: 0 });
  } else {
    visibleElevationProfile = false;
    elevationProfile.destroy();
  }
}
