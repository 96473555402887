<template>
  <div>
    <h4>Position:</h4>

    <v-text-field
      v-model="position"
      suffix="º"
      class="mt-0 pt-0 centered-input"
      :rules="[rules.position]"
      readonly
    ></v-text-field>

    <h4>Altitude:</h4>
    <v-text-field
      v-model="altitude"
      suffix="m"
      class="mt-0 pt-0 centered-input"
      :rules="[rules.altitudeMax, rules.altitudeMin]"
      readonly
    ></v-text-field>

    <v-radio-group row mandatory v-model="relativeAltitude">
      <v-radio label="Absolute" value="0"></v-radio>
      <v-radio label="Relative" value="1"></v-radio>
    </v-radio-group>
    <v-divider class="pb-2"></v-divider>
    <h4>Speed:</h4>
    <v-slider
      v-model="speed"
      class="align-center"
      :max="speedMax"
      :min="speedMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="speed"
          suffix="m/s"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 70px"
          :rules="[rules.speedMax, rules.speedMin]"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script>
import { getMagnitudeBounds } from "../../utils/Commands";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { view, droneLayer } from "../../esriAPI/EsriMap";
import { mapGetters } from "vuex";

let gotoSketchViewModel = null;
let graphic = null;

function newGotoSketchViewModel(that) {
  gotoSketchViewModel = new SketchViewModel({
    view: view,
    layer: droneLayer,
    pointSymbol: {
      type: "simple-marker",
      style: "circle",
      size: 20,
      color: [255, 255, 0],
      outline: {
        color: [50, 50, 50],
        width: 5,
      },
    },
  });
  gotoSketchViewModel.create("point");

  graphic = null;

  gotoSketchViewModel.on("create", function(event) {
    if (event.state === "complete") {
      setValues(that, event.graphic);
      gotoSketchViewModel.update(graphic);
    }
  });
  gotoSketchViewModel.on("update", function(event) {
    setValues(that, event.graphics[0]);
  });

  gotoSketchViewModel.on("delete", function() {
    gotoSketchViewModel.create("point");
    that.altitude = 0;
    that.latitude = 0;
    that.longitude = 0;
  });
}

function setValues(that, graph) {
  graphic = graph;
  that.latitude = round(graphic.geometry.latitude);
  that.longitude = round(graphic.geometry.longitude);
  if (that.relativeAltitude == 0) {
    that.altitude = roundAlt(graphic.geometry.z);
  } else {
    if (that.selectedDrone) {
      that.altitude = roundAlt(
        graphic.geometry.z - that.selectedDrone.home.altitude
      );
    } else that.altitude = null;
  }
}
function round(num) {
  return Math.round(num * 1000000) / 1000000;
}

function roundAlt(num) {
  return Math.round(num * 1000) / 1000;
}

export default {
  data() {
    return {
      latitude: 0,
      longitude: 0,
      altitudeMin: 0,
      altitudeMax: 100,
      altitude: 0,
      relativeAltitude: 0,
      speedMin: 0,
      speedMax: 100,
      speed: 0,
      rules: {
        altitudeMax: (value) => value <= this.altitudeMax || "Too large",
        altitudeMin: (value) => value >= this.altitudeMin || "Too small",
        speedMax: (value) => value <= this.speedMax || "Too large",
        speedMin: (value) => value >= this.speedMin || "Too small",
        position: () =>
          (this.longitude != 0 && this.latitude != 0) ||
          "Click map to select a position",
      },

      clickListener: null,
      lastMapPoint: null,
      noWP: true,
    };
  },

  computed: {
    position: function() {
      return `${this.latitude}, ${this.longitude}`;
    },
    ...mapGetters({
      selectedDrone: "addedDroneObject/getSelectedDrone",
    }),
  },

  created: function() {
    this.altitudeMin = getMagnitudeBounds("goto").altitude.min;
    this.altitudeMax = getMagnitudeBounds("goto").altitude.max;
    this.altitude = getMagnitudeBounds("goto").altitude.default;
    this.speedMin = getMagnitudeBounds("goto").speed.min;
    this.speedMax = getMagnitudeBounds("goto").speed.max;
    this.speed = getMagnitudeBounds("goto").speed.default;

    newGotoSketchViewModel(this);
  },
  destroyed: function() {
    droneLayer.remove(graphic);
    gotoSketchViewModel.destroy();
  },

  watch: {
    relativeAltitude: function() {
      //if the user has clicked and a graphic is being edited
      if (gotoSketchViewModel.updateGraphics.items.length !== 0) {
        gotoSketchViewModel.update(graphic);
      }
    },
  },

  methods: {
    emitParameters() {
      this.$emit("command-parameters", {
        latitude: this.latitude,
        longitude: this.longitude,
        altitude: this.altitude,
        altitudeType: this.relativeAltitude == "0" ? 0 : 1,
        speed: this.speed,
      });
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
