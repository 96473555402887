<template>
      <v-card v-if="openMenu" class="areaControls">
        <span>
          <v-tooltip
            bottom
            v-for="(button, index) in buttons"
            v-bind:key="index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                fab
                v-on="on"
                class="ml-1"
                @click.stop="button.method"
                small
              >
                <v-icon>
                  {{ button.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ button.name }}</span>
          </v-tooltip>
        </span>
      </v-card>
</template>
<script>
import {
  saveArea,
  cancelArea,
  deleteArea,
} from "../../esriAPI/AreaManager/AreaEditor";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      chosenFile: null,
      dialog: false,//TODO finish
      buttons: [
        { name: "Save & Finish Area", method: saveArea, icon: "mdi-check" },
        { name: "Close Editor", method: cancelArea, icon: "mdi-close" },
        { name: "Remove Area", method: deleteArea, icon: "mdi-delete" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      appState: "state/getAppState",
    }),

    openMenu: function () {
      return this.appState == "AREA_CREATION";
    },
  },
  methods: {

    ...mapActions({
      changeAppState: "state/changeAppstate",
    }),
  },
};
</script>
<style scoped>
  .areaControls {
    position: fixed;

  }
  </style>
