<template>
  <div id="map-wrapper" v-resize="onResize">
    <EsriMap id="map" />
    <MPMenuBar
      class="component-wrapper"
      id="toolbar"
      v-on:open-navigation-bar="openNavBar()"
      v-on:open-MP-settings="openMPSettings()"
    />
    <MissionSelection></MissionSelection>

    <div id="left-components">   
      <Telemetry
        v-if="telemetry != null && selectedMission != null"
        :systemId="selectedMission.systemId"
        :telemetry="telemetry"
      />
      <AddedMissions
        id="added-missions"
        v-if="telemetry != null && selectedMission != null"
      />
    </div>

    <SpeedControl
      v-if="selectedMission != null"
      id="controls"
      v-on:open-simulation-settings="openSimulationSettings"
    ></SpeedControl>

    <div id="timeSliderDiv"></div>

    <NavigationBar class="component-wrapper" ref="navBar" />

    <Alerts class="component-wrapper" id="error-alert" />

    <SimulationSettings
      class="component-wrapper"
      id="simulation-settings"
      ref="simulationSettings"
    ></SimulationSettings>
  </div>
</template>

<script>
import EsriMap from "../components/MissionPlayer/EsriMap.vue";
import NavigationBar from "../components/NavigationBar";
import Alerts from "../components/Alerts";
import MPMenuBar from "../components/MissionPlayer/MPMenuBar";
import MissionSelection from '@/components/MissionPlayer/MissionSelection.vue';
import Telemetry from "@/components/Telemetry.vue";
import AddedMissions from "@/components/MissionPlayer/AddedMissions.vue";
//import TimeSliderComponent from "@/components/MissionPlayer/TimeSliderComponent.vue";
import SpeedControl from "@/components/MissionPlayer/SpeedControl.vue";
import SimulationSettings from "@/components/MissionPlayer/SimulationSettings.vue";

import { mapGetters } from "vuex";

//TODO: parameterize events so they dont get long
export default {
  name: "Home",
  components: {
    EsriMap,
    NavigationBar,
    Alerts,
    MPMenuBar,
    MissionSelection,
    Telemetry,
    AddedMissions,
    SpeedControl,
    SimulationSettings,
  },

  computed: mapGetters({
    state: "missionPlayer/getMissionPlayerState",
    selectedMission: "missionPlayer/getSelectedMission",
    telemetry: "missionPlayer/getCurrentTelemetry"
  }),

  data() {
    return {
      swp: "",
      height: "50vh",
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  beforeCreate() {
    this.$store.dispatch("state/changeAppstate", "MISSION_PLAYER");
  },
  mounted() {
    this.onResize();
  },
  unmounted() {
    this.$store.dispatch("state/setLastAppstate");
  },

  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    openNavBar() {
      this.$refs.navBar.openNavBar();
    },
    openMPSettings() {
      this.$refs.mpSet.overlay();
    },
    openSimulationSettings() {
      this.$refs.simulationSettings.overlay();
    }
  },
};
</script>

<style scoped>
#map-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}
#map {
  width: 100vw;
  height: 100vh;
}
.component-wrapper {
  position: absolute;
}

#left-components {
  position: absolute;
  right: 0px;
  top: 0px;
  margin: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 95vh;
}

#controls {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 5px;
}

/*Necessary due to capacitor flicker bug */
#swipeableBottomSheet {
  height: 100%;
  width: 100%;
}

#toolbar {
  top: 5px;
  left: 64px;
}

#telemetry {
  top: 2px;
  right: 5px;
}

#error-alert {
  bottom: 20px;
  right: 100px;
}

#timeSliderDiv {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 20px;
}
</style>
