<template>
  <v-card width="300" rounded="lg">
    <v-container pa-0>
      <v-card-title class="h3 pl-4 pb-0 pt-2">Telemetry: {{ systemId }}</v-card-title>
      <v-card-text>
        <v-container pa-0>
          <v-row dense>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">SPD</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "meter-per-second",
                      maximumFractionDigits: 2,
                    }).format(
                      Math.sqrt(
                        telemetry.speedX ** 2 +
                        telemetry.speedY ** 2
                      )
                    )
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">V_SPD</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "meter-per-second",
                      maximumFractionDigits: 2,
                    }).format(telemetry.speedZ)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">HDG</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      maximumFractionDigits: 1,
                    }).format(telemetry.track)
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">PITCH</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      maximumFractionDigits: 1,
                    }).format(telemetry.pitch * 180 / Math.PI)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">ROLL</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      maximumFractionDigits: 1,
                    }).format(telemetry.roll * 180 / Math.PI)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">YAW</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      maximumFractionDigits: 1,
                    }).format(telemetry.yaw * 180 / Math.PI)
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card outlined @click.stop="toggleAlt()">
                <div class="font-weight-bold text-center">{{ altitudeLabel }}</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "meter",
                      maximumFractionDigits: 2,
                    }).format(altitudeValue())
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">LAT</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      minimumFractionDigits: 8,
                    }).format(telemetry.latitude)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined>
                <div class="font-weight-bold text-center">LON</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "unit",
                      unit: "degree",
                      unitDisplay: "narrow",
                      minimumFractionDigits: 8,
                    }).format(telemetry.longitude)
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-card outlined @click.stop="(selectedRad = 0)" :disabled="(selectedRad == 0)">
                <div class="font-weight-bold text-center">CPS</div>
                <div class="font-italic text-center">
                  {{
                    new Intl.NumberFormat("en-US", {
                      style: "decimal",
                    }).format(displayRad() ? this.telemetry.countsPerSecond : 0)
                  }}
                </div>
              </v-card>
            </v-col>
            <v-col>
              <v-card outlined @click.stop="(selectedRad = 1)" :disabled="(selectedRad == 1)">
                <div class="font-weight-bold text-center">CPS N</div>
                <div class="font-italic text-center">
                  {{
  new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 1,
  }).format(displayRad() ? this.telemetry.normalizedCountsPerSecond : 0)
                  }}
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        <!--
        <div class="text-center text-h5 pt-1">
          {{ selectedDrone.droneStatus.mode }}
        </div>
        -->
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { Telemetry as TelemetryDM } from "@/utils/DroneDataModel";
import { changeRadiationType, DEFAULT_RAD } from '@/esriAPI/RadiationManager';
import { changeRadiationType as changeRadiationTypeSim } from "@/mission_player/esri/RadiationManager";
import { MissionPlayerStateEnum } from '@/store/modules/missionPlayer';

export default {
  data: () => ({
    altitudeType: 0,
    selectedRad: DEFAULT_RAD,
  }),
  watch: {
    selectedRad: function (rad) {
      if (this.missionPlayerState == MissionPlayerStateEnum.PLAYING_MISSION) {
        changeRadiationTypeSim(rad);
      } else {
        changeRadiationType(rad);
      }
    }
  },

  props: {
    systemId: String,
    telemetry: TelemetryDM,
  },
  computed: {
    ...mapGetters({
      selectedDrone: "addedDroneObject/getSelectedDrone",
      missionPlayerState: "missionPlayer/getState",
    }),
    altitudeLabel() {
      switch (this.altitudeType) {
        case 0:
          return "ALT";
        case 1:
          return "ALT_R";
        case 2:
          return "ALT_L";
      }
      return "";
    }
  },
  methods: {

    toggleAlt() {
      this.altitudeType = (this.altitudeType + 1) % 3;
    },
    radiationValue() {
      //-1 means no radiation being read from backend (not enabled)
      if (this.telemetry.counts != -1) {
        return this.telemetry.counts
      } else {
        return 0;
      }
    },
    displayRad() {
      return this.telemetry.counts != -1;
    },
    altitudeValue() {
      switch (this.altitudeType) {
        case 0:
          return this.telemetry.absoluteAltitude;
        case 1:
          return this.telemetry.relativeAltitude;
        case 2:
          return this.telemetry.laserAltitude;
      }
    },
  },
};
</script>

<style>

</style>
