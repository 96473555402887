export function translateTitle(command: string) {
  switch (command) {
    case "takeoff":
      return "Takeoff";
    case "land":
      return "Land";
    case "rth":
      return "RTH";
    case "loiter":
      return "Loiter";
    case "turnyaw":
      return "Turn Yaw";
    case "gimbalturn":
      return "Guimbal Turn";
    case "changespeed":
      return "Change Speed";
    case "changealtitude":
      return "Change Altitude";
    case "updatespeed":
      return "M. Update Speed";
    case "upload":
      return "Upload FP";
    case "stop":
      return "M. Stop";
    case "start":
      return "M. Start";
    case "pause":
      return "M. Pause";
    case "resume":
      return "M. Resume";
    case "sethome":
      return "Set Home";
    case "goto":
      return "Go To";
    case "radpoint":
      return "Radiation Point";
    case "radstop":
      return "Radiation Stop";
    case "captureinit":
      return "Capture Initialize";
    case "capturestop":
      return "Capture Stop";
  }
}

export function translateToInt(command: string) {
  switch (command) {
    case "takeoff":
      return 1;
    case "land":
      return 2;
    case "rth":
      return 3;
    case "loiter":
      return 4;
    case "turnyaw":
      return 5;
    case "gimbalturn":
      return 6;
    case "changespeed":
      return 7;
    case "changealtitude":
      return 8;
    case "updatespeed":
      return 15;
    case "upload":
      return 10;
    case "stop":
      return 14;
    case "start":
      return 11;
    case "pause":
      return 12;
    case "resume":
      return 13;
    case "sethome":
      return 0;
    case "goto":
      return 9;
    case "radpoint":
      return 31;
    case "radstop":
      return 32;
    case "captureinit":
      return 41;
    case "capturestop":
      return 42;
  }
}

export function getMagnitudeBounds(command: string) {
  switch (command) {
    case "takeoff":
      return { altitude: { min: 1, max: 500, default: 50 } };
    case "turnyaw":
      return {
        angle: { min: -360, max: 360, default: 90 },
        speed: { max: 20, min: 1, default: 10 },
      };
    case "gimbalturn":
      return {
        pitch: { min: -360, max: 360, default: 90 },
        roll: { min: -360, max: 360, default: 90 },
        yaw: { min: -360, max: 360, default: 90 },
      };
    case "changespeed":
      return { speed: { min: 1, max: 20, default: 10 } };
    case "changealtitude":
      return { altitude: { min: -500, max: 500, default: 10 } };
    case "updatespeed":
      return { speed: { min: 1, max: 20, default: 10 } };
    case "sethome":
      return { altitude: { min: 0, max: 3000, default: 300 } };
    case "goto":
      return {
        altitude: { min: -200, max: 2000, default: 10 },
        speed: { min: 1, max: 20, default: 10 },
      };
    default:
      return null;
  }
}
