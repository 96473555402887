<template>
  <div class="wrapper">
    <Telemetry
      v-if="selectedDrone != null && !$vuetify.breakpoint.smAndDown"
      :systemId="selectedDrone.systemId"
      :telemetry="selectedDrone.telemetry"
    />

    <Commands
      v-if="selectedDrone != null && !$vuetify.breakpoint.smAndDown"
      v-on:open-command-window="openCommandConfirmationDialog"
      v-on:start-recording="startCounter"
      v-on:stop-recording="stopCounter"
    />

    <CommandConfirmationDialog
      ref="commandConfirmationDialog"
      v-if="!$vuetify.breakpoint.smAndDown"
    />

    <v-chip
      v-if="selectedDrone != null && recording"
    >
    Recording: {{ counter }}
    </v-chip>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Telemetry from "@/components/Telemetry.vue";
import Commands from "@/components/Commands.vue";
import CommandConfirmationDialog from "@/components/CommandConfirmationDialog.vue";
export default {
  components: {
    Telemetry,
    Commands,
    CommandConfirmationDialog,
  },
  data() {
    return {
      counter: 0,
    };
  },

  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
    appState: "state/getAppState",
    recording: "addedDroneObject/getRecording",
    recordingStart: "addedDroneObject/getRecordingStart",
  }),

  methods: {
    openCommandConfirmationDialog(command) {
      this.$refs.commandConfirmationDialog.openCommandConfirmationDialog(
        command
      );
    },
    startCounter() {
      const now = new Date();
      const seconds = Math.floor((now.getTime() - this.recordingStart.getTime()) / 1000);
      this.counter = seconds;
      if (this.recording) setTimeout(this.startCounter,1000);
    },
    stopCounter() {
      this.counter = 0;
    }
  },
};
</script>

<style scoped>
.wrapper {
  margin: 5px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 95vh;
}
</style>
