<template>
  <div id="map-wrapper" v-resize="onResize">
    <EsriMap id="map" />
    <MenuBar
      class="component-wrapper"
      id="toolbar"
      v-on:open-navigation-bar="openNavBar"
      v-on:open-fp-settings="openFPSettings"
      v-on:open-fp-statistics="openFPStatistics"
      v-on:open-general-settings="openGeneralSettings"
      v-on:open-file-fp="openFileFP"
      v-on:open-area-editor="openAreaEditor"

    />

    <AddDrone
      class="component-wrapper"
      id="add-drone"
      v-if="!$vuetify.breakpoint.smAndDown"
    />

    <LeftComponentsWrapper class="component-wrapper" id="left-components" />

    <FPinformation
      class="component-wrapper"
      id="fp-information"
      v-if="appState == 'FLIGHT_PLAN_PLOTED' && !$vuetify.breakpoint.smAndDown"
      :width="1200"
    />

    <FPsettings class="component-wrapper" id="fp-settings" ref="fpSettings" />
    <FPstatistics
      class="component-wrapper"
      id="fp-statistics"
      ref="fpStatistics"
    />

    <GeneralSettings
      class="component-wrapper"
      id="general-settings"
      ref="generalSettings"
    />

    <AreaEditor
    class="component-wrapper"
      id="general-settings"
      ref="areaEditor"
    />

    <FileFP class="component-wrapper" id="file-fp" ref="fileFP" />

    <div v-if="$vuetify.breakpoint.smAndDown">
      <SwipeableBottomSheet
        id="swipeableBottomSheet"
        ref="swipeableBottomSheet"
      >
        <AddDrone />
        <Telemetry v-if="selectedDrone != null" />

        <Commands
          v-if="selectedDrone != null"
          v-on:open-command-window="openCommandConfirmationDialog"
        />

        <CommandConfirmationDialog ref="commandConfirmationDialog" />

        <FPinformation
          :width="windowSize.x"
          v-if="appState == 'FLIGHT_PLAN_PLOTED'"
        />

        <ElevationProfileComponent
          v-if="
            appState == 'FLIGHT_PLAN_PLOTED' && $vuetify.breakpoint.smAndDown
          "
        />
      </SwipeableBottomSheet>
    </div>

    <NavigationBar class="component-wrapper" ref="navBar" />

    <Alerts class="component-wrapper" id="error-alert" />
  </div>
</template>

<script>
import EsriMap from "../components/EsriMap";
import NavigationBar from "../components/NavigationBar";
import MenuBar from "../components/Toolbar/MenuBar";
import Telemetry from "../components/Telemetry";
import Commands from "../components/Commands";
import CommandConfirmationDialog from "../components/CommandConfirmationDialog";
import AddDrone from "../components/AddDrone";
import Alerts from "../components/Alerts";
import FPinformation from "../components/FlightPlan/FPinformation";
import FPsettings from "../components/FlightPlan/FPsettings";
import FPstatistics from "../components/FlightPlan/FPstatistics";
import FileFP from "../components/FlightPlan/FileFP";
import AreaEditor from "../components/Area/AreaEditor";
import GeneralSettings from "../components/GeneralSettings";
//import { SwipeableBottomSheet } from "vue-swipeable-bottom-sheet";
import SwipeableBottomSheet from "../components/SwipeableBottomSheet.vue";
import LeftComponentsWrapper from "@/components/LeftComponentsWrapper.vue";
import ElevationProfileComponent from "@/components/FlightPlan/ElevationProfileComponent.vue";

import { mapGetters, mapActions } from "vuex";
import { connect } from "@/websocket/websocket";

//TODO: parameterize events so they dont get long
export default {
  name: "Home",
  components: {
    SwipeableBottomSheet,
    EsriMap,
    NavigationBar,
    MenuBar,
    Telemetry,
    Commands,
    CommandConfirmationDialog,
    AddDrone,
    Alerts,
    FPinformation,
    FPsettings,
    FPstatistics,
    GeneralSettings,
    AreaEditor,
    FileFP,
    ElevationProfileComponent,
    LeftComponentsWrapper,
  },

  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
    appState: "state/getAppState",
    connected: "auth/getConnected",
  }),

  data() {
    return {
      swp: "",
      height: "50vh",
      windowSize: {
        x: 0,
        y: 0,
      },
    };
  },

  mounted() {
    this.onResize();
    if (this.getTimerId() === 0)
      this.startDetectedDroneListTimer();
    if (!this.connected)
      connect();
    this.recoverMapState();
  },

  methods: {
    ...mapActions({
      startDetectedDroneListTimer: "detectedDroneList/startTimer",
      recoverMapState: "state/recoverMapState",
    }),
    ...mapGetters({
      getTimerId: "detectedDroneList/getTimerId",
    }),
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },

    openNavBar() {
      this.$refs.navBar.openNavBar();
    },
    openCommandConfirmationDialog(command) {
      this.$refs.commandConfirmationDialog.openCommandConfirmationDialog(
        command
      );
    },
    openFPSettings() {
      this.$refs.fpSettings.overlay();
    },
    openFPStatistics() {
      this.$refs.fpStatistics.overlay();
    },
    openGeneralSettings() {
      this.$refs.generalSettings.overlay();
    },
    openFileFP(data) {
      this.$refs.fileFP.overlay(data);
    },
    openAreaEditor(){
      this.$refs.areaEditor.overlay();
    }
  },
};
</script>

<style scoped>
#map-wrapper {
  width: 100vw;
  height: 100vh;
  position: relative;
}
#map {
  width: 100vw;
  height: 100vh;
}
.component-wrapper {
  position: absolute;
}

#left-components {
  right: 0px;
  top: 0px;
}

/*Necessary due to capacitor flicker bug */
#swipeableBottomSheet {
  height: 100%;
  width: 100%;
}

#toolbar {
  top: 5px;
  left: 64px;
}

#telemetry {
  top: 2px;
  right: 5px;
}

#commands {
  top: 250px;
  right: 5px;
}

#command-confirmation-dialog {
  top: 365px;
  right: 5px;
}

#add-drone {
  bottom: 20px;
  left: 5px;
}

#fp-information {
  left: 50%;
  bottom: -265px;
  transform: translate(-50%, 0%);
  transition: bottom 250ms cubic-bezier(0.2, 0.6, 0.4, 1);
}

#fp-information:hover {
  bottom: 0px;
}

#error-alert {
  bottom: 20px;
  right: 100px;
}
</style>
