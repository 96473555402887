import { FlightPlan, Waypoint } from "@/utils/DataModels";
import { DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { sendWebSocketMessage } from "@/websocket/websocket";
import { Point } from "@arcgis/core/geometry";
import { vuexStore } from "../../main";
import { FlightPlanProtocolTypes } from "../../utils/FlightPlanProtocol";
import { FPTypes } from "../../utils/FPDataModels";
import {
  SocketMessageTypes,
  WebSocketMessage
} from "../../utils/WebsocketProtocol";
import {
  layerFP, map, spatialReference, view
} from "../EsriMap";



let selectedDroneId: string;



export function getStageEnd(): string {
  const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP || FP.waypoints.length == 0) {
    return FPTypes.INROUTE;
  }
  const lastWPStage: string = FP.waypoints[FP.waypoints.length - 1].stage;

  if (lastWPStage === FPTypes.INROUTE) {
    return FPTypes.INROUTE;
  }
  if (lastWPStage === FPTypes.MISSION) {
    return FPTypes.OUTROUTE;
  }
  if (lastWPStage === FPTypes.OUTROUTE) {
    return FPTypes.OUTROUTE;
  }
  return FPTypes.INROUTE;
}

export function getNextStage(prevStage: string, futureStage: string): string {
  if (prevStage === FPTypes.INROUTE) {
    return FPTypes.INROUTE;
  }
  if (prevStage === FPTypes.MISSION && futureStage === FPTypes.MISSION) {
    return FPTypes.MISSION;
  }
  if (prevStage === FPTypes.MISSION && futureStage === FPTypes.OUTROUTE) {
    return FPTypes.OUTROUTE;
  }
  if (prevStage === FPTypes.OUTROUTE) {
    return FPTypes.OUTROUTE;
  }
  return FPTypes.INROUTE;
}


export function changeAltitude(relative: boolean) {
  const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (FP && layerFP.graphics.length != 0) {
    const extent = layerFP.graphics.getItemAt(0).geometry.extent;
    const elevationSampler = view.map.ground.createElevationSampler(extent);
    const elevation: any[] = []
    const promises: any[] = [];

    FP.waypoints.forEach(function (waypoint: Waypoint) {
      promises.push(
        map.ground.load()
          .then(async function () {
            const point = new Point({
              spatialReference: spatialReference,
              hasZ: true,
              latitude: waypoint.lat,
              longitude: waypoint.lon,
              z: waypoint.altitude,
            });
            const zEnrichedGeometry: any = (await elevationSampler).queryElevation(point);
            elevation.push(zEnrichedGeometry.z)
          })
      );
    });

    Promise.all(promises).then(() => {
      for (let i = 0; i < FP.waypoints.length; i++) {
        if (relative) {
          FP.waypoints[i].altitude = FP.waypoints[i].altitude - elevation[i];
        } else {
          FP.waypoints[i].altitude = FP.waypoints[i].altitude + elevation[i];
        }
      }
      sendFlightPlanToBackend(FP, FlightPlanProtocolTypes.UPDATED_FP);
    }
    );



  }
}


let WEBSOCKET_PROTOCOL = "proto";
WEBSOCKET_PROTOCOL = "proto";
WEBSOCKET_PROTOCOL = "normal";

export function sendFlightPlanToBackend(FP: FlightPlan, typeProtocol: String) {

  selectedDroneId = vuexStore.getters["addedDroneObject/getSelectedDroneId"];
  const message = new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
    systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
    type: typeProtocol,
    flightPlan: FP,
  })

  if (!(WEBSOCKET_PROTOCOL == "proto")) {
    sendWebSocketMessage(message);
    return;
  }

  const protobuf = require('protobufjs');
  run().catch(err => console.log(err));
  async function run() {
    const root = await protobuf.load('/GPB/FlightPlan.proto');
    const protoFP = root.lookupType('GPB.FlightPlan');
    const protoWrapper = root.lookupType('GPB.Wrapper');
    if (!protoFP.verify(FP)) {
      const binarySerialized = protoWrapper.encode(message).finish();
      sendWebSocketMessage(binarySerialized, true);
    }
  }
}


