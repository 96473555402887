export const FPTypes = {
  TAKEOFF: "takeOff",
  MISSION: "mission",
  OUTROUTE: "outRoute",
  INROUTE: "inRoute",
  RTL: "RTL",
  HOVER: "hover",
  LANDING: "landing",
  BACK_AND_FORTH: "backAndForth",
  SMART_RANDOM: "smartRandom",
  BASIC_RANDOM: "basicRandom",
  LEFT: "left",
  RIGHT: "right",
  NONE: "none",
  TIME: "time",
  TURNS: "turns",
  AREA: "area",
};
