<template>
  <v-toolbar dense floating max-width="80vw" v-resize="onResize">
    <v-app-bar-nav-icon
      @click.stop="$emit('open-navigation-bar')"
    ></v-app-bar-nav-icon>
    <div id="searchDiv" v-if="!toolsMode"></div>
    <v-divider
      vertical
      class="ml-4"
      v-if="!$vuetify.breakpoint.smAndDown"
    ></v-divider>
    <v-btn
      @click.stop="toolsMode = !toolsMode"
      v-if="$vuetify.breakpoint.smAndDown"
      icon
      fab
      samll
    >
      <v-icon v-if="!toolsMode">mdi-draw</v-icon>
      <v-icon v-else>mdi-magnify</v-icon>
    </v-btn>
    <GeneralTools
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
      v-on:open-fp-settings="$emit('open-fp-settings')"
      v-on:open-general-settings="$emit('open-general-settings')"
      v-on:open-fp-statistics="
        (data) => {
          $emit('open-fp-statistics', data);
        }
      "
    />
    <FlightPlanTools
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
    />
    <AreaTools
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
      v-on:open-area-editor="$emit('open-area-editor')"
    /> 
    
    <LayerTools
      id="layer-tools"
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
      v-on:open-layer-manager="
        (data) => {
          $emit('open-layer-manager', data);
        }
      "
    />
    <FileTools
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
      v-on:open-file-fp="
        (data) => {
          $emit('open-file-fp', data);
        }
      "
    />
    <RadiationTools
      v-if="$vuetify.breakpoint.smAndDown ? toolsMode : true"
    />
  </v-toolbar>
</template>

<script>
import GeneralTools from "./GeneralTools";
import FlightPlanTools from "./FlightPlanTools";
import AreaTools from "./AreaTools";
import FileTools from "./FileTools";
import LayerTools from "./LayerTools";
import RadiationTools from './RadiationTools';

import { newSearch } from "@/esriAPI/EsriMap";

export default {
  data() {
    return {
      toolsMode: true,
      small: null,
    };
  },
  components: {
    GeneralTools,
    FlightPlanTools,
    AreaTools,
    FileTools,
    LayerTools,
    RadiationTools
  },
  watch: {
    toolsMode: function (val) {
      if (!val) {
        setTimeout(function () {
          newSearch();
        }, 0);
      }
    },
  },
  methods: {
    onResize() {
      if (this.small && !this.$vuetify.breakpoint.smAndDown && this.toolsMode) {
        setTimeout(function () {
          newSearch();
        }, 50);
      }
      this.small = this.$vuetify.breakpoint.smAndDown;
    },
  },
};
</script>
<style scoped>
.esri-search {
  width: 100%;
}
#searchDiv {
  max-width: 200px;
}
</style>
