import { vuexStore } from "@/main";
import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import MissionPlayer from "../views/MissionPlayer.vue";
import Settings from "../views/Settings.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/missionplayer",
    name: "MissionPlayer",
    component: MissionPlayer,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
  },
];

const router = new VueRouter({
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedIn: boolean = vuexStore.getters["auth/getLoggedIn"];
  if (!loggedIn && to.name !== "Login") {
    next({ name: "Login" });
    return;
  }

  switch (to.name) {
    case "Home":
      if (!vuexStore.getters["auth/getRoles"].includes("operator")) {
        next(false);
        return;
      }
      break;
    case "MissionPlayer":
      if (!vuexStore.getters["auth/getRoles"].includes("viewer")) {
        next(false);
        return;
      }
      break;
    case "Settings":
      if (!vuexStore.getters["auth/getRoles"].includes("admin") ||
        !vuexStore.getters["auth/getRoles"].includes("operator")) {
        next(false);
        return;
      }
  }

  next();
});


export default router;
