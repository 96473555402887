<template>
  <v-navigation-drawer v-model="sidebar" absolute temporary>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="require('../assets/drone.png')"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title">Ground Station 1</v-list-item-title>
          <v-list-item-subtitle>Mission Director</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item-group v-model="item" color="primary">
        <v-list-item v-for="(item, i) in items" :key="i" :to="item.path">
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title v-text="item.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <template v-slot:append>
      <div id="change-cartography"></div>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      sidebar: false,

      item: 0,
      items: [
        { text: "Drone Control", icon: "mdi-drone", path: "/" },
        {
          text: "Drone View",
          icon: "mdi-account-eye-outline",
          path: "missionplayer",
        },
        { text: "Settings", icon: "mdi-cog", path: "settings" },
      ],
      img: "../assets/drone.png",
    };
  },
  methods: {
    openNavBar() {
      this.sidebar = !this.sidebar;
    },
  },
};
</script>

<style scoped>
#change-cartography {
  bottom: 15px;
  margin: auto;
}
</style>
