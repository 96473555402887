import { FlightPlan } from "./DataModels";

export const DUMMY_DRONE_ID = "-1";

export class Drone {
  systemId: string;
  batteryManager: BatteryManager;
  droneStatus: DroneStatus;
  goToWP: GoToWP;
  gpsData: GpsData;
  home: Home;
  lteData: LteData;
  telemetry: Telemetry;
  flightPlan: FlightPlan;

  constructor(data: Drone) {
    this.systemId = data.systemId;
    this.batteryManager = data.batteryManager;
    this.droneStatus = data.droneStatus;
    this.goToWP = data.goToWP;
    this.gpsData = data.gpsData;
    this.home = data.home;
    this.lteData = data.lteData;
    this.telemetry = data.telemetry;
    this.flightPlan = data.flightPlan;
  }
}

type BatteryManager = {
  batteryPercentage: number;
  numberOfBatteryCells: number;
};

type DroneStatus = {
  ARM: "ARMED" | "DISARMED";
  battery: number;
  mode: string;
  mavlinkState: string;
};

export type GoToWP = {
  altitude: number | null;
  latitude: number | null;
  longitude: number | null;
};

type GpsData = {
  latitude: number;
  longitude: number;
  altitude: number;
  timestamp: number;
};

export type Home = {
  latitude: number;
  longitude: number;
  altitude: number;
  timestamp: number;
};

type LteData = {
  IMEI: number;
  altitude: number;
  battery: number;
  bytesReceived: number;
  bytesSent: number;
  channelId: number;
  latitude: number;
  longitude: number;
  operator: number;
  signalQuality: number;
  timestamp: number;
};

export type Telemetry = {
  absoluteAltitude: number;
  accX: number;
  accY: number;
  accZ: number;
  latitude: number;
  longitude: number;
  pitch: number;
  relativeAltitude: number;
  roll: number;
  speedX: number;
  speedY: number;
  speedZ: number;
  timestamp: number;
  track: number;
  yaw: number;
  counts: number;
  countsPerSecond: number;
  normalizedCountsPerSecond: number;
  laserAltitude: number;
};
