<template>
  <v-card class="grid pt-2 px-2 overflow-x-auto" width="950px" height="320">
    <!-- <v-container width="75%"> -->
    <v-data-table
      v-on:dblclick:row="rowDblClicked"
      :items-per-page="-1"
      dense
      :headers="headers"
      fixed-header
      hide-default-footer
      :items="FP.waypoints"
      class="elevation-1 table"
      id="table"
      height="310"
      disable-sort
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:[`header.altitude`]="{ header }">
        <v-btn type="button" x-small :color="buttonColorAlt" @click="altClick">
          {{ header.text }}
        </v-btn>
      </template>

      <template v-slot:[`item.lat`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.lat"
          @open="open"
          @close="close"
        >
          {{ `${item.lat ? item.lat.toFixed(9) : item.lat}º`}}
          <template v-slot:input>
            <v-text-field
              v-model.number="item.lat"
              label="Edit"
              single-line
              counter
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:[`item.lon`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.lon"
          @open="open"
          @close="close"
        >
          {{ `${item.lon ? item.lon.toFixed(9) : item.lon}º`}}
          <template v-slot:input>
            <v-text-field
              v-model.number="item.lon"
              label="Edit"
              single-line
              counter
              type="number"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

       

      <template v-slot:[`item.altitude`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.altitude"
          @open="open"
          @close="close"
        >
          {{ item.altitude ? item.altitude.toFixed(3) : item.altitude }}
          <template v-slot:input>
            <v-text-field
              v-model.number="item.altitude"
              label="Edit"
              single-line
              counter
              type="number"
              @keydown="updateAltitudes($event, item)"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.speed`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.speed"
          @open="open"
          @close="close"
        >
          {{ `${item.speed ? item.speed.toFixed() : item.speed} m/s` }}
          <template v-slot:input>
            <v-text-field
              v-model.number="item.speed"
              label="Edit"
              single-line
              counter
              type="number"
              :min="1"
              @change="updateSpeeds(item.speed)"
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
    </v-data-table>
    <v-overlay absolute :value="configActionMenu" opacity="0.8">
      <v-card width="300" flat color="rgba(0,0,0,0)">
        <!-- if we have more than one action with parameters change this -->
        <Takeoff ref="takeoff" v-on:command-parameters="applyActionConfig" />
      </v-card>
      <v-btn @click="getParameters"> Apply </v-btn>
    </v-overlay>
    <ElevationProfileComponent
      v-if="!$vuetify.breakpoint.smAndDown"
      id="elevation-profile-component"
    />
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { view } from "../../esriAPI/EsriMap";
import ElevationProfile from "@arcgis/core/widgets/ElevationProfile";
import Takeoff from "../CommandConfirmationDialogs/Takeoff";
//import { getMagnitudeBounds } from "../../utils/Commands";
import { layerFP } from "@/esriAPI/EsriMap";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import { sqrt } from "mathjs";
// import FPActions from "./FPActions.vue";
import ElevationProfileComponent from "./ElevationProfileComponent.vue";

let elevationProfile;
let loaded = false;
let chartPositionMap = new Map();

function buildElevationProfile() {
  setTimeout(() => {
    const graphic = layerFP.graphics.getItemAt(0);

    // eslint-disable-next-line no-unused-vars
    elevationProfile = new ElevationProfile({
      view: view,
      container: "elevation-profile",
      unit: "meters",
      profiles: [
        {
          type: "input",
          color: "#0099ff",
          title: "Flight Plan Elevation",
        },
        {
          type: "ground",
          title: "Ground Elevation",
          visible: false,
        },
      ],
      input: layerFP.graphics.getItemAt(0),

      visibleElements: {
        chart: true,
        legend: true,
        settingsButton: false,
        sketchButton: false,
        selectButton: false,
      },
    });

    const inputProfile = elevationProfile.profiles.find((item) => {
      return item.type === "input";
    });

    watchUtils.whenEqualOnce(inputProfile, "progress", 1, () => {
      loaded = true;
      const path = graphic.geometry.paths[0];

      chartPositionMap.set(0, 0);
      let i = 1;
      let dist = 0;
      while (i < path.length) {
        const difX = path[i][0] - path[i - 1][0];
        const difY = path[i][1] - path[i - 1][1];
        const d = sqrt(difY ** 2 + difX ** 2);
        dist += d;
        chartPositionMap.set(i, dist);
        i++;
      }

      const totalDistance = dist;
      chartPositionMap.forEach((item, index) => {
        chartPositionMap.set(index, item / totalDistance);
      });
    });
  }, 100);
}

function setHoveredChartPosition(id) {
  if (loaded && elevationProfile.viewModel) {
    elevationProfile.viewModel.hoveredChartPosition = chartPositionMap.get(id);
  }
}

export default {
  components: {
    Takeoff,
    // FPActions,
    ElevationProfileComponent,
  },
  data() {
    return {
      altSameValue: false,
      buttonColorAlt: "",
      switch1: false,
      switch2: false,
      hover: false,
      headers: [
        { text: "Id", value: "order" },
        { text: "Latitude º", value: "lat" },
        { text: "Longitude º", value: "lon" },
        { text: "Altitude m", value: "altitude" },
        { text: "Speed m/s", value: "speed" },
        { text: "Stage", value: "stage" },
        // { text: "Actions", value: "actions" },
      ],

      lastRowClicked: null,
      configActionMenu: false,
      generalSpeed: 5,
    };
  },
  props: {
    width: Number,
  },


  computed: {
    ...mapGetters({
    appState: "state/getAppState",
    FP: "state/getDisplayedFlightPlan",
    selectedDroneId: "addedDroneObject/getSelectedDroneId",
  }),
  },

  created: function () {
    //const modFP = this.FP;
    buildElevationProfile();
  },
  beforeDestroy: function () {
    elevationProfile.destroy();
  },
  mounted: function () {
    let that = this;
    //only minimize flight_plan screen if there has been no click
    let fpInformation = document.getElementById("fp-information");
    if (fpInformation) {
      fpInformation.addEventListener("mouseleave", function () {
        if (that.hover) {
          this.style.bottom = "0px";
        } else {
          //hide
          this.style.bottom = "-265px";
        }
      });

      fpInformation.addEventListener("mouseover", function () {
        this.style.bottom = "0px";
      });
    }
    const rows = document.querySelectorAll("tbody > tr");
    const table = document.querySelector("table");
    table.addEventListener("mouseleave", () => {
      setHoveredChartPosition(null);
    });
    rows.forEach((item) => {
      item.addEventListener("click", () => {
        //document.getElementById("fp-information").style.bottom = "0px";
        const waypointId = parseInt(item.children[0].textContent) - 1;
        this.lastRowClicked = waypointId;
        setHoveredChartPosition(waypointId);
      });
    });
  },

  methods: {
    ...mapActions({
      setDisplayedFlightPlan: "state/setDisplayedFlightPlan",
    }),

    updateSpeeds(newSpeed) {
      newSpeed = newSpeed > 0 ? newSpeed : 1;
      newSpeed = newSpeed < 20 ? newSpeed : 20;
      for (let i = 0; i < this.FP.waypoints.length; i++) {
        this.FP.waypoints[i].speed = newSpeed;
      }
    },

    updateAltitudes(event, item) {
      //Change all altitudes
      if (this.buttonColorAlt == "green" && event.key == "Enter") {
        const newAlt = Math.max(item.altitude, 0.0001);
        for (let i = 0; i < this.FP.waypoints.length; i++) {
          this.FP.waypoints[i].altitude = newAlt;
        }
      //Change on altitude of the specific waypoint
      } else if (event.key == "Enter"){
        const newAlt=Math.max(item.altitude, 0.0001)
        this.FP.waypoints[item.order-1].altitude = newAlt;
      }
    },
    open() {
      this.hover = true;
    },

    close() {
      this.hover = false;
      this.setDisplayedFlightPlan({
        systemId: this.selectedDroneId,
        flightPlan: this.FP,
      });
    },

    altClick() {
      this.altSameValue = !this.altSameValue;
      if (this.altSameValue) {
        this.buttonColorAlt = "green";
      } else {
        this.buttonColorAlt = "";
      }
    },
    close2() {
      this.hover = false;
    },
    rowClicked(item) {
      this.lastRowClicked = item;
      setHoveredChartPosition();
    },
    rowDblClicked(MouseEvent, item) {
      view.goTo({
        center: [item.item.lon, item.item.lat],
        zoom: 17,
        tilt: 40,
      });
    },
    configAction() {
      this.configActionMenu = !this.configActionMenu;
    },

    updateActions(waypoint) {
      this.hover = false;
      this.updateWaypointActions(waypoint);
    },

    getParameters() {
      this.configActionMenu = !this.configActionMenu;
      var refs = Object.values(this.$refs);
      refs.find((el) => el != Object.undifined).emitParameters();
    },
    applyActionConfig(parameters) {
      const waypoint = this.FP.waypoints[this.lastRowClicked];
      const actionIndex = waypoint.actions.findIndex((el) => {
        return el.type === "takeoff"; //if we have more than one action with parameters change this
      });
      let action = waypoint.actions[actionIndex];
      action = { ...action, ...parameters };
      waypoint.actions[actionIndex] = action;
      this.updateWaypointActions(waypoint);
    },
    ...mapActions({
      updateWaypointActions: "state/updateActions",
    }),
  },
};
</script>

<style scoped>
.grid {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 5px;
  justify-items: stretch;
}

#elevation-profile-component {
  width: 40%;
}
.center {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.select {
  padding: 1px 0px 0px 0px;
  height: 40px;
  width: 100px;
}

.v-overlay__content {
  width: 300px;
}
</style>
