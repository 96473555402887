<template>
  <div id="settings-wrapper">
    <v-toolbar dense floating max-width="80vw">
      <v-app-bar-nav-icon
        @click.stop="openNavBar()"
      ></v-app-bar-nav-icon>
    </v-toolbar>
    <NavigationBar class="component-wrapper" ref="navBar" />
    <UsersPanel />
    
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar";
import UsersPanel from "../components/Settings/UsersPanel";

export default {
  name: "Settings",
  components: {
    NavigationBar,
    UsersPanel,
  },
  methods: {
    openNavBar() {
      this.$refs.navBar.openNavBar();
    },
  }
}
</script>

<style>

</style>