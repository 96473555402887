<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        Create Role
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">Create Role</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-text-field
                v-model="name"
                :rules="nameRules"
                label="Role name"
                required
              ></v-text-field>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="creatingRole"
          @click="close"
        >Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid || creatingRole"
          @click="confirm"
        >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "CreateRole",
  data() {
    return {
      dialog: false,
      creatingRole: false,
      valid: false,
      nameRules: [
        v => !!v || "Can't be empty",
        v => !(this.roles.includes(v)) || "Role already exists",
      ],
      roles: [],
      name: '',
    }
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.getRoles();
        this.name = '';
      }
    }
  },
  methods: {
    getRoles() {
      const loc =
        process.env.NODE_ENV === "production"
          ? "https://rima2.ac.upc.edu:8443"
          : window.location.origin;
  
      axios.get(
        loc + "/auth/all_roles"
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't stablish connection");
          this.dialog = false;
          return;
        }
        this.roles = response.data.roles.map(role => role.name);
        console.log(this.roles);
      }).catch((error) => {
        console.log(error);
        this.dialog = false;
        return;
      });
    },
    close() {
      this.dialog = false;
      this.creatingRole = false;
      this.name = '';
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.changingRoles = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.post(
          loc + "/auth/roles/" + this.name,
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't create role");
            this.close();
            return;
          }
  
          this.$emit('role-created');
          this.close();
        }).catch((error) => {
          console.log(error);
          this.close();
          return;
        });
      }
    },
  }
}
</script>

<style>

</style>