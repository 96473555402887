<template>
  <v-overlay absolute :value="popOverlay" opacity="0.8">
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="grey darken-4"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Visualization Settings
          <v-icon>mdi-tune</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Vehicle Settings
          <v-icon>mdi-magnify-scan</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" :eager="true" class="px-5">
          <v-checkbox
            v-model="absoluteAlt"
            label="Absolute Altitude: Add ground level"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-card
            flat
            color="transparent"
          >
          <div>Threshold of radiation counts</div>

            <v-card-text>
              <v-row>
                <v-col class="px-4">
                  <v-range-slider
                    v-model="range"
                    :max="max"
                    :min="min"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="range[0]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(range, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="range[1]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(range, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

        </v-tab-item>
        <v-tab-item value="tab-2" class="px-5">
          <v-checkbox
            v-model="enabledCar"
            label="Enable Car Mode for Vehicles"
          ></v-checkbox>
        </v-tab-item>
        <v-tab-item value="tab-2" class="px-5">
          <v-slider
            v-model="fov"
            class="align-center"
            :max="maxFOV"
            :min="minFOV"
            step="0.5"
            hide-details
            label="Sensor FOV"
          >
            <template v-slot:append>
              <v-text-field
                v-model="fov"
                suffix="º"
                class="mt-0 pt-0"
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
            </template>
          </v-slider>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn color="success" class="center my-5" @click="save"> Save </v-btn>
       
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import { layerFP } from "@/esriAPI/EsriMap";
import { changeAltitude } from "../esriAPI/FlightPlanManager/FlightPlanUtils";
import { mapActions } from "vuex";
import { vuexStore } from '@/main';
import { changeThresholdRange, DEFAULT_THRESH1, DEFAULT_THRESH2 } from "@/esriAPI/RadiationManager";


export default {
  methods: {
    ...mapActions({
      setSensorFOV: "addedDroneObject/setSensorFOV"
    }),
    save() {
      this.overlay();

      this.setSensorFOV(this.fov);

      if (this.prevAbsoluteAlt != this.absoluteAlt) {
        if (this.absoluteAlt) {
          layerFP.elevationInfo = { mode: "absolute-height" };
          changeAltitude(false);
        } else {
          layerFP.elevationInfo = { mode: "relative-to-ground" };
          changeAltitude(true);
        }
      }
      if (this.prevEnabledCar != this.enabledCar) {
        this.toggleCarEnabled();
      }

      changeThresholdRange(this.range);
    },
    overlay() {
      this.popOverlay = !this.popOverlay;
      if (this.popOverlay) {
        this.prevAbsoluteAlt = this.absoluteAlt;
        this.prevEnabledCar = this.enabledCar;
        this.fov = vuexStore.getters["addedDroneObject/getSensorFOV"];
      }
    },
    toggleCarEnabled() {
      this.$store.dispatch("detectedDroneList/toggleCarEnabled");
    },
  },
  data() {
    return {
      min: 0,
      max: 10000,
      range: [DEFAULT_THRESH1, DEFAULT_THRESH2],

      prevEnabledCar: false,
      enabledCar: false,
      absoluteAlt: false,
      prevAbsoluteAlt: false,
      tab: "tab-1",
      popOverlay: false,
      minFOV: 0.5,
      maxFOV: 10,
      fov: vuexStore.getters["addedDroneObject/getSensorFOV"],
    };
  },
};
</script>


