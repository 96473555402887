<template>
  <v-speed-dial v-model="open" bottom right direction="left">
    <template v-slot:activator>
      <v-btn v-model="open" dark fab>
        <v-icon v-if="open">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-cogs
        </v-icon>
      </v-btn>
    </template>
    <v-btn fab @click="speedUp">
      <v-icon>
        mdi-fast-forward
      </v-icon>
    </v-btn>
    <v-btn fab @click="slowDown">
      <v-icon>
        mdi-rewind
      </v-icon>
    </v-btn>
    <v-btn fab @click="openSimulationSettings">
      <v-icon>mdi-cog-outline</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    }
  },
  methods: {
    slowDown() {
      this.$store.dispatch("missionPlayer/slowDown");
    },
    speedUp() {
      this.$store.dispatch("missionPlayer/speedUp");
    },
    openSimulationSettings() {
      this.$emit("open-simulation-settings");
    }
  }
}
</script>

<style>

</style>