<template>
  <v-menu
    offset-y
    open-on-hover
    transition="slide-y-transition"
    close-delay="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon> mdi-file-arrow-up-down-outline </v-icon>
      </v-btn>
    </template>
    <div v-for="(item, index) in items" v-bind:key="index" class="my-1">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="item.method"
            color="default"
            fab
            small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>
  </v-menu>
</template>

<script>
import { getListFiles, checkFPExists } from "../../esriAPI/FileManager";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      openMenu: false,
      items: [
        {
          name: "Cloud",
          method: this.cloudHandler,
          icon: "mdi-cloud-outline",
        },
        {
          name: "Local",
          method: this.localHandler,
          icon: "mdi-folder-outline",
        },
        {
          name: "View",
          method: this.viewFileHandler,
          icon: "mdi-file-eye-outline",
        },
      ],
      message: "",
    };
  },

  computed: {
    ...mapGetters({
      appState: "state/getAppState",
      jsonFP: "state/getDisplayedFlightPlan",
      files: "state/getFileList",
    }),
  },
  //TODO: create new vue for theinput and importand download and the view to dix
  methods: {
    cloudHandler() {
      getListFiles();
      this.$emit("open-file-fp", 2);
    },
    localHandler() {
      getListFiles();
      this.$emit("open-file-fp", 4);
    },
    viewFileHandler() {
      if (checkFPExists(true)) {
        getListFiles();
        this.$emit("open-file-fp", 1);
      }
    },
       ...mapActions({
      changeAppState: "state/changeAppstate",
      setLastAppstate: "state/setLastAppstate",
    }),
  },
};
</script>

