import Vue from "vue";
import Vuex from "vuex";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

import detectedDroneList from "./store/modules/detectedDroneList";
import addedDroneObject from "./store/modules/addedDroneObject";
import state from "./store/modules/state";
import alert from "./store/modules/alert";
import auth from "./store/modules/auth";
import missionPlayer from "./store/modules/missionPlayer";
import "@arcgis/core/assets/esri/themes/dark/main.css";

Vue.config.productionTip = false;
Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    detectedDroneList,
    addedDroneObject,
    alert,
    state,
    auth,
    missionPlayer,
  },
});

export { store as vuexStore };

new Vue({
  router,
  store: store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
