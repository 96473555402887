<template>
  <v-overlay absolute :value="popOverlay" opacity="0.8">
    <v-card max-width="500px">
      <v-tabs
        v-model="tab"
        background-color="grey darken-4"
        centered
        dark
        icons-and-text
        show-arrows
        center-active
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1" :disabled="!validFP">
          Preview
          <v-icon>mdi-file-eye-outline</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
          Load from Cloud
          <v-icon>mdi-cloud-download-outline</v-icon>
        </v-tab>
        <v-tab href="#tab-3" :disabled="!validFP">
          Save to Cloud
          <v-icon>mdi-cloud-upload-outline</v-icon>
        </v-tab>
        <v-tab href="#tab-4">
          Load from Local
          <v-icon>mdi-monitor-share</v-icon>
        </v-tab>

        <v-tab href="#tab-5" :disabled="!validFP">
          Save to Local
          <v-icon>mdi-monitor-arrow-down-variant</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" class="px-5">
          <pre class="jsonFP">{{ FP }} </pre>
        </v-tab-item>
        <v-tab-item value="tab-2" class="px-5">
          <div style="max-width: 400px">
            <v-text-field
              class="my-5"
              v-model="search"
              append-icon="mdi-magnify"
              outlined
              dingle-line
              hide-details
            ></v-text-field>
            <v-data-table
              filterable
              @click:row="clickedFile"
              dark
              :headers="headers"
              :items="files"
              item-key="name"
              class="elevation-1 table my-5"
              :search="search"
              :items-per-page="5"
            >
              <template v-slot:top> </template>
            </v-data-table>
          </div>
        </v-tab-item>
        <v-tab-item value="tab-4" class="px-5">
          <div style="max-width: 400px">
            <v-file-input
              show-size
              dark
              accept=".json"
              label="File Input"
              v-model="chosenFile"
              @change="importTxt"
            ></v-file-input>
          </div>
        </v-tab-item>
        <v-tab-item value="tab-3" class="px-5">
          <v-text-field
            hide-details
            class="my-5"
            v-model="fileName"
            placeholder="Enter file Name"
            outlined
            suffix=".json"
          ></v-text-field>
          <v-btn block class="my-5" color="success" @click="saveFileServer">
            Download
          </v-btn>
        </v-tab-item>
        <v-tab-item value="tab-5" class="px-5">
          <v-text-field
            class="my-5"
            v-model="fileNameLocal"
            placeholder="Enter file Name"
            outlined
            suffix=".json"
          ></v-text-field>
          <v-btn block class="my-5" color="success" @click="saveFileLocal">
            Download
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions class="justify-center">
        <v-btn color="error" @click.stop="overlay">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import {
  importFileBackend,
  sendFileBackend,
  checkFPExists,
} from "../../esriAPI/FileManager";
import { mapGetters } from "vuex";
import { FlightPlanProtocolTypes } from "@/utils/FlightPlanProtocol";
import { deleteArea } from '@/esriAPI/AreaManager/AreaEditor';
import { sendFlightPlanToBackend } from '@/esriAPI/FlightPlanManager/FlightPlanUtils';

export default {
  computed: {
    ...mapGetters({
      appState: "state/getAppState",
      FP: "state/getDisplayedFlightPlan",
      files: "state/getFileList",
    }),
  },
  methods: {
    importTxt() {
      if (this.chosenFile) {
        this.overlay();
        var reader = new FileReader();
        reader.readAsText(this.chosenFile);
        reader.onload = () => {
          try {
            deleteArea(); // clear current area
            const importedFP = JSON.parse(reader.result);
            sendFlightPlanToBackend(importedFP, FlightPlanProtocolTypes.UPDATED_FP);
          } catch (e) {
            this.$store.dispatch("alert/popAlert", {
              id: -1,
              type: "error",
              message: "Error importing file",
            });
          }
        };
      }
    },
    saveFileServer() {
      sendFileBackend(this.fileName);
      this.overlay();
    },
    saveFileLocal() {
      const text = JSON.stringify(this.FP);
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", this.fileNameLocal + ".json");

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);

      this.overlay();
    },

    overlay(data) {
      this.popOverlay = !this.popOverlay;
      if (this.popOverlay) {
        this.validFP = checkFPExists();
        if (data <= 5) {
          this.tab = "tab-" + data;
        } else {
          this.overlay();
        }
      }
    },
    clickedFile(value) {
      this.overlay();
      importFileBackend(value.name);
    },
  },
  data() {
    return {
      chosenFile: null,
      validFP: false,
      search: "",
      tab: "tab-1",
      popOverlay: false,
      fileName: "",
      fileNameLocal: "",
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: false,
          value: "name",
        },
      ],
    };
  },
};
</script>
<style scoped>
.table {
  background-color: rgb(53, 53, 53);
}
.jsonFP {
  padding: 10px;
  background-color: rgb(53, 53, 53);
  border-radius: 10px;
  padding: 20px 20px;
  margin: 10px;
  color: white;
  height: auto;
  max-height: 40vh;
  overflow: auto;
}
</style>


