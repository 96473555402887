<template>
  <div>
    <h4>Relative Altitude:</h4>
    <v-slider
      v-model="altitude"
      class="align-center"
      :max="max"
      :min="min"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="altitude"
          suffix="m"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 60px"
          :rules="[rules.max, rules.min]"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script>
import { getMagnitudeBounds } from "../../utils/Commands";
export default {
  data() {
    return {
      min: 0,
      max: 100,
      altitude: 0,
      rules: {
        max: (value) => value <= this.max || "Too large",
        min: (value) => value >= this.min || "Too small",
      },
    };
  },

  created: function () {
    this.min = getMagnitudeBounds("changealtitude").altitude.min;
    this.max = getMagnitudeBounds("changealtitude").altitude.max;
    this.altitude = getMagnitudeBounds("changealtitude").altitude.default;
  },

  methods: {
    emitParameters() {
      this.$emit("command-parameters", { altitude: this.altitude });
    },
  },
};
</script>