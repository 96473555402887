import { AreaPoint, FlightPlan } from "@/utils/DataModels";
import { DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { sendWebSocketMessage } from "@/websocket/websocket";
import { Point, Polygon } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { vuexStore } from "../../main";
import { FlightPlanProtocolTypes } from "../../utils/FlightPlanProtocol";
import { AppState } from "../../utils/StateUtils";
import {
  SocketMessageTypes,
  WebSocketMessage
} from "../../utils/WebsocketProtocol";
import { areaLabels, areaLayer, spatialReference, view } from "../EsriMap";
import { resizeAreaPolygon } from "./AreaUtils";



let sketchViewModel: SketchViewModel;
let selectedDroneId: string;
let previouseAreaPoints: AreaPoint[] = [];

const areaSymbol: any = {
  type: "simple-fill",
  color: [255, 152, 0, 0.25],
  outline: {
    color: [239, 108, 0],
    width: 2,
  },
};

export function initializeAreaCreator() {
  sketchViewModel = new SketchViewModel({
    view: view,
    layer: areaLayer,
    polygonSymbol: areaSymbol,
  });
  sketchViewModel.on("create", function (event) {
    listenForCompletion(event);
  });
  sketchViewModel.on("update", function (event) {
    if (event.state === "start") {
      vuexStore.dispatch("state/changeAppstate", AppState.AREA_CREATION);
    }
    listenForCompletion(event);
  });

  sketchViewModel.on("delete", function () {
    areaLabels.removeAll();
    vuexStore.dispatch("state/changeAppstate", AppState.DEFAULT);

    selectedDroneId = vuexStore.getters["addedDroneObject/getSelectedDroneId"];
    const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
    FP.area = [];
    sendWebSocketMessage(
      new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
        systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
        type: FlightPlanProtocolTypes.UPDATED_FP,
        flightPlan: FP,
      })
    );
  });
}

export function createArea() {
  if (areaLayer.graphics.length === 0) {
    sketchViewModel.create("polygon");
  } else {
    sketchViewModel.redo();
  }
}

function listenForCompletion(event: any) {
  if (event.state === "complete") {
    if (areaLayer.graphics.length > 0) {

      const polygon: any = areaLayer.graphics.getItemAt(0).geometry;
      generateAreaFromPolygon(polygon);

    }
  } else if (event.state === "cancel") {
    vuexStore.dispatch("state/setLastAppstate");
  }
}

export function saveArea() {
  sketchViewModel.complete();
  vuexStore.dispatch("state/changeAppstate", AppState.DEFAULT);
}
export function cancelArea() {
  sketchViewModel.cancel();
  vuexStore.dispatch("state/changeAppstate", AppState.DEFAULT);
}
export function deleteArea() {
  sketchViewModel.delete();
  removePlotedArea();
}

export function generateAreaFromPolygon(polygon: Polygon) {
  const areaPoints: AreaPoint[] = [];
  for (let i = 0; i < polygon.rings[0].length; i++) {
    const point = polygon.getPoint(0, i);
    areaPoints.push(new AreaPoint(Number(point.latitude.toFixed(9)), Number(point.longitude.toFixed(9))));
  }

  generateAreaFromPoints(areaPoints);
}

export function generateAreaFromPoints(areaPoints: AreaPoint[]) {
  removePlotedArea();

  selectedDroneId = vuexStore.getters["addedDroneObject/getSelectedDroneId"];

  let FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP) {
    FP = new FlightPlan();
  }

  previouseAreaPoints = areaPoints;
  FP.area = areaPoints;
  sendWebSocketMessage(
    new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
      systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
      type: FlightPlanProtocolTypes.UPDATED_FP,
      flightPlan: FP,
    })
  );
}
export function plotArea(FP: FlightPlan) {
  const area = FP.area;
  if (area && area.length > 0) {
    removePlotedArea();

    const polygon = new Polygon({
      spatialReference: spatialReference,
    });

    const points: Point[] = [];

    area.forEach((point: AreaPoint) => {
      points.push(
        new Point({
          spatialReference: spatialReference,
          latitude: point.lat,
          longitude: point.lon,
        })
      );
    });

    polygon.addRing(points);
    const graphic = new Graphic({
      geometry: polygon,
      symbol: areaSymbol,
    });
    addGraphicToAreaLayer(graphic);
  }
}

export function removePlotedArea() {
  areaLayer.removeAll();
  areaLabels.removeAll();
}

function addGraphicToAreaLayer(graphic: Graphic) {

  areaLayer.add(graphic);

  areaLabels.removeAll();
  const polygon: any = graphic.geometry;
  for (let i = 0; i < polygon.rings[0].length - 1; i++) {
    const point = polygon.getPoint(0, i);
    const txtSym = {
      type: "text",  // autocasts as new TextSymbol()
      color: [255, 152, 0, 1],
      haloColor: "black",
      haloSize: "1px",
      text: i + 1,
      font: {  // autocasts as new Font()
        size: 13,
        family: "Josefin Slab",
        weight: "bold"
      }
    };
    areaLabels.add(new Graphic({
      geometry: point,
      symbol: txtSym,
    }));
  }
}
