<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title class="text-h5">Change email</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="Email"
                required
              ></v-text-field>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="changingEmail"
          @click="close"
        >Cancel</v-btn>
        <v-btn
          color="blue darken-1"
          text
          :disabled="!valid || changingEmail"
          @click="confirm"
        >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "ChangePassword",
  data() {
    return {
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      dialog: false,
      changingEmail: false,
      valid: false,
      email: '',
    }
  },
  props: {
    user: {},
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.email = this.user.email;
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.changingEmail = false;
      this.email = '';
    },
    confirm() {
      if (this.$refs.form.validate()) {
        this.changingEmail = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.post(
          loc + "/auth/user/" + this.user.id,
          {
            email: this.email,
          }
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't change email");
            this.close();
            return;
          }
  
          this.$emit('email-changed');
          this.close();
        }).catch((error) => {
          console.log(error);
          this.close();
          return;
        });
      }
    },
  }
}
</script>

<style>

</style>