<template>
  <div class="wrapper" :data-open="state === 'open' ? 1 : 0">
    <div class="bg" @click="() => setState('half')"></div>
    <div
      ref="card"
      class="card"
      :data-state="isMove ? 'move' : state"
      :style="{ top: `${isMove ? y : calcY()}px` }"
    >
      <div class="pan-area" ref="pan">
        <div class="bar" ref="bar" :style="{ backgroundColor: barColor }"></div>
      </div>
      <div class="contents">
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import Hammer from "hammerjs";
import { mapGetters } from "vuex";
export default {
  props: {
    openY: {
      type: Number,
      default: 0.1,
    },
    halfY: {
      type: Number,
      default: 0.95,
    },
    defaultState: {
      type: String,
      default: "close",
    },
    barColor: {
      // Added a bar color props
      type: String,
      default: "rgba(255, 255, 255, .5)",
    },
  },
  data() {
    return {
      mc: null,
      y: 0,
      startY: 0,
      isMove: false,
      state: this.defaultState,
      rect: {},
    };
  },
  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
    appState: "state/getAppState",
  }),
  mounted() {
    window.onresize = () => {
      this.rect = this.$refs.card.getBoundingClientRect();
    };
    this.rect = this.$refs.card.getBoundingClientRect();
    this.mc = new Hammer(this.$refs.pan);
    this.mc.get("pan").set({ direction: Hammer.DIRECTION_ALL });
    this.mc.on("panup pandown", (evt) => {
      this.y = evt.center.y - 16;
    });
    this.mc.on("panstart", (evt) => {
      this.startY = evt.center.y;
      this.isMove = true;
    });
    this.mc.on("panend", (evt) => {
      this.isMove = false;
      switch (this.state) {
        case "close": //Added a close state on the condition to be able to swipe from closed to half/closed state.
        case "half":
          if (this.state == "close") {
            if (this.startY - evt.center.y > 120) {
              this.state = "half";
            }
            if (this.startY - evt.center.y > 320) {
              this.state = "open";
            }
            break;
          }
          if (this.startY - evt.center.y > 120) {
            this.state = "open";
          }
          if (this.startY - evt.center.y < -50) {
            this.state = "close";
          }
          break;
        case "open":
          if (this.startY - evt.center.y < -120) {
            this.state = "half";
          }
          break;
      }
    });
  },
  beforeDestroy() {
    this.mc.destroy();
    window.onresize = null;
  },
  methods: {
    calcY() {
      switch (this.state) {
        case "close":
          return this.rect.height;
        case "open":
          return this.rect.height * this.openY;
        case "half":
          return this.rect.height * this.halfY;
        default:
          return this.y;
      }
    },
    setState(state) {
      this.state = state;
    },
  },
  openCommandConfirmationDialog(command) {
    console.log(command);
  },
};
</script>

<style lang="scss" scoped>
.wrapper[data-open="1"] {
  position: fixed;
  top: 0;
  left: 0;
}
.wrapper[data-open="1"] .bg {
  display: block;
  transition: all 0.3s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.card {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #1e1e1e;
  border-radius: 10px 10px 0 0;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.1);
  left: 0;
}
.card[data-state="half"],
.card[data-state="open"],
.card[data-state="close"] {
  transition: top 0.3s ease-out;
}
.card[data-state="close"] {
  box-shadow: none;
}
.bar {
  width: 45px;
  height: 8px;
  border-radius: 14px;
  margin: 0 auto;
  cursor: pointer;
}
.pan-area {
  top: 0;
  right: 0;
  left: 0;
  position: relative;
  padding: 12px 0;
  .bar {
    &:hover {
      cursor: grab;
    }
    &:active {
      cursor: grabbing;
    }
  }
}
// Moved the pan area above the card content to be ale to swipe from closed state to half/open
.card[data-state="close"] .pan-area {
  top: -28px;
}
.contents {
  position: absolute;
  display: flex;
  //flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: stretch;
  // align-content: stretch;

  align-content: flex-start;
  gap: 8px;
  // padding-top: 28px;
  overflow-y: scroll;

  height: 100vh;
  width: 100%;

  padding-bottom: calc(100vh * 0.2);
  box-sizing: border-box;
}
</style>
