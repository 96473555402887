import Graphic from "@arcgis/core/Graphic";
import { Point, Polyline } from "@arcgis/core/geometry";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import PointSymbol3D from "@arcgis/core/symbols/PointSymbol3D";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import ObjectSymbol3DLayer from "@arcgis/core/symbols/ObjectSymbol3DLayer";
import Circle from "@arcgis/core/geometry/Circle";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";

import * as watchUtils from "@arcgis/core/core/watchUtils";

import { droneLayer, droneModelLayer, dronePathsLayer, rayLayer, spatialReference, view } from "./EsriMap";
import { distanceToCamera } from "./CameraDroneManager";
import { vuexStore } from "../main";
import { Drone, GoToWP, Home, Telemetry } from "@/utils/DroneDataModel";
import { isProductionelectron } from "@/utils/Electron";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import { removeRadPlot, SENSOR_HEIGHT, updateRadPlot } from "./RadiationManager";
import LatLon, { Ned } from 'geodesy/latlon-nvector-ellipsoidal.js'; // Node.js


// Nvecot
const drone3DModelSize = 1;
const drone3Danchor = -0.2;
const drone3DHeadingOffset = 180;

const distanceTo3DChange = 400; //distance that graphics will toggle from 2D to 3D in km

const pathDistanceResolution: number = 1; //distance between points for drone path to update

const radDistanceResolution: number = 0.10; //distance between points for drone rad to update


const pathSymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [0, 152, 255],
  width: 3,
});
const raySymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [200, 100, 10],
  width: 3,
});

const unSelectedDroneSymbol = new PictureMarkerSymbol({
  url: (isProductionelectron() ? "app://" : "") + "./assets/drone.png",
  width: "64px",
  height: "64px",
});

const selectedDroneSymbol = new PictureMarkerSymbol({
  url: (isProductionelectron() ? "app://" : "") + "./assets/selectedDrone.png",
  width: "64px",
  height: "64px",
});

const droneSymbol3DResource = {
  // href: (isProductionelectron() ? "app://" : "") + "./assets/drone3D.gltf",
  href: (isProductionelectron() ? "app://" : "") + "./assets/drone3DNew.glb",

};

const carSymbol3DResource = {
  href: (isProductionelectron() ? "app://" : "") + "./assets/car.gltf",
};

const gotoSymbol = new SimpleMarkerSymbol({
  style: "circle",
  size: 20,
  color: [255, 255, 0],
  outline: {
    color: [50, 50, 50],
    width: 5,
  },
});

const graphicsMap: Map<string, Graphic> = new Map(); //Object that contains all the graphics of drones. Mapped with systemId
const pathsMap: Map<string, Point[]> = new Map(); //Object that contains all the paths of the drones
const radiationMap: Map<string, any[]> = new Map(); //Object that contains all the radiation detected by all drones


const gotoMarker = new Graphic({
  symbol: gotoSymbol,
});

const homeGraphic = new Graphic({
  symbol: new SimpleFillSymbol({
    style: "diagonal-cross",
    color: "black",
    outline: {
      width: 3,
      color: "#1ec1d5",
    },
  }),
});




//creates a new Graphic for a drone and adds it to the map
export function createDrone(drone: Drone) {

  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: drone.telemetry.latitude,
    longitude: drone.telemetry.longitude,
    hasZ: true,
    // z: drone.telemetry.absoluteAltitude,
    z: drone.telemetry.relativeAltitude,

  });

  const newGraphic = new Graphic({
    geometry: newPoint,
    symbol: unSelectedDroneSymbol,
    attributes: {
      droneId: drone.systemId,
      car: false,
    },
  });
  graphicsMap.set(drone.systemId, newGraphic);

  pathsMap.set(drone.systemId, []);
  pathsMap.get(drone.systemId)?.push(newPoint);

  radiationMap.set(drone.systemId, []);


  addDrone(drone);
}


//Deletes the drone from the map and the layer
export function deleteDrone(droneId: string) {
  removeDrone(droneId);
  graphicsMap.delete(droneId);
  pathsMap.delete(droneId);
  radiationMap.delete(droneId);


}

//adds the drone to the layer
export function addDrone(drone: Drone) {
  if (graphicsMap.has(drone.systemId)) {
    droneModelLayer.add(graphicsMap.get(drone.systemId)!);
  }
  else createDrone(drone);
}

//removes the drone from the layer
export function removeDrone(droneId: string) {
  if (graphicsMap.has(droneId)) {
    droneModelLayer.remove(graphicsMap.get(droneId)!);
  } else {
    throw `${droneId} not in graphicsMap`;
  }
}


//changes a graphics symbol to the selected drone symbol
export function selectDrone(drone: Drone) {
  initializePathGraphic(drone.systemId);
  if (
    distanceToCamera(graphicsMap.get(drone.systemId)!.geometry as Point) <
    distanceTo3DChange
  ) {
    makeDrone3D(drone);
  } else {
    makeDrone2D(drone);
  }
}

//chnges a graphics symbol to the non selected drone symbol
export function unSelectDrone(droneId: string) {
  graphicsMap.get(droneId)!.symbol = unSelectedDroneSymbol;
  removePathGraphic();
  removeRadPlot();
}

function makeDrone3D(drone: Drone) {
  const graphic = graphicsMap.get(drone.systemId)!;
  graphic.symbol = new PointSymbol3D({
    symbolLayers: [
      new ObjectSymbol3DLayer({
        height: graphic.getAttribute("car") ? 2 : drone3DModelSize,
        //anchor: graphic.getAttribute("car") ? "bottom" : "center",
        anchor: "relative",
        anchorPosition: graphic.getAttribute("car") ? { x: 0, y: 0, z: -0.5 } : { x: 0, y: 0, z: drone3Danchor },
        resource: graphic.getAttribute("car")
          ? carSymbol3DResource
          : droneSymbol3DResource,
        heading: drone.telemetry.track + (graphic.getAttribute("car") ? 0 : drone3DHeadingOffset),
        tilt: drone.telemetry.pitch,
        roll: drone.telemetry.roll,
      }),
    ],
  });
}

function makeDrone2D(drone: Drone) {
  graphicsMap.get(drone.systemId)!.symbol = selectedDroneSymbol;
}


export function convertToCar(droneId: string) {
  const drone: Drone = vuexStore.getters[
    "addedDroneObject/getAddedDroneObject"
  ].get(droneId);
  const selectedDroneId =
    vuexStore.getters["addedDroneObject/getSelectedDroneId"];

  const graphic = graphicsMap.get(droneId)!;
  graphic.setAttribute("car", true);

  if (
    droneId === selectedDroneId &&
    distanceToCamera(graphicsMap.get(selectedDroneId)!.geometry as Point) <
    distanceTo3DChange
  ) {
    graphic.symbol = new PointSymbol3D({
      symbolLayers: [
        new ObjectSymbol3DLayer({
          height: 2,
          anchor: "bottom",
          resource: carSymbol3DResource,
          heading: drone.telemetry.track,
          tilt: drone.telemetry.pitch,
          roll: drone.telemetry.roll,
        }),
      ],
    });
  }

  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: drone.telemetry.latitude,
    longitude: drone.telemetry.longitude,
    hasZ: false,
  });

  graphic.geometry = newPoint;
}

export function convertToDrone(droneId: string) {
  const drone: Drone = vuexStore.getters[
    "addedDroneObject/getAddedDroneObject"
  ].get(droneId);
  const selectedDroneId =
    vuexStore.getters["addedDroneObject/getSelectedDroneId"];

  const graphic = graphicsMap.get(droneId)!;
  graphic.setAttribute("car", false);
  if (
    droneId === selectedDroneId &&
    distanceToCamera(graphicsMap.get(selectedDroneId)!.geometry as Point) <
    distanceTo3DChange
  ) {
    graphic.symbol = new PointSymbol3D({
      symbolLayers: [
        new ObjectSymbol3DLayer({
          height: drone3DModelSize,
          resource: droneSymbol3DResource,
          heading: drone.telemetry.track + drone3DHeadingOffset,
          tilt: drone.telemetry.pitch,
          roll: drone.telemetry.roll,
        }),
      ],
    });
  }

  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: drone.telemetry.latitude,
    longitude: drone.telemetry.longitude,
    hasZ: true,
    //z: drone.telemetry.absoluteAltitude,
    z: drone.telemetry.relativeAltitude,

  });
  graphic.geometry = newPoint;
}

export function newRadPoint(drone: Drone) {
  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: drone.telemetry.latitude,
    longitude: drone.telemetry.longitude,
    hasZ: false,
  });

  updateRad(newPoint, drone.telemetry, drone, drone.systemId);
}

//update the position of all the drones
export function updatePostitions(addedDroneObject: Map<string, Drone>) {
  addedDroneObject.forEach((drone: Drone, key: string) => {

    if (graphicsMap.has(key) && pathsMap.has(key)) {
      const graphic = graphicsMap.get(key)!;

      const newPoint = new Point({
        spatialReference: spatialReference,
        latitude: drone.telemetry.latitude,
        longitude: drone.telemetry.longitude,
        hasZ: !graphic.getAttribute("car"),
      });

      if (!graphic.getAttribute("car")) {
        //newPoint.z = drone.telemetry.absoluteAltitude;
        newPoint.z = drone.telemetry.relativeAltitude;
      }

      graphic.geometry = newPoint;

      updatePath(newPoint, drone, key, graphic);
      // Leave it to newRadPoint
      // That way we don't lose any reading
      // updateRad(newPoint, drone.telemetry, drone, key);

      if (graphic.symbol.type === "point-3d") {
        graphic.symbol = new PointSymbol3D({
          symbolLayers: [
            new ObjectSymbol3DLayer({
              height: graphic.getAttribute("car") ? 2 : drone3DModelSize,
              // anchor: graphic.getAttribute("car") ? "bottom" : "center",
              anchor: "relative",
              anchorPosition: graphic.getAttribute("car") ? { x: 0, y: 0, z: -0.5 } : { x: 0, y: 0, z: drone3Danchor },
              resource: graphic.getAttribute("car")
                ? carSymbol3DResource
                : droneSymbol3DResource,
              heading: drone.telemetry.track + (graphic.getAttribute("car") ? 0 : drone3DHeadingOffset),
              tilt: drone.telemetry.pitch,
              roll: drone.telemetry.roll,
            }),
          ],
        });
      }
    }
  });
}


export function getDroneGraphic(droneId: string) {
  return graphicsMap.get(droneId);
}

export function setViewWatcher() {
  watchUtils.whenTrue(view, "stationary", function () {
    const selectedDrone: Drone | null =
      vuexStore.getters["addedDroneObject/getSelectedDrone"];


    if (selectedDrone != null) {
      if (
        distanceToCamera(
          graphicsMap.get(selectedDrone.systemId)!.geometry as Point
        ) < distanceTo3DChange
      ) {
        makeDrone3D(selectedDrone);
      } else {
        makeDrone2D(selectedDrone);
      }
    }
  });
}

//GOTO FUNCTONS
function addGoToGraphic() {
  droneLayer.add(gotoMarker);
}

export function removeGoToGraphic() {
  droneLayer.remove(gotoMarker);
}

export function updateGoToGraphic(goToWP: GoToWP) {
  const geometry = new Point({
    spatialReference: spatialReference,
    latitude: goToWP.latitude!,
    longitude: goToWP.longitude!,
    hasZ: true,
    z: goToWP.altitude!,
  });
  gotoMarker.geometry = geometry;
  if (!gotoMarker.layer) addGoToGraphic();
}

//HOME FUNCTIONS

function addHomeGraphic() {
  droneLayer.add(homeGraphic);
}

export function removeHomeGraphic() {
  droneLayer.remove(homeGraphic);
}

export function updateHomeGraphic(home: Home) {
  const homeCenter = new Point({
    spatialReference: spatialReference,
    latitude: home.latitude,
    longitude: home.longitude,
  });

  homeGraphic.geometry = new Circle({
    center: homeCenter,
    geodesic: true,
    numberOfPoints: 100,
    radius: 10,
    radiusUnit: "meters",
  });

  console.log(homeGraphic.layer);
  if (!homeGraphic.layer) addHomeGraphic();
}


//RAD FUNCTIONS

function updateRad(dronePoint: Point, telemetry: Telemetry, drone: Drone, key: string) {

  //-1 means no radiation being read from backend (not enabled)
  if (telemetry.counts != -1) {
    const i = radiationMap.get(key)?.length;

    const coneSlope = Math.tan(0.5 * (vuexStore.getters["addedDroneObject/getSensorFOV"] * Math.PI) / 180.0);
    const dRadius = coneSlope * (drone.telemetry.relativeAltitude - SENSOR_HEIGHT);

    const heading: number = drone.telemetry.yaw;
    const yLocal = Math.tan(drone.telemetry.pitch) * drone.telemetry.relativeAltitude;
    const xLocal = -Math.tan(drone.telemetry.roll) * drone.telemetry.relativeAltitude;
    const yAbs = Math.cos(heading) * yLocal + Math.sin(heading) * xLocal
    const xAbs = Math.sin(heading) * yLocal + Math.cos(heading) * xLocal

    const SMR = new LatLon(drone.telemetry.latitude, drone.telemetry.longitude, 0);
    const ned = new Ned(yAbs, xAbs, 0);
    const pointLOS = SMR.destinationPoint(ned);
    const pointLOSArcgis = new Point({
      spatialReference: spatialReference,
      latitude: pointLOS.latitude,
      longitude: pointLOS.longitude,
      hasZ: true,
      z: 0,
    });

    const ray = new Polyline({
      spatialReference: spatialReference,
      hasZ: true,
    });
    ray.addPath([
      dronePoint,
      pointLOSArcgis,
    ]);
    const rayGraphic = new Graphic({
      geometry: ray,
      symbol: raySymbol,
    });
    rayLayer.removeAll();
    rayLayer.add(rayGraphic);

    const newGraphic = {
      geometry: {
        type: "point",
        spatialReference: spatialReference,
        hasZ: false,
        latitude: pointLOS.latitude,
        longitude: pointLOS.longitude,

      }, attributes: {
        radiation: drone.telemetry.counts,
        radiationCPS: drone.telemetry.countsPerSecond,
        radiationCPSN: drone.telemetry.normalizedCountsPerSecond,
        relativeWidth: dRadius,
        ObjectID: i,
      }
    };
    const newPoint = new Point({
      spatialReference: spatialReference,
      latitude: drone.telemetry.latitude,
      longitude: drone.telemetry.longitude,
      hasZ: false,
    });

    const selectedDroneId = vuexStore.getters["addedDroneObject/getSelectedDroneId"];
    // TODO: Bypass enoughDistanceChangeRad
    /* eslint-disable no-constant-condition */
    if (true || enoughDistanceChangeRad(key, newPoint)) {
      radiationMap.get(key)?.push(newGraphic);
      if (key == selectedDroneId) {
        updateRadPlot(newGraphic);
      }
    }

  }

}


//PATH FUNCTIONS

function initializePathGraphic(droneId: string) {
  removePathGraphic();


  const newPolyline = new Polyline({
    hasZ: true,
    spatialReference: spatialReference,
  });

  newPolyline.addPath(pathsMap.get(droneId)!);

  const newPathGraphic = new Graphic({
    geometry: newPolyline,
    symbol: pathSymbol,
  });
  dronePathsLayer.add(newPathGraphic);

}


function updatePath(newPoint: Point, drone: Drone, key: string, graphic: Graphic) {
  const selectedDroneId = vuexStore.getters["addedDroneObject/getSelectedDroneId"];
  //check store to see if enabled

  if (enoughDistanceChange(key, newPoint)) {
    //always want altitude data
    //newPoint.z = drone.telemetry.absoluteAltitude;
    newPoint.z = drone.telemetry.relativeAltitude;

    pathsMap.get(key)?.push(newPoint);
    if (key == selectedDroneId) {
      //if enabled thepath is drawn
      plotPath(key, !graphic.getAttribute("car"));
    }
  }

}



function plotPath(droneId: string, hasZCar: boolean) {

  dronePathsLayer.graphics.forEach((pathGraphic: Graphic) => {
    if (pathGraphic.geometry.type == "polyline") {
      const newPolyline = new Polyline({
        hasZ: hasZCar,
        spatialReference: spatialReference,
      });
      newPolyline.addPath(pathsMap.get(droneId)!);
      pathGraphic.geometry = newPolyline;
    }
  });
}

function removePathGraphic() {
  dronePathsLayer.graphics.forEach((pathGraphic: Graphic) => {
    if (pathGraphic.geometry.type == "polyline") {
      dronePathsLayer.remove(pathGraphic);
    }
  });
}

export function resetPathGraphic(drone: Drone) {
  pathsMap.set(drone.systemId, []);
  initializePathGraphic(drone.systemId);
}

//returns true if drone moved more than distance resolution
function enoughDistanceChange(droneId: string, newPoint: Point) {
  const numPoints = pathsMap.get(droneId)!.length;
  if (numPoints > 0) {
    const distance = newPoint.distance(pathsMap.get(droneId)![numPoints - 1]);
    return distance > pathDistanceResolution;
  }
  return true;

}

//returns true if drone moved more than distance resolution
function enoughDistanceChangeRad(droneId: string, newPoint: Point) {
  const numPoints = radiationMap.get(droneId)!.length;
  if (numPoints > 0) {

    const geomOld = radiationMap.get(droneId)![numPoints - 1].geometry;
    const oldPoint = new Point({
      spatialReference: spatialReference,
      latitude: geomOld.latitude,
      longitude: geomOld.longitude,
      hasZ: false,
    });
    const distance = newPoint.distance(oldPoint);
    return distance > radDistanceResolution;
  }
  return true;

}

