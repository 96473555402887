<template>
  <v-card width="300" height="fill-height" rounded="lg">
    <v-container pt-1>
      <v-card-title class="h3 pl-1 py-0">Commands</v-card-title>

      <v-row dense>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('takeoff')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/takeoffWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Takeoff</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('land')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/landWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Land</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('rth')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/rthWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Return to Home</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('loiter')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/loiterWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Loiter</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('turnyaw')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/turnyawWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Turn yaw</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('gimbalturn')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/gimbalturnWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Gimbal Turn</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('changespeed')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/changespeedWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Change Speed</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('changealtitude')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/changealtitudeWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Change Altitude</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('updatespeed')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/updateSpeedWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Update Mission Speed</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('upload')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/uploadWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Upload Flightplan</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('stop')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/stopWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Stop Mission</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('start')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/startWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Start Mission</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('pause')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/pauseWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Pause Mission</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('resume')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/resumeWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Resume Mission</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('sethome')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/sethomeWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Set Home Position</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('goto')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/gotoWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Go To Position</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align="end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="reveal = !reveal"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon
                  v-if="!reveal"
                  medium
                  class="mdi mdi-menu-down"
                ></v-icon>
                <v-icon v-if="reveal" medium class="mdi mdi-menu-up"></v-icon>
              </v-btn>
            </template>
            <span>More</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-row dense v-if="reveal">
        <v-col align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('radpoint')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/radpointWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Radiation Point</span>
          </v-tooltip>
        </v-col>
        <v-col align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="openCommandWindow('radstop')"
                v-bind="attrs"
                v-on="on"
              >
                <v-img
                  src="../assets/commandIcons/stopWhite.png"
                  :aspect-ratio="1 / 1"
                >
                </v-img>
              </v-btn>
            </template>
            <span>Stop Radiation</span>
          </v-tooltip>
        </v-col>
        <v-col align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="startRecording"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">mdi-movie-open-play-outline</v-icon>
              </v-btn>
            </template>
            <span>Start Recording Mission</span>
          </v-tooltip>
        </v-col>
        <v-col align="center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-small
                icon
                @click="stopRecording"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="25">mdi-movie-open-off-outline</v-icon>
              </v-btn>
            </template>
            <span>Stop Recording Mission</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { sendStartRecording, sendStopRecording } from '@/mission_player/protocol';
import { vuexStore } from '@/main';
export default {
  data: () => ({
    reveal: false,
  }),
  methods: {
    openCommandWindow(command) {
      this.$emit("open-command-window", command);
    },
    startRecording() {
      // TODO: Send missionId on start
      // Save mission with no data to prevent others to use the same systemId
      const date = new Date();
      const defaultName = date.getFullYear() + "-" + 
        (date.getMonth() + 1).toString().padStart(2, '0') + "-" +
        date.getDate().toString().padStart(2, '0') + "-" +
        date.getHours().toString().padStart(2, '0') + ":" +
        date.getMinutes().toString().padStart(2, '0') + ":" +
        date.getSeconds().toString().padStart(2, '0');

      const selectedDrone = vuexStore.getters["addedDroneObject/getSelectedDrone"];
      
      sendStartRecording(defaultName, selectedDrone.systemId);
      vuexStore.dispatch("addedDroneObject/startRecording", date);
      this.$emit("start-recording");
    },
    stopRecording() {
      sendStopRecording(vuexStore.getters["addedDroneObject/getSelectedDrone"].systemId);
      vuexStore.dispatch("addedDroneObject/stopRecording");
      this.$emit("stop-recording");
    }
  },
};
</script>
