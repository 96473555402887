import { AppState } from "../utils/StateUtils";
import { vuexStore } from "../main";
import { Alert } from "@/utils/DataModels";
import { FlightPlan } from "@/utils/DataModels";
import { sendWebSocketMessage } from "@/websocket/websocket";
import {
  SocketMessageTypes,
  WebSocketMessage,
} from "../utils/WebsocketProtocol";
import { DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { FlightPlanProtocolTypes } from "@/utils/FlightPlanProtocol";

export function getListFiles() {
  const selectedDroneId =
    vuexStore.getters["addedDroneObject/getSelectedDroneId"];
  sendWebSocketMessage(
    new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
      systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
      type: FlightPlanProtocolTypes.LIST_FP,
    })
  );
}
export function checkFPExists(alert: boolean = false) {
  const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP || FP.waypoints.length == 0) {
    if (alert) {
      vuexStore.dispatch(
        "alert/popAlert",
        new Alert(-1, "warning", "No FP to Download or View")
      );
    }
    return false;
  } else {
    return true;
  }
}


export function sendFileBackend(fileName: string) {
  const selectedDroneId =
    vuexStore.getters["addedDroneObject/getSelectedDroneId"];
  const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (FP) {
    sendWebSocketMessage(
      new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
        systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
        type: FlightPlanProtocolTypes.DOWNLOAD_FP,
        flightPlan: FP,
        fileName: fileName,
      })
    );
  }
}
export function importFileBackend(fileName: string) {
  const selectedDroneId =
    vuexStore.getters["addedDroneObject/getSelectedDroneId"];
  sendWebSocketMessage(
    new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
      systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
      type: FlightPlanProtocolTypes.IMPORT_FP,
      fileName: fileName,
    })
  );
}



