<template>
<div class="center">
  <v-form
    ref="form"
    id="form"
    v-model="valid"
    style="width: 500px;"
    lazy-validation
  >
    <v-text-field
      v-model="email"
      :rules="emailRules"
      label="Email"
      required
    ></v-text-field>
    <v-text-field
      type="password"
      v-model="password"
      :rules="passwordRules"
      label="Password"
      required
    ></v-text-field>
    <v-alert
      v-for="(error, index) in loginErrors"
      :key="index"
      :type="error.type"
      dense
    >
      {{ error.message }}
    </v-alert>

    <v-btn
      :disabled="!valid || loggingIn"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Log In
    </v-btn>
  </v-form>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: "Login",
  async mounted() {
    await this.recover();
  },
  data() {
    return {
      valid: true,
      email: "",
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || 'Email must be valid',
      ],
      password: "",
      passwordRules: [
        v => !!v || "Password is required.",
        v => (v && v.length <= 72) || "Maximum password length is 72 bytes."
      ],
      loggingIn: false,
    }
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      recover: "auth/recover",
    }),
    async validate() {
      this.loggingIn = true;
      if (this.$refs.form.validate()) {
        await this.login({ email: this.email, password: this.password });
        this.loggingIn = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      loginErrors: "auth/getLoginErrors",
    }),
  }
}
</script>

<style>
.center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>