var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":"300","height":"fill-height","rounded":"lg"}},[_c('v-container',{attrs:{"pt-1":""}},[_c('v-card-title',{staticClass:"h3 pl-1 py-0"},[_vm._v("Commands")]),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('takeoff')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/takeoffWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Takeoff")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('land')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/landWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Land")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('rth')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/rthWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Return to Home")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('loiter')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/loiterWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Loiter")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('turnyaw')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/turnyawWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Turn yaw")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('gimbalturn')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/gimbalturnWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Gimbal Turn")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('changespeed')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/changespeedWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Change Speed")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('changealtitude')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/changealtitudeWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Change Altitude")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('updatespeed')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/updateSpeedWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Update Mission Speed")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('upload')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/uploadWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Upload Flightplan")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('stop')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/stopWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Stop Mission")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('start')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/startWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Start Mission")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('pause')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/pauseWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Pause Mission")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('resume')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/resumeWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Resume Mission")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('sethome')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/sethomeWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Set Home Position")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('goto')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/gotoWhite.png"),"aspect-ratio":1 / 1}})],1)]}}])},[_c('span',[_vm._v("Go To Position")])])],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"align":"end"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){_vm.reveal = !_vm.reveal}}},'v-btn',attrs,false),on),[(!_vm.reveal)?_c('v-icon',{staticClass:"mdi mdi-menu-down",attrs:{"medium":""}}):_vm._e(),(_vm.reveal)?_c('v-icon',{staticClass:"mdi mdi-menu-up",attrs:{"medium":""}}):_vm._e()],1)]}}])},[_c('span',[_vm._v("More")])])],1)],1),(_vm.reveal)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('radpoint')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/radpointWhite.png"),"aspect-ratio":1 / 1}})],1)]}}],null,false,1006506470)},[_c('span',[_vm._v("Radiation Point")])])],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":function($event){return _vm.openCommandWindow('radstop')}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"src":require("../assets/commandIcons/stopWhite.png"),"aspect-ratio":1 / 1}})],1)]}}],null,false,3902496305)},[_c('span',[_vm._v("Stop Radiation")])])],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":_vm.startRecording}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-movie-open-play-outline")])],1)]}}],null,false,1492921306)},[_c('span',[_vm._v("Start Recording Mission")])])],1),_c('v-col',{attrs:{"align":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","icon":""},on:{"click":_vm.stopRecording}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-movie-open-off-outline")])],1)]}}],null,false,935268233)},[_c('span',[_vm._v("Stop Recording Mission")])])],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }