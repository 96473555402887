<template>
  <v-dialog
          v-model="dialog"
          max-width="500px"
        >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        New User
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">New User</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-row>
              <v-text-field
                v-model="user.email"
                :rules="emailRules"
                label="Email"
                required
              ></v-text-field>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="password"
                  v-model="user.password"
                  :rules="passwordRules"
                  label="Password"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  type="password"
                  v-model="user.repeatPassword"
                  :rules="repeatPasswordRules"
                  label="Repeat Password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-checkbox
                v-for="role in roles"
                :key="role.name"
                v-model="user.roles"
                :label="role.name"
                :value="role.name"
              ></v-checkbox>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled="!valid || savingUser"
          color="blue darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "NewUser",
  data() {
    return {
      user: {
        email: '',
        password: '',
        repeatPassword: '',
        roles: []
      },
      defaultUser: {
        email: '',
        password: '',
        repeatPassword: '',
        roles: []
      },
      roles: [],
      emailRules: [
        v => !!v || "Email is required",
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || "Password is required.",
        v => (v && v.length <= 72) || "Maximum password length is 72 bytes."
      ],
      repeatPasswordRules: [
        v => !!v || "Repeat password is required.",
        v => (v && v.length <= 72) || "Maximum password length is 72 bytes.",
        v => (v === this.user.password) || "Passwords must be equal"
      ],
      dialog: false,
      valid: false,
      savingUser: false,
    }
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.getRoles();
      }
    }
  },
  mounted() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      const loc =
        process.env.NODE_ENV === "production"
          ? "https://rima2.ac.upc.edu:8443"
          : window.location.origin;
  
      axios.get(
        loc + "/auth/all_roles"
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't stablish connection");
          this.dialog = false;
          return;
        }
        this.roles = response.data.roles;
      }).catch((error) => {
        console.log(error);
        this.dialog = false;
        return;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.user = Object.assign({}, this.defaultUser);
      });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.savingUser = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.post(
          loc + "/auth/register",
          {
            email: this.user.email,
            password: this.user.password,
            roles: this.user.roles
          }
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't save new user");
            this.dialog = false;
            this.savingUser = false;
            return;
          }

          this.$emit('user-created');
          this.dialog = false;
          this.savingUser = false;
        }).catch((error) => {
          console.log(error);
          this.dialog = false;
          this.savingUser = false;
          return;
        });
      }
      
    }
  }
}
</script>

<style>

</style>