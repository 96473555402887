<template>
<div class="container">
  <v-menu
    offset-y
    open-on-hover
    transition="slide-y-transition"
    close-delay="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
      icon 
      v-bind="attrs" 
      v-on="on"
      :disabled="appState == 'FLIGHT_PLAN_CREATION' || appState == 'AREA_CREATION'"
      >
        <v-icon> mdi-radioactive </v-icon>
      </v-btn>
    </template>
    <div v-for="(item, index) in items" v-bind:key="index" class="my-1">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="item.method"
            color="default"
            fab
            small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>
  </v-menu>
  <RadiationSource class="bottom-right" v-if="this.newsource" v-on:placed="newsource=false"></RadiationSource>
</div>
</template>

<script>
import { radSourceLayer } from '@/esriAPI/EsriMap';
import { mapGetters, mapActions } from "vuex";
import RadiationSource from './RadiationSource.vue';
import { sendClearSources } from '@/mission_player/protocol';

export default {
    data() {
        return {
          items: [
            {
              name: "Place Source",
              method: this.placeSource,
              icon: "mdi-select-place",
            },
            {
              name: "Clear Sources",
              method: this.clearSources,
              icon: "mdi-delete",
            }
          ],
          message: "",
          newsource: false,
        };
    },
    computed: {
        ...mapGetters({
            appState: "state/getAppState",
        }),
    },
    methods: {
    placeSource() {
      this.newsource = true;
    },
    clearSources() {
      radSourceLayer.removeAll();
      sendClearSources();
    },
    ...mapActions({
        changeAppState: "state/changeAppstate",
        setLastAppstate: "state/setLastAppstate",
    }),
  },
  components: { RadiationSource }
};
</script>
<style scoped>
.fileInput{
  max-width: 280px;
}
.scaleArea{
  position: absolute;
  min-width: 300px;
  max-width: 300px;
  top:0px;
  left:333px;
  padding: 10px;
}
.container {
  position: relative;
}
.bottom-right {
  position: absolute;
  top: 30px;
  left: 100px;
  min-width: 300px;
}
</style>

