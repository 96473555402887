<template>
  <v-card rounded="lg" width="300">
    <v-container pa-0>
      <v-card-title class="h3 pb-0 pt-2">Added Missions </v-card-title>
      <v-card-text>
        <v-container pa-0>
          <v-row
            dense
          >
            <v-card outlined width="100%">
              <v-container p-0>
                <v-row class="flex-nowrap">
                  <v-col cols="8">
                    <v-card-text style="font-size: 1em">
                      {{ selectedMission.missionId }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="4">
                    <v-card-actions class="pt-1 mt-1">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            icon
                            @click.stop="focusDrone"
                          >
                        <v-icon medium class="mdi mdi-eye-outline"></v-icon
                      ></v-btn>
                        </template>
                        <span>Zoom to Drone</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            icon
                            @click.stop="unselectMission"
                          >
                        <v-icon medium class="mdi mdi-close-circle-outline"></v-icon
                      ></v-btn>
                        </template>
                        <span>Unselect Mission</span>
                      </v-tooltip>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import { focusDrone } from "../../mission_player/esri/path";

export default {
  computed: mapGetters({
    selectedMission: "missionPlayer/getSelectedMission",
  }),
  methods: {
    focusDrone() {
      focusDrone();
    },
    unselectMission() {
      this.$store.dispatch("missionPlayer/unselectMission");
    }
  }
};
</script>
