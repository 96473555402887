import { Alert, FlightPlan } from "@/utils/DataModels";
import { Polygon } from "@arcgis/core/geometry";
import Point from "@arcgis/core/geometry/Point";
import { re } from "mathjs";
import { vuexStore } from "../../main";
import { spatialReference } from "../EsriMap";



export function checkAreaExists(alert: boolean = false) {
  const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP || !FP.area || FP.area.length == 0) {
    if (alert) {
      vuexStore.dispatch(
        "alert/popAlert",
        new Alert(-1, "warning", "No Area Exists")
      );
    }
    return null
  } else {
    return FP.area;
  }
}

export function areaToCSV(area: any) {
  const fields = Object.keys(area[0])
  let csv = area.map(function (row: any) {
    return fields.map(function (fieldName) {
      return JSON.stringify(row[fieldName])
    }).join(', ')
  })
  csv.unshift(fields.join(', ')) // add header column
  csv = csv.join('\r\n');
  return csv;

}

export function csvToArea(csv: string) {
  const arr = csv.split('\n');
  arr.pop();
  const waypoints = [];
  const headers = arr[0].split(',');
  for (let i = 1; i < arr.length; i++) {
    const data = arr[i].split(',');
    const obj: any = {};
    for (let j = 0; j < data.length; j++) {
      obj[headers[j].trim()] = parseFloat(data[j].trim());
    }
    waypoints.push(obj);
  }
  waypoints.push(waypoints[0]);
  return waypoints;
}

export function resizeAreaPolygon(scale: number, polygon: Polygon) {
  if (!polygon) {
    return null
  }
  const newPolygon = new Polygon({
    spatialReference: spatialReference,
  });

  const reference = polygon.centroid;

  polygon.rings.forEach((ring) => {
    const newRing: any[] = [];
    ring.forEach((coordinate) => {
      const aPoint = new Point({
        spatialReference: spatialReference,
        x: coordinate[0],
        y: coordinate[1],
        z: coordinate[2],
      });

      if (!aPoint) {
        newRing.push(aPoint)
      }
      const bDistance = reference.distance(aPoint);
      const bPoint = new Point({
        spatialReference: spatialReference,
        x: reference.x + bDistance,
        y: reference.y,
        z: 0,
      });
      const aDistance = reference.distance(bPoint);
      const cDistance = aPoint.distance(bPoint);

      let angle = Math.acos((aDistance ** 2 + bDistance ** 2 - cDistance ** 2) / (2 * aDistance * bDistance));
      const scaledDistance = bDistance * scale;

      if (aPoint.y < reference.y) {
        angle = angle * -1
      }

      const scalex = scaledDistance * Math.cos(angle) + reference.x;
      const scaley = scaledDistance * Math.sin(angle) + reference.y;

      newRing.push(new Point({
        spatialReference: spatialReference,
        x: scalex,
        y: scaley,
        z: 0, //on the ground
      }));
    })
    newPolygon.addRing(newRing);
  }
  )
  return newPolygon;
  //All z coordinates must be on the ground
}