import { convertToCar, convertToDrone } from "@/esriAPI/ManageDroneLayer";
import { Alert } from "@/utils/DataModels";
import { DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import axios from "axios";
import { isElectron } from "@/utils/Electron";

import { Capacitor } from "@capacitor/core";
import { stat } from "original-fs";

interface DetectedDroneListState {
  detectedDroneList: DetectedDroneObject[];
  carModeEnabled: boolean;
  timerId: number;
}

interface DetectedDroneObject {
  addBtn: "ADD" | "REMOVE";
  added: boolean;
  ploted: boolean;
  car: boolean;
  loading: boolean;
  selectBtn: "SELECT" | "UNSELECT";
  selected: boolean;
  systemId: string;
}

const state: DetectedDroneListState = {
  detectedDroneList: [],
  carModeEnabled: false,
  timerId: 0,
};

const getters = {
  get: (state: DetectedDroneListState) => {
    return state.detectedDroneList.filter((item) => {
      return item.systemId !== DUMMY_DRONE_ID;
    });
  },
  getCarModeEnabled: (state: DetectedDroneListState) => {
    return state.carModeEnabled;
  },
  getTimerId: (state: DetectedDroneListState) => {
    return state.timerId;
  },
};

const mutations = {
  ADD_TO_MAP(state: DetectedDroneListState, drone: DetectedDroneObject) {
    const listDrone:
      | DetectedDroneObject
      | undefined = state.detectedDroneList.find(
        (item: DetectedDroneObject) => {
          return item.systemId === drone.systemId;
        }
      );

    if (listDrone) {
      listDrone.addBtn = "REMOVE";
      listDrone.loading = true;
    }
  },

  SET_PLOTED(state: DetectedDroneListState, droneId: string) {
    const obj = state.detectedDroneList.find((item) => {
      return item.systemId === droneId;
    })!;

    obj.ploted = true;
    obj.loading = false;
  },

  REMOVE_FROM_MAP(state: DetectedDroneListState, drone: DetectedDroneObject) {
    const listDrone:
      | DetectedDroneObject
      | undefined = state.detectedDroneList.find(
        (item: DetectedDroneObject) => {
          return item.systemId === drone.systemId;
        }
      );
    if (listDrone) {
      listDrone.addBtn = "ADD";
      listDrone.ploted = false;
    }
  },

  SELECT(state: DetectedDroneListState, drone: DetectedDroneObject) {
    state.detectedDroneList.forEach((item: DetectedDroneObject) => {
      if (item === drone) {
        item.selectBtn = "UNSELECT";
        item.selected = true;
      } else {
        item.selectBtn = "SELECT";
        item.selected = false;
      }
    });
  },

  UNSELECT(state: DetectedDroneListState, drone: DetectedDroneObject) {
    const listDrone:
      | DetectedDroneObject
      | undefined = state.detectedDroneList.find(
        (item: DetectedDroneObject) => {
          return item.systemId === drone.systemId;
        }
      );
    if (listDrone) {
      listDrone.selectBtn = "SELECT";
      listDrone.selected = false;
    }
  },

  NEW_SYSTEM(state: DetectedDroneListState, systemId: string) {
    state.detectedDroneList.push({
      systemId: systemId,
      ploted: false,
      added: false,
      car: false,
      selected: false,
      addBtn: "ADD",
      selectBtn: "SELECT",
      loading: false,
    });
  },

  REMOVE_SYSTEM(state: DetectedDroneListState, systemId: string) {
    state.detectedDroneList = state.detectedDroneList.filter((item) => {
      return item.systemId !== systemId;
    });
  },

  CONVERT_TO_DRONE(state: DetectedDroneListState, drone: DetectedDroneObject) {
    state.detectedDroneList.find((item) => {
      return item.systemId === drone.systemId;
    })!.car = false;
  },

  CONVERT_TO_CAR(state: DetectedDroneListState, drone: DetectedDroneObject) {
    state.detectedDroneList.find((item) => {
      return item.systemId === drone.systemId;
    })!.car = true;
  },

  TOGGLE_CAR_MODE(state: DetectedDroneListState) {
    state.carModeEnabled = !state.carModeEnabled;
  },

  TIMER_STARTED(state: DetectedDroneListState, timerId: number) {
    state.timerId = timerId;
  },

  TIMER_STOPPED(state: DetectedDroneListState) {
    state.timerId = 0;
  },
};

const actions = {
  getFromBackendAPI(context: any) {
    const loc =
      process.env.NODE_ENV === "production"
        ? "https://rima2.ac.upc.edu:8443"
        : window.location.origin;

    if (isElectron() || Capacitor.getPlatform() === "android") {
      axios
        .get(loc + "/detected_drones", {
          auth: {
            username: process.env.USER_NAME!,
            password: process.env.PASS!,
          },
        })
        .then((response) => {
          response.data.detectedDroneList.forEach((systemId: string) => {
            const found = context.state.detectedDroneList.find(
              (element: DetectedDroneObject) => element.systemId === systemId
            );
            if (!found) {
              context.commit("NEW_SYSTEM", systemId);
            }
          });

          context.state.detectedDroneList.forEach(
            (drone: DetectedDroneObject) => {
              const found = response.data.detectedDroneList.find(
                (systemId: string) => systemId === drone.systemId
              );
              if (!found) {
                context.commit("REMOVE_SYSTEM", drone.systemId);
                context.dispatch("addedDroneObject/removeDrone", drone.systemId, {
                  root: true,
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          context.dispatch("alert/popAlert", new Alert(0, "error", error), {
            root: true,
          });
        });
    } else {
      axios
        .get(loc + "/detected_drones")
        .then((response) => {
          response.data.detectedDroneList.forEach((systemId: string) => {
            const found = context.state.detectedDroneList.find(
              (element: DetectedDroneObject) => element.systemId === systemId
            );
            if (!found) {
              context.commit("NEW_SYSTEM", systemId);
            }
          });

          context.state.detectedDroneList.forEach(
            (drone: DetectedDroneObject) => {
              const found = response.data.detectedDroneList.find(
                (systemId: string) => systemId === drone.systemId
              );
              if (!found) {
                context.commit("REMOVE_SYSTEM", drone.systemId);
                context.dispatch("addedDroneObject/removeDrone", drone.systemId, {
                  root: true,
                });
              }
            }
          );
        })
        .catch((error) => {
          console.log(error);
          context.dispatch("alert/popAlert", new Alert(0, "error", error), {
            root: true,
          });
        });
    }
  },

  setPloted(context: any, droneId: string) {
    context.commit("SET_PLOTED", droneId);
  },

  toggleDroneAddition(context: any, drone: DetectedDroneObject) {
    if (drone.ploted) {
      context.commit("REMOVE_FROM_MAP", drone);
      context.commit("UNSELECT", drone);
    } else {
      context.commit("ADD_TO_MAP", drone);
    }
  },

  toggleDroneSelection(context: any, drone: DetectedDroneObject) {
    if (drone.selected) {
      context.commit("UNSELECT", drone);
    } else {
      context.commit("SELECT", drone);
    }
  },

  toggleCarEnabled(context: any) {
    context.commit("TOGGLE_CAR_MODE");
  },

  toggleCar(context: any, drone: DetectedDroneObject) {
    if (drone.car) {
      context.commit("CONVERT_TO_DRONE", drone);
      convertToDrone(drone.systemId);
    } else {
      context.commit("CONVERT_TO_CAR", drone);
      convertToCar(drone.systemId);
    }
  },

  startTimer(context: any) {
    const id = setInterval(
      function () {
        context.dispatch("getFromBackendAPI");
      }.bind(this),
      5000
    );
    context.commit("TIMER_STARTED", id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
