<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
      >
        Delete Role
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5">Delete Role</v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container>
            <v-text-field
              ref="search"
              v-model="search"
              full-width
              hide-details
              label="Search"
              single-line
            ></v-text-field>
            <v-divider></v-divider>
            <v-list>
              <template v-for="(role, index) in filteredRoles">
                <v-list-item
                  :key="index"
                  :disabled="deletingRole"
                  @click="confirm(role)"
                >
                  <v-list-item-title v-text="role"></v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="deletingRole"
          @click="close"
        >Cancel</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: "CreateRole",
  data() {
    return {
      dialog: false,
      deletingRole: false,
      valid: false,
      roles: [],
      search: '',
    }
  },
  watch: {
    dialog(visible) {
      if (visible) {
        this.getRoles();
        this.search = '';
      }
    }
  },
  computed: {
    filteredRoles() {
      const search = this.search.toLowerCase();

      if (!search) return this.roles;

      return this.roles.filter(role => {
        return role.toLowerCase().indexOf(search) > -1;
      });
    }
  },
  methods: {
    getRoles() {
      const loc =
        process.env.NODE_ENV === "production"
          ? "https://rima2.ac.upc.edu:8443"
          : window.location.origin;
  
      axios.get(
        loc + "/auth/all_roles"
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't stablish connection");
          this.dialog = false;
          return;
        }
        this.roles = response.data.roles.map(role => role.name);
        console.log(this.roles);
      }).catch((error) => {
        console.log(error);
        this.dialog = false;
        return;
      });
    },
    close() {
      this.dialog = false;
      this.deletingRole = false;
      this.search = '';
    },
    confirm(role) {
      if (this.$refs.form.validate()) {
        this.changingRoles = true;
        const loc =
          process.env.NODE_ENV === "production"
            ? "https://rima2.ac.upc.edu:8443"
            : window.location.origin;
        axios.delete(
          loc + "/auth/roles/" + role,
        ).then((response) => {
          if (response.status !== 200) {
            console.log("Couldn't delete role");
            this.close();
            return;
          }
  
          this.$emit('role-deleted');
          this.close();
        }).catch((error) => {
          console.log(error);
          this.close();
          return;
        });
      }
    },
  }
}
</script>

<style>

</style>