var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"grid pt-2 px-2 overflow-x-auto",attrs:{"width":"950px","height":"320"}},[_c('v-data-table',{staticClass:"elevation-1 table",attrs:{"items-per-page":-1,"dense":"","headers":_vm.headers,"fixed-header":"","hide-default-footer":"","items":_vm.FP.waypoints,"id":"table","height":"310","disable-sort":"","header-props":{ sortIcon: null }},on:{"dblclick:row":_vm.rowDblClicked},scopedSlots:_vm._u([{key:"header.altitude",fn:function(ref){
var header = ref.header;
return [_c('v-btn',{attrs:{"type":"button","x-small":"","color":_vm.buttonColorAlt},on:{"click":_vm.altClick}},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.lat",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.lat},on:{"update:returnValue":function($event){return _vm.$set(item, "lat", $event)},"update:return-value":function($event){return _vm.$set(item, "lat", $event)},"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.lat),callback:function ($$v) {_vm.$set(item, "lat", _vm._n($$v))},expression:"item.lat"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(((item.lat ? item.lat.toFixed(9) : item.lat) + "º"))+" ")])]}},{key:"item.lon",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.lon},on:{"update:returnValue":function($event){return _vm.$set(item, "lon", $event)},"update:return-value":function($event){return _vm.$set(item, "lon", $event)},"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","type":"number"},model:{value:(item.lon),callback:function ($$v) {_vm.$set(item, "lon", _vm._n($$v))},expression:"item.lon"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(((item.lon ? item.lon.toFixed(9) : item.lon) + "º"))+" ")])]}},{key:"item.altitude",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.altitude},on:{"update:returnValue":function($event){return _vm.$set(item, "altitude", $event)},"update:return-value":function($event){return _vm.$set(item, "altitude", $event)},"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","type":"number"},on:{"keydown":function($event){return _vm.updateAltitudes($event, item)}},model:{value:(item.altitude),callback:function ($$v) {_vm.$set(item, "altitude", _vm._n($$v))},expression:"item.altitude"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(item.altitude ? item.altitude.toFixed(3) : item.altitude)+" ")])]}},{key:"item.speed",fn:function(ref){
var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"return-value":item.speed},on:{"update:returnValue":function($event){return _vm.$set(item, "speed", $event)},"update:return-value":function($event){return _vm.$set(item, "speed", $event)},"open":_vm.open,"close":_vm.close},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-text-field',{attrs:{"label":"Edit","single-line":"","counter":"","type":"number","min":1},on:{"change":function($event){return _vm.updateSpeeds(item.speed)}},model:{value:(item.speed),callback:function ($$v) {_vm.$set(item, "speed", _vm._n($$v))},expression:"item.speed"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(((item.speed ? item.speed.toFixed() : item.speed) + " m/s"))+" ")])]}}],null,true)}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.configActionMenu,"opacity":"0.8"}},[_c('v-card',{attrs:{"width":"300","flat":"","color":"rgba(0,0,0,0)"}},[_c('Takeoff',{ref:"takeoff",on:{"command-parameters":_vm.applyActionConfig}})],1),_c('v-btn',{on:{"click":_vm.getParameters}},[_vm._v(" Apply ")])],1),(!_vm.$vuetify.breakpoint.smAndDown)?_c('ElevationProfileComponent',{attrs:{"id":"elevation-profile-component"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }