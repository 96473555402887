<template>
  <v-menu
    offset-y
    open-on-hover
    transition="slide-y-transition"
    close-delay="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <v-icon> mdi-hammer-wrench </v-icon>
      </v-btn>
    </template>
    <div v-for="(item, index) in items" v-bind:key="index" class="my-1">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click.stop="item.method"
            color="default"
            fab
            small
            class="ml-1"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-btn>
        </template>
        <span>{{ item.name }}</span>
      </v-tooltip>
    </div>
  </v-menu>
</template>

<script>
import { checkAreaExists } from '../../esriAPI/AreaManager/AreaUtils';
// eslint-disable-next-line no-unused-vars
import { toggleElevationProfile } from "../../esriAPI/ElevationProfile";
export default {
  data() {
    return {
      items: [
        {
          name: "Compute scan",
          icon: "mdi-magnify-scan",
          method: this.createScan,
        },
        {
          name: "Scan Statistics",
          icon: "mdi-clipboard-text-outline",
          method: this.openStatistics,
        },
        {
          name: "Elevation info",
          icon: "mdi-elevation-rise",
          method: toggleElevationProfile,
        },
        {
          name: "Settings",
          icon: "mdi-cog-outline",
          method: this.openGeneralSettings,
        },
      ],
    };
  },
  methods: {
    createScan() {
      if(checkAreaExists(true)){
        this.$emit("open-fp-settings");
      }
    },
    openStatistics() {
      this.$emit("open-fp-statistics");
    },
    openGeneralSettings() {
      this.$emit("open-general-settings");
    },
  },
};
</script>
