import { FlightPlan, Waypoint } from "@/utils/DataModels";
import { Drone, DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { FlightPlanProtocolTypes } from "@/utils/FlightPlanProtocol";
import { SocketMessageTypes, WebSocketMessage } from "@/utils/WebsocketProtocol";
import { sendWebSocketMessage } from "@/websocket/websocket";
import { plotArea, removePlotedArea } from "../../esriAPI/AreaManager/AreaEditor";
import { plotFP, removePlotedFP } from "../../esriAPI/FlightPlanManager/FlightPlanEditor";
import { AppState } from "../../utils/StateUtils";
//Fp: Area, Stages"array of waypoints
import { updateSelectedDrone } from "@/esriAPI/EsriMap";
import { addDrone } from "@/esriAPI/ManageDroneLayer";
import { vuexStore } from "../../main";

interface StateState {
  appState: AppStateType;
  lastAppState: AppStateType | null;
  displayedFlightPlan: FlightPlan | undefined;
  temporalFlightPlan: FlightPlan | undefined;
  fileList: any | undefined;
  statistics: any | undefined;
}

export type AppStateType =
  | "DEFAULT"
  | "AREA_CREATION"
  | "AREA_PLOTED"
  | "FLIGHT_PLAN_CREATION"
  | "FLIGHT_PLAN_PLOTED"
  | "MISSION_PLAYER";

const state: StateState = {
  appState: "DEFAULT",
  lastAppState: null,

  //FlightPlan
  displayedFlightPlan: undefined,
  temporalFlightPlan: undefined, //stores the flightplan that is created when no drone is created.

  fileList: undefined,

  statistics: undefined,
};

const getters = {
  getAppState: (state: StateState) => {
    return state.appState;
  },
  getLastAppState: (state: StateState) => {
    return state.lastAppState;
  },
  getDisplayedFlightPlan: (state: StateState) => {
    const selectedDroneId: any =
      vuexStore.getters["addedDroneObject/getSelectedDroneId"];

    return selectedDroneId
      ? state.displayedFlightPlan
      : state.temporalFlightPlan;
  },

  getStatistics: (state: StateState) => {
    return state.statistics;
  },


  getFileList: (state: StateState) => {
    if (!state.fileList) {
      return [{ "name": "No files" }];
    }
    const listFiles = []
    for (const i in state.fileList) {
      listFiles.push({ "name": state.fileList[i] })
    }
    return listFiles;
  },
  getState: (state: StateState) => {
    return state;
  },
};

const mutations = {
  CHANGE_APPSTATE(state: StateState, appstate: AppStateType) {
    state.lastAppState = state.appState;
    state.appState = appstate;
  },
  SET_DISPLAYED_FLIGHTPLAN(state: StateState, flightPlan: FlightPlan) {
    state.displayedFlightPlan = flightPlan;
  },
  SET_STATISTICS(state: StateState, statistics: any) {
    state.statistics = statistics;
  },
  SET_FILE_LIST(state: StateState, fileList: JSON) {
    state.fileList = fileList;
  },
  SET_TEMPORAL_FLIGHTPLAN(state: StateState, flightPlan: FlightPlan) {
    state.temporalFlightPlan = flightPlan;
  },
  MODIFY_DISPLAYED_FLIGHTPLAN_ACTIONS(state: StateState, waypoint: Waypoint) {
    if (state.displayedFlightPlan) {
      state.displayedFlightPlan.waypoints.find((wp: Waypoint) => {
        return wp.order === waypoint.order;
      })!.actions = waypoint.actions;
    } else if (state.temporalFlightPlan) {
      state.temporalFlightPlan.waypoints.find((wp: Waypoint) => {
        return wp.order === waypoint.order;
      })!.actions = waypoint.actions;
    }
  },
};

const actions = {
  changeAppstate(context: any, appState: AppStateType) {
    context.commit("CHANGE_APPSTATE", appState);
  },

  setLastAppstate(context: any) {
    context.commit("CHANGE_APPSTATE", context.state.lastAppState);
  },

  setDisplayedFlightPlan(
    context: any,
    parameters: { systemId: string; flightPlan: FlightPlan }
  ) {
    const flightPlan: FlightPlan = parameters.flightPlan;
    const systemId: string = parameters.systemId;

    if (flightPlan == null || flightPlan.waypoints.length === 0)
      context.commit("CHANGE_APPSTATE", AppState.DEFAULT);
    else {
      context.commit("CHANGE_APPSTATE", AppState.DEFAULT);
      //RESET UI
      setTimeout(function () {
        context.commit("CHANGE_APPSTATE", AppState.FLIGHT_PLAN_PLOTED);
      }, 25);
    }
    if (systemId && systemId != DUMMY_DRONE_ID) {
      context.commit("SET_DISPLAYED_FLIGHTPLAN", flightPlan);
    } else {
      context.commit("SET_TEMPORAL_FLIGHTPLAN", flightPlan);
    }
    plotFP(flightPlan);
    plotArea(flightPlan);
  },

  setStatistics(
    context: any,
    parameters: { systemId: string; statistics: any }
  ) {
    context.commit("SET_STATISTICS", parameters.statistics);
  },

  setFileList(
    context: any,
    parameters: { systemId: string; fileList: JSON }
  ) {
    const fileList: JSON = parameters.fileList;
    context.commit("SET_FILE_LIST", fileList);
  },

  displayTemporalFlightPlan(context: any) {
    if (context.state.temporalFlightPlan) {
      context.dispatch(
        "setDisplayedFlightPlan",
        context.state.temporalFlightPlan
      );
    } else {
      context.dispatch("removeDisplayedFlightPlan");
    }
  },

  removeDisplayedFlightPlan(context: any) {
    if (context.state.appState == AppState.FLIGHT_PLAN_PLOTED) {
      context.commit("CHANGE_APPSTATE", AppState.DEFAULT);
      removePlotedFP();
      removePlotedArea();
    }
  },

  updateSelectedDroneFlightPlan(
    context: any,
    { systemId, flightPlan }: { systemId: string; flightPlan: FlightPlan }
  ) {
    if (!systemId) {
      systemId =
        context.rootGetters["addedDroneObject/getSelectedDroneId"] ? context.rootGetters["addedDroneObject/getSelectedDroneId"] : DUMMY_DRONE_ID;
    }
    context.dispatch("setDisplayedFlightPlan", {
      systemId: systemId,
      flightPlan: flightPlan,
    });

  },

  updateStatistics(
    context: any,
    { systemId, statistics }: { systemId: string; statistics: any }
  ) {
    if (!systemId) {
      systemId =
        context.rootGetters["addedDroneObject/getSelectedDroneId"] ? context.rootGetters["addedDroneObject/getSelectedDroneId"] : DUMMY_DRONE_ID;
    }
    context.dispatch("setStatistics", {
      systemId: systemId,
      statistics: statistics,
    });
  },

  updateActions(context: any, waypoint: Waypoint) {
    context.commit("MODIFY_DISPLAYED_FLIGHTPLAN_ACTIONS", waypoint);
    const selectedDroneId =
      context.rootGetters["addedDroneObject/getSelectedDroneId"];
    sendWebSocketMessage(
      new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, {
        systemId: selectedDroneId ? selectedDroneId : DUMMY_DRONE_ID,
        type: FlightPlanProtocolTypes.UPDATED_FP,
        flightPlan: context.state.displayedFlightPlan
          ? context.state.displayedFlightPlan
          : context.state.temporalFlightPlan,
      })
    );
  },

  listFiles(
    context: any,
    { systemId, fileList }: { systemId: string; fileList: JSON }
  ) {
    context.dispatch("setFileList", {
      systemId: systemId,
      fileList: fileList,
    });
  },

  recoverMapState(context: any) {
    const flightPlan = context.state.displayedFlightPlan ? context.state.displayedFlightPlan : context.state.temporalFlightPlan;
    if (flightPlan) {
      plotFP(flightPlan);
      plotArea(flightPlan);
    }

    context.rootState.addedDroneObject.addedDroneObject.forEach((drone: Drone, droneId: string) => {
      console.log(droneId, drone);
      addDrone(drone);
    });

    const selectedDroneId = context.rootState.addedDroneObject.selectedDroneId;
    console.log(selectedDroneId);
    if (selectedDroneId) {
      updateSelectedDrone(selectedDroneId, null);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
