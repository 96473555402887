import { FlightPlan, Waypoint } from "@/utils/DataModels";
import { Point, Polyline } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import { vuexStore } from "../../main";
import { FlightPlanProtocolTypes } from "../../utils/FlightPlanProtocol";
import { AppState } from "../../utils/StateUtils";
import {
  footprintLayer, layerFP,
  layerFP2, spatialReference, view, waypointLabels
} from "../EsriMap";
import { plotScanfootPrint } from "./FlightPlanFootprint";
import { getNextStage, getStageEnd, sendFlightPlanToBackend } from "./FlightPlanUtils";


export const defaultAltitude = 5;

let sketchViewModel: SketchViewModel;
let wpLength: number;

let prevPath: any;
let modifiedWp: boolean = false;

let stages: string[] = [];

const defaultSpeed = 5;

const polylineSymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [0, 255, 255],
  width: 5,
});

const footprintSymbol: any = {
  type: "simple-fill",
  color: [0, 153, 255, 0.4],
  outline: {
    color: [0, 153, 255],
    width: 2,
  },
};

export function initializeFPcreator() {
  sketchViewModel = new SketchViewModel({
    view: view,
    layer: layerFP,
    pointSymbol: {
      type: "simple-marker",
      style: "circle",
      size: 6,
      color: [255, 255, 255],
      outline: {
        color: [50, 50, 50],
        width: 1,
      },
    },
    polylineSymbol: polylineSymbol,
  });
  sketchViewModel.on("create", function (event: any) {
    listenForCompletion(event);
  });
  sketchViewModel.on("update", function (event: any) {
    if (event.state === "start") {
      vuexStore.dispatch("state/changeAppstate", AppState.FLIGHT_PLAN_CREATION);
    }
    listenForCompletion(event);
    checkForAddedWaypoints(event);
  });

  sketchViewModel.on("delete", function () {
    clearFP();
    wpLength = 0;

    const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
    FP.waypoints = [];
    sendFlightPlanToBackend(FP, FlightPlanProtocolTypes.UPDATED_FP);
    vuexStore.dispatch("state/changeAppstate", AppState.DEFAULT);
  });
  wpLength = 0;
}

export function createFP() {
  if (layerFP.graphics.length === 0) {
    sketchViewModel.create("polyline");
  } else {
    //On create when point already exist, save points to display while editing
    const prevGraphic: Graphic = layerFP.graphics.getItemAt(0).clone();
    layerFP2.graphics.add(prevGraphic);
    clearFP();
    sketchViewModel.create("polyline");
  }
}

export function saveFP() {
  sketchViewModel.complete();
  vuexStore.dispatch("state/changeAppstate", AppState.DEFAULT);
}

export function deleteFP() {
  sketchViewModel.delete();
  modifiedWp = false;
}


function clearFP() {
  if (layerFP.graphics.getItemAt(0)) {
    layerFP.remove(layerFP.graphics.getItemAt(0));
  }
  waypointLabels.removeAll();

}

function checkForAddedWaypoints(event: any) {
  if (event.state === "start") {
    stages = [];
    modifiedWp = true;

    prevPath = event.graphics[0].geometry.paths[0];
    const FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
    for (let i = 0; i < FP.waypoints.length; i++) {
      stages.push(FP.waypoints[i].stage);
    }
  }
  if (event.state === "active") {
    const newWPLenght = event.graphics[0].geometry.paths[0].length;
    if (wpLength != newWPLenght) {
      const path = event.graphics[0].geometry.paths[0];

      if (wpLength < newWPLenght) {
        for (let i = 0; i < prevPath.length; i++) {
          if (prevPath[i][0] != path[i][0] && prevPath[i][1] != path[i][1]) {
            stages.splice(i, 0, getNextStage(stages[i - 1], stages[i]));
            break;
          }
        }
      } else {
        for (let i = 0; i < path.length; i++) {
          if (prevPath[i][0] != path[i][0] && prevPath[i][1] != path[i][1]) {
            stages.splice(i, 1);
            break;
          }
        }
      }
      prevPath = path;
      wpLength = event.graphics[0].geometry.paths[0].length;
    } else {
      prevPath = event.graphics[0].geometry.paths[0];
    }
  }
}

function listenForCompletion(event: any) {
  if (event.state === "complete") {
    if (modifiedWp) { //waypoints added with (+) or moved

      generateFPwithNewWP();
      modifiedWp = false;
    } else { //waypoints added with mouse
      generateFP();
    }

  }
}

function generateFP() {
  const waypointArray: Waypoint[] = [];
  let speed = defaultSpeed;
  let FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP) {
    FP = new FlightPlan();
  } else {
    if (FP.waypoints.length > 0) {
      speed = FP.waypoints[0].speed;
    }
  }

  //First segment of FP that was storeed while adding points
  if (layerFP2.graphics.getItemAt(0)) {
    const polyline2: any = layerFP2.graphics.getItemAt(0).geometry;
    for (let i = 0; i < polyline2.paths[0].length; i++) {
      const point = polyline2.getPoint(0, i);
      const stage: string = FP.waypoints[i].stage;
      waypointArray.push(
        new Waypoint(i + 1, point.latitude, point.longitude, point.z, stage, speed)
      );
    }
    layerFP2.remove(layerFP2.graphics.getItemAt(0));
  }

  //New segment of FP that was added in edit (set at default altitude)
  if (layerFP.graphics.getItemAt(0)) {
    const polyline: any = layerFP.graphics.getItemAt(0).geometry;
    const orderNum = waypointArray.length + 1;
    const stage: string = getStageEnd();
    for (let i = 0; i < polyline.paths[0].length; i++) {
      const point = polyline.getPoint(0, i);
      waypointArray.push(
        new Waypoint(
          i + orderNum,
          point.latitude,
          point.longitude,
          point.z + defaultAltitude,
          stage, speed
        )
      );
    }
  }

  FP.waypoints = waypointArray;
  wpLength = waypointArray.length;
  sendFlightPlanToBackend(FP, FlightPlanProtocolTypes.UPDATED_FP);
}

function generateFPwithNewWP() {
  const waypointArray: Waypoint[] = [];
  let speed = defaultSpeed;
  let FP: FlightPlan = vuexStore.getters["state/getDisplayedFlightPlan"];
  if (!FP) {
    FP = new FlightPlan();
  } else {

    if (FP.waypoints != null && FP.waypoints.length > 0) {
      speed = FP.waypoints[0].speed;
    }
  }

  if (layerFP.graphics.getItemAt(0)) {
    const polyline: any = layerFP.graphics.getItemAt(0).geometry;
    for (let i = 0; i < polyline.paths[0].length; i++) {
      const point = polyline.getPoint(0, i);
      waypointArray.push(
        new Waypoint(i + 1, point.latitude, point.longitude, point.z, stages[i], speed)
      );
    }
  }

  FP.waypoints = waypointArray;

  wpLength = waypointArray.length;
  sendFlightPlanToBackend(FP, FlightPlanProtocolTypes.UPDATED_FP);
}


export function plotFP(FP: FlightPlan) {
  clearFP();
  if (footprintLayer.graphics.getItemAt(0)) {
    footprintLayer.remove(footprintLayer.graphics.getItemAt(0));
  }

  const polyline = new Polyline({
    hasZ: true,
    spatialReference: spatialReference,
  });



  const points: Point[] = [];

  FP.waypoints.forEach((waypoint: Waypoint) => {
    points.push(
      new Point({
        spatialReference: spatialReference,
        hasZ: true,
        latitude: waypoint.lat,
        longitude: waypoint.lon,
        z: waypoint.altitude,
      })
    );
  });

  if (points.length != 0) {
    polyline.addPath(points);

    const graphic = new Graphic({
      geometry: polyline,
      symbol: polylineSymbol,
    });

    addGraphicToWaypointLayer(graphic);
    plotScanfootPrint(polyline);
  }
}

function addGraphicToWaypointLayer(graphic: Graphic) {

  layerFP.add(graphic);
  waypointLabels.removeAll();

  //creates all the number labels for that polygon
  const polyline: any = graphic.geometry;
  for (let i = 0; i < polyline.paths[0].length; i++) {
    const point = polyline.getPoint(0, i);
    point.z = point.z + 0.1;

    const txtSym = {
      type: "text",
      color: [0, 255, 255],
      haloColor: "black",
      haloSize: "1px",
      text: i + 1,
      font: {
        size: 13,
        family: "Josefin Slab",
        weight: "bold"
      }
    };
    waypointLabels.add(new Graphic({
      geometry: point,
      symbol: txtSym,
    }));
  }
}

export function removePlotedFP() {
  clearFP();
  if (footprintLayer.graphics.getItemAt(0))
    footprintLayer.remove(footprintLayer.graphics.getItemAt(0));
}

