import { vuexStore } from "@/main";
import { Telemetry } from "@/utils/DroneDataModel";
import { Point } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import LayerView from "@arcgis/core/views/layers/LayerView";
import LatLon, { Ned } from "geodesy/latlon-nvector-ellipsoidal";
import { addLayerToMap, radSourceLayer, spatialReference, timeSlider, view } from "./esriMap";

export const DEFAULT_THRESH1 = 25;
export const DEFAULT_THRESH2 = 5000;

export const SENSOR_HEIGHT = 0.6;

let currentThresh1 = DEFAULT_THRESH1;
let currentThresh2 = DEFAULT_THRESH2;

export const DEFAULT_RAD = 0;
const radTypes = ["radiationCPS", "radiationCPSN"]

let currentRad = DEFAULT_RAD;

let cylinderHeight: boolean = true;

const radiationFeatures = [];
export let radDetectionLayer: any;
export let radLayerView: LayerView;


export function clearRad() {
  radDetectionLayer.destroy();
}

export function removeRadPlot() {
  radDetectionLayer.features = [];
  radDetectionLayer.listMode = "hide";
  radDetectionLayer.visible = false;
}

function renderAgain() {
  const newRenderer = radDetectionLayer.renderer.clone();

  newRenderer.visualVariables =
    [{
      type: "color",
      field: radTypes[currentRad],
      stops: [
        {
          value: currentThresh1,
          color: "yellow",
        },
        {
          value: currentThresh2,
          color: "red",
        }
      ]
    },
    {
      type: "size",
      field: cylinderHeight ? radTypes[currentRad] : currentThresh1,
      stops: [
        {
          value: currentThresh1,
          size: cylinderHeight ? 1 : 0.1,
        },
        {
          value: currentThresh2,
          size: 3,
        }
      ],
      axis: "height"
    },
    {
      type: "size",
      field: "relativeWidth",
      axis: "width-and-depth",
      minDataValue: 0.1,
      maxDataValue: 20,
      minSize: "0.1",
      maxSize: "20"
    }];

  radDetectionLayer.renderer = newRenderer;
}

export function changeThresholdRange(newRange: number[]) {
  currentThresh1 = newRange[0];
  currentThresh2 = newRange[1];

  renderAgain();
}

export function changeRadiationType(index: number) {
  currentRad = index;

  renderAgain();
}

export function setCylinderHeight(isHeight: boolean) {
  cylinderHeight = isHeight;
}

export function initializeRadiation(telemetryList: Telemetry[]) {
  const features = telemetryList
    .filter(telemetry => telemetry.counts > -1)
    .map((telemetry: Telemetry, index: number) => {
      const coneSlope = Math.tan(0.5 * (vuexStore.getters["addedDroneObject/getSensorFOV"] * Math.PI) / 180.0);
      const dRadius = coneSlope * (telemetry.relativeAltitude - SENSOR_HEIGHT);

      const heading: number = telemetry.yaw;
      const yLocal = Math.tan(telemetry.pitch) * telemetry.relativeAltitude;
      const xLocal = -Math.tan(telemetry.roll) * telemetry.relativeAltitude;
      const yAbs = Math.cos(heading) * yLocal + Math.sin(heading) * xLocal
      const xAbs = Math.sin(heading) * yLocal + Math.cos(heading) * xLocal

      const SMR = new LatLon(telemetry.latitude, telemetry.longitude, 0);
      const ned = new Ned(yAbs, xAbs, 0);
      const pointLOS = SMR.destinationPoint(ned);
      return {
        geometry: {
          type: "point",
          spatialReference: spatialReference,
          hasZ: false,
          latitude: pointLOS.latitude,
          longitude: pointLOS.longitude,
          z: 0,
        }, attributes: {
          radiation: telemetry.counts,
          radiationCPS: telemetry.countsPerSecond,
          radiationCPSN: telemetry.normalizedCountsPerSecond,
          relativeWidth: dRadius,
          ObjectID: index,
          timestamp: telemetry.timestamp,
          timestamp2: telemetryList[telemetryList.length - 1].timestamp
        }
      };
    });

  const renderer: any = {
    type: "simple",
    symbol: {
      type: "point-3d",
      symbolLayers: [
        {
          width: 0.4,

          type: "object",
          resource: {
            primitive: "cylinder"
          },
        }
      ]
    },
  };

  renderer.visualVariables = [{
    type: "color",
    field: radTypes[DEFAULT_RAD],
    stops: [
      {
        value: DEFAULT_THRESH1,
        color: "yellow",
      },
      {
        value: DEFAULT_THRESH2,
        color: "red",
      }
    ]
  },
  {
    type: "size",
    field: radTypes[DEFAULT_RAD],
    stops: [
      {
        value: DEFAULT_THRESH1,
        size: 1,
      },
      {
        value: DEFAULT_THRESH2,
        size: 3,
      }
    ],
    axis: "height"
  },
  {
    type: "size",
    field: "relativeWidth",
    axis: "width-and-depth",
    minDataValue: 0.1,
    maxDataValue: 20,
    minSize: "0.1",
    maxSize: "20"
  }];



  radDetectionLayer = new FeatureLayer({
    spatialReference: spatialReference,
    source: features,
    title: "Radiation Detected",
    listMode: "hide",
    geometryType: "point",
    hasZ: true,
    renderer: renderer,
    objectIdField: "ObjectID",
    fields: [{
      name: "ObjectID",
      type: "oid"
    }, {
      name: radTypes[0],
      type: "double"
    },
    {
      name: radTypes[1],
      type: "double"
    },
    {
      name: "relativeWidth",
      type: "double"
    },
    {
      name: "timestamp",
      type: "date"
    },
    {
      name: "timestamp2",
      type: "date"
    }],
    timeInfo: {
      startField: "timestamp",
      endField: "timestamp2"
    }
  });
  radDetectionLayer.elevationInfo = { mode: "on-the-ground" };


  addLayerToMap(radDetectionLayer);
  view.whenLayerView(radDetectionLayer).then(lv => {
    radLayerView = lv;
  });
}

export function placeRadiationSource(id: string, latitude: number, longitude: number) {
  const point = new Point({
    spatialReference: spatialReference,
    latitude: latitude,
    longitude: longitude,
  });
  const symbol = {
    type: "point-3d",
    symbolLayers: [
      {
        type: "object",
        width: 0.5,
        height: 2,
        depth: 0.5,
        resource: { primitive: "cylinder" },
        material: { color: "blue" },
      },
    ],
  };
  const graphic = new Graphic({
    geometry: point,
    // @ts-ignore
    symbol: symbol,
  });
  radSourceLayer.add(graphic);
}

function simulateFeatures() {

  const features = [];
  for (let i = 0; i < 10; i++) {
    features[i] = {
      geometry: {
        type: "point",
        spatialReference: spatialReference,
        hasZ: true,
        latitude: 41.2748 + i * Math.random() / 10000,
        longitude: 1.988 + i * Math.random() / 10000,
        z: 100,

      }, attributes: {
        radiation: 50 + i * 500,
        relativeWidth: 2,
        maxRadiation: 1,
        ObjectID: i,
      }
    };
  }
  return features;

}
