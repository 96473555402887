<template>
  <div>
    <h4>Yaw Angle:</h4>
    <v-slider
      v-model="yaw"
      class="align-center"
      :max="yawMax"
      :min="yawMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="yaw"
          suffix="º"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 60px"
          :rules="[rules.yawMax, rules.yawMin]"
        ></v-text-field>
      </template>
    </v-slider>
    <v-radio-group row mandatory v-model="relativeAngle">
      <v-radio label="Relative" value="1"></v-radio>
      <v-radio label="Absolute" value="0"></v-radio>
    </v-radio-group>
    <v-divider class="pb-2"></v-divider>
    <h4>Speed:</h4>
    <v-slider
      v-model="speed"
      class="align-center"
      :max="speedMax"
      :min="speedMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="speed"
          suffix="rad/s"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 75px"
          :rules="[rules.speedMax, rules.speedMin]"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script>
import { getMagnitudeBounds } from "../../utils/Commands";
export default {
  data() {
    return {
      speedMin: 0,
      speedMax: 100,
      yawMin: 0,
      yawMax: 100,
      speed: 0,
      yaw: 0,
      relativeAngle: 1,
      rules: {
        speedMax: (value) => value <= this.speedMax || "Too large",
        speedMin: (value) => value >= this.speedMin || "Too small",
        yawMax: (value) => value <= this.yawMax || "Too large",
        yawMin: (value) => value >= this.yawMin || "Too small",
      },
    };
  },

  created: function() {
    this.speedMin = getMagnitudeBounds("turnyaw").speed.min;
    this.speedMax = getMagnitudeBounds("turnyaw").speed.max;
    this.speed = getMagnitudeBounds("turnyaw").speed.default;

    this.yawMin = getMagnitudeBounds("turnyaw").angle.min;
    this.yawMax = getMagnitudeBounds("turnyaw").angle.max;
    this.yaw = getMagnitudeBounds("turnyaw").angle.default;
  },

  methods: {
    emitParameters() {
      this.$emit("command-parameters", {
        speed: this.speed,
        yaw: Math.abs(this.yaw),
        relativeAngle: this.relativeAngle == "0" ? 0 : 1,
        clockWise: this.yaw < 0 ? 0 : 1,
      });
    },
  },
};
</script>
