<template>
  <div>
    <v-alert
      v-for="alert in alerts"
      v-bind:key="alert.id"
      v-bind:type="alert.type"
      dismissible
      @input="dismiss(alert.id)"
    >
      {{ alert.message }}
    </v-alert>

    <!-- <v-btn
      @click="
        testAlert(
          Math.floor(Math.random() * 10000),
          'warning',
          'this is a test msg'
        )
      "
    >
      test alert
    </v-btn> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    testAlert(id, type, message) {
      this.$store.dispatch("alert/popAlert", {
        id: id,
        type: type,
        message: message,
      });
    },
    dismiss(id) {
      this.$store.dispatch("alert/dismissAlert", id);
    },
  },
  computed: mapGetters({ alerts: "alert/getAlerts" }),
};
</script>
