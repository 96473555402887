<template>
  <v-overlay absolute :value="overlay" opacity="0.8">
    <v-card max-width="500px">
      <v-card-title>Mission History</v-card-title>
      <div style="max-width: 400px">
        <v-text-field
          class="my-5"
          v-model="searchField"
          append-icon="mdi-magnify"
          outlined
          dingle-line
          hide-details
        ></v-text-field>
        <v-data-table
          filterable
          @click:row="missionClicked"
          dark
          :headers="headers"
          :items="missionList"
          item-key="name"
          class="elevation-1 table my-5"
          :search="searchField"
          :items-per-page="5"
        >
          <template v-slot:top> </template>
        </v-data-table>
      </div>
      <v-card-actions class="justify-center">
        <v-btn color="error" @click.stop="goBack">Go back</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>  
</template>

<script>
import { vuexStore } from '@/main';
import { mapGetters } from 'vuex'
import { MissionPlayerStateEnum } from '@/store/modules/missionPlayer';
export default {
  data() {
    return {
      searchField: "",
      headers: [
        {
          text: "Mission ID",
          align: "start",
          sortable: false,
          value: "missionId",
        },
        {
          text: "System ID",
          align: "end",
          sortable: false,
          value: "systemId",
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      missionPlayerState: "missionPlayer/getState",
      missionList: "missionPlayer/getMissionList",
    }),
    overlay() {
      if (this.missionPlayerState === MissionPlayerStateEnum.SELECTING_MISSIONS) {
        // TODO: GET MISSIONS DATA
        vuexStore.dispatch("missionPlayer/loadMissionList");
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    missionClicked(value) {
      vuexStore.dispatch("missionPlayer/selectMission", value);
    },
    goBack() {
      this.$router.push("/");
    }
  }
}
</script>

<style>

</style>