<template>
  <v-card rounded="lg">
    <v-container pa-0>
      <v-card-title class="h3 pb-0 pt-2">Detected systems </v-card-title>
      <v-card-text>
        <v-container pa-0>
          <v-row
            dense
            v-for="drone in detectedDroneList"
            v-bind:key="drone.systemId"
          >
            <v-card outlined width="350">
              <v-container py-0>
                <v-row justify="space-between" no-gutters>
                  <v-col>
                    <v-card-text>
                      {{ drone.systemId }}
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-card-actions class="pt-2 mt-1">
                      <v-btn @click.stop="addDrone(drone)" small>{{
                        drone.addBtn
                      }}</v-btn>
                      <v-btn
                        v-bind:disabled="!drone.ploted"
                        :loading="drone.loading"
                        @click.stop="selectDrone(drone)"
                        small
                        >{{ drone.selectBtn }}</v-btn
                      >
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            icon
                            @click.stop="focusDrone(drone)"
                            :disabled="!drone.ploted"
                          >
                        <v-icon medium class="mdi mdi-eye-outline"></v-icon
                      ></v-btn>
                        </template>
                        <span>Zoom to Drone</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            icon
                            @click.stop="resetPath(drone)"
                            :disabled="!(selectedDroneId==drone.systemId)"
                          >
                        <v-icon medium class="mdi mdi-eraser"></v-icon
                      ></v-btn>
                        </template>
                        <span>Clear drone path</span>
                      </v-tooltip>
                    
                      <div v-if="carModeEnabled">
                        <v-btn
                          text
                          icon
                          @click.stop="toggleCar(drone)"
                          :disabled="!drone.ploted || !carModeEnabled"
                        >
                          <v-icon
                            v-if="!drone.car"
                            medium
                            class="mdi mdi-car"
                          ></v-icon>
                          <v-icon
                            v-else
                            medium
                            class="mdi mdi-car-off"
                          ></v-icon>
                        </v-btn>
                      </div>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
        </v-container>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
//v-if="detectedDroneList.lenght == 0"
import { mapGetters } from "vuex";
import { view, map, spatialReference } from "../esriAPI/EsriMap";
import Point from "@arcgis/core/geometry/Point";
import { getDroneGraphic, resetPathGraphic } from "../esriAPI/ManageDroneLayer";
import { Extent } from '@arcgis/core/geometry';

export default {
  methods: {
    addDrone(drone) {
      this.$store.dispatch("addedDroneObject/addDrone", drone.systemId);
      this.$store.dispatch("detectedDroneList/toggleDroneAddition", drone);
    },
    selectDrone(drone) {
      this.$store.dispatch(
        "addedDroneObject/changeSelectedDrone",
        drone.systemId
      );
      this.$store.dispatch("detectedDroneList/toggleDroneSelection", drone);
    },
    focusDrone(drone) {
      if (drone) {
        let point = new Point();
        point.copy(getDroneGraphic(drone.systemId).geometry);
        point.z = point.z + 75;
        point.y = point.y- 125;

        const extent = new Extent({
          xmax: point.x + 20, xmin: point.x - 20,
          ymax: point.y + 20, ymin: point.y - 20,
          spatialReference: spatialReference
        });

        map.ground
          .load()
          .then(function () {
            return view.map.ground.createElevationSampler(extent);
          })
          .then(function (elevationSampler) {
            let zEnrichedGeometry = elevationSampler.queryElevation(point);
            point.z +=  zEnrichedGeometry.z;
            view.goTo({ position: point, heading: 0, tilt: 50 });
          });
      }
    },
    resetPath(drone){
      resetPathGraphic(drone);
    },

    toggleCar(drone) {
      this.$store.dispatch("detectedDroneList/toggleCar", drone);
    },
  },
  computed: mapGetters({
    detectedDroneList: "detectedDroneList/get",
    carModeEnabled: "detectedDroneList/getCarModeEnabled",
    selectedDroneId: "addedDroneObject/getSelectedDroneId",
  }),
};
</script>
