import ArcGISMap from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import Track from "@arcgis/core/widgets/Track";
import TimeSlider from "@arcgis/core/widgets/TimeSlider";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { Point, SpatialReference } from "@arcgis/core/geometry";
import { vuexStore } from "@/main";
import { MissionData } from "../models/MissionData";
import { clearDrone, clearPath, initializePath, updatePath } from "./path";
import TimeExtent from "@arcgis/core/TimeExtent";
import { clearRad, initializeRadiation, placeRadiationSource, radDetectionLayer, radLayerView } from "./RadiationManager";
import FeatureEffect from "@arcgis/core/layers/support/FeatureEffect";
import FeatureFilter from "@arcgis/core/layers/support/FeatureFilter";

const BASE_SPEED = 250;


export let map: ArcGISMap;
export let view: SceneView;
export let droneLayer: GraphicsLayer;
export let droneModelLayer: GraphicsLayer;
export let dronePathsLayer: GraphicsLayer;
export let pathProjectionLayer: GraphicsLayer;
export let sensorRayLayer: GraphicsLayer;
export let radSourceLayer: GraphicsLayer;

export const spatialReference: SpatialReference = new SpatialReference({
  wkid: 102100,
});

export let timeSlider: TimeSlider;

//initialize map layers and widgets
export function initializeMap() {
  map = new ArcGISMap({
    basemap: "satellite",
    ground: "world-elevation",
  });


  view = new SceneView({
    container: "viewDiv",
    map: map,
    spatialReference: spatialReference,
    camera: {
      heading: 0,
      position: {
        // observation point
        latitude: 41.28,
        longitude: 1.33,
        z: 1000, // zoom level
      },
      tilt: 50, // perspective in degrees
    },
  });

  const track = new Track({
    view: view,
  });

  droneLayer = new GraphicsLayer({ listMode: "hide" });
  droneModelLayer = new GraphicsLayer({ title: "Drones", listMode: "show" });
  dronePathsLayer = new GraphicsLayer({ title: "Drone Paths", listMode: "show" });
  pathProjectionLayer = new GraphicsLayer({ title: "Path Projection", listMode: "show" });
  sensorRayLayer = new GraphicsLayer({ title: "Sensor Ray", listMode: "show" });
  radSourceLayer = new GraphicsLayer({ title: "Radiation Source", listMode: "show" });

  droneModelLayer.elevationInfo = { mode: "relative-to-ground" };
  dronePathsLayer.elevationInfo = { mode: "relative-to-ground" };
  pathProjectionLayer.elevationInfo = { mode: "on-the-ground" };
  sensorRayLayer.elevationInfo = { mode: "relative-to-ground" };
  radSourceLayer.elevationInfo = { mode: "on-the-ground" };

  map.addMany([droneLayer, droneModelLayer, dronePathsLayer, pathProjectionLayer, sensorRayLayer, radSourceLayer]);

  view.ui.add(track, "top-left");
}


export function addLayerToMap(layer: any) {
  map.add(layer);
}

export function activateTimeSlider() {
  const missionData: MissionData = vuexStore.getters["missionPlayer/getMissionData"];

  initializePath(missionData);

  if (missionData.radiationSources) {
    missionData.radiationSources.forEach((source: any) => {
      placeRadiationSource(source.id, source.latitude, source.longitude);
    });
  }

  const numStops =
    (missionData.telemetryList[missionData.telemetryList.length - 1].timestamp
      - missionData.telemetryList[0].timestamp) * 10 / 1000;
  console.log(numStops);


  timeSlider = new TimeSlider({
    container: "timeSliderDiv",
    fullTimeExtent: {
      start: new Date(missionData.telemetryList[0].timestamp),
      end: new Date(missionData.telemetryList[missionData.telemetryList.length - 1].timestamp),
    },
    mode: "cumulative-from-start",
    // actions:  [{id, icon, title}]
    view: view,
    stops: {
      count: numStops < 10000 ? numStops : 10000,
    },
    playRate: BASE_SPEED,
    labelFormatFunction: (value: Date | Date[], type, element: HTMLElement | undefined, layout) => {
      switch (type) {
        case "min":
        case "max":
          if (!Array.isArray(value)) {
            element!.innerText = value.getHours().toString().padStart(2, '0') + ":" +
              value.getMinutes().toString().padStart(2, '0') + ":" +
              value.getSeconds().toString().padStart(2, '0');
          }
          break;
        case "extent":
          if (Array.isArray(value)) {
            element!.innerText = value[1].getHours().toString().padStart(2, '0') + ":" +
              value[1].getMinutes().toString().padStart(2, '0') + ":" +
              value[1].getSeconds().toString().padStart(2, '0');
          }
          break;
      }
    }
  });

  view.ui.add(timeSlider, "bottom-left");
  initializeRadiation(missionData.telemetryList);

  view.watch("timeExtent", (timeExtent: TimeExtent) => {
    vuexStore.dispatch("missionPlayer/setCurrentTime", timeExtent.end.getTime());

    radDetectionLayer.featureEffect = new FeatureEffect({
      filter: new FeatureFilter({
        timeExtent: timeSlider.timeExtent,
        geometry: view.extent
      }),
      excludedEffect: "opacity(0%)"
    });
  });
}

export function focusOn(point: Point) {
  map.ground
    .load()
    .then(function () {
      return view.map.ground.createElevationSampler(view.extent);
    })
    .then(function (elevationSampler) {
      const zEnrichedGeometry = elevationSampler.queryElevation(point) as Point;
      point.z += zEnrichedGeometry.z;
      view.goTo({ position: point, heading: 0, tilt: 50 });
    });
}

export function clearMap() {
  timeSlider.stop();
  view.ui.remove(timeSlider);
  clearPath();
  clearDrone();
  clearRad();
}

export function setSliderSpeed(factor: number) {
  timeSlider.playRate = BASE_SPEED / factor;
}