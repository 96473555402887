import { vuexStore } from "../main";
import ArcGISMap from "@arcgis/core/Map";
import SceneView from "@arcgis/core/views/SceneView";
import BasemapToggle from "@arcgis/core/widgets/BasemapToggle";
import Track from "@arcgis/core/widgets/Track";
import Search from "@arcgis/core/widgets/Search";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";

import {
  selectDrone,
  unSelectDrone,
  updatePostitions,
  setViewWatcher,
  updateHomeGraphic,
  removeHomeGraphic,
} from "./ManageDroneLayer";

import { removeGoToGraphic, updateGoToGraphic } from "./ManageDroneLayer";

import { initializeFPcreator } from "./FlightPlanManager/FlightPlanEditor";
import { SpatialReference } from "@arcgis/core/geometry";
import { initializeAreaCreator } from "./AreaManager/AreaEditor";
import { Drone, DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { hasProto } from "vue-class-component/lib/util";
import { hasNumericValueDependencies } from "mathjs";
import Graphic from "@arcgis/core/Graphic";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { initializeRadiation } from "./RadiationManager";
import Color from "@arcgis/core/Color";


export let map: ArcGISMap;
export let view: SceneView;
export let droneLayer: GraphicsLayer;
export let droneModelLayer: GraphicsLayer;
export let dronePathsLayer: GraphicsLayer;
export let layerFP: GraphicsLayer;
export let layerFP2: GraphicsLayer;
export let areaLayer: GraphicsLayer;
export let footprintLayer: GraphicsLayer;
export let radLayer: GraphicsLayer;
export let radSourceLayer: GraphicsLayer;
export let areaLabels: GraphicsLayer;
export let waypointLabels: GraphicsLayer;
export let rayLayer: GraphicsLayer;


export const spatialReference: SpatialReference = new SpatialReference({
  wkid: 102100,
});

export let searchWidget: Search;

//initialize map layers and widgets
export function initializeMap() {
  map = new ArcGISMap({
    basemap: "satellite",
    ground: "world-elevation",
  });


  view = new SceneView({
    container: "viewDiv",
    map: map,
    spatialReference: spatialReference,
    camera: {
      heading: 0,
      position: {
        // observation point
        latitude: 41.2748,
        longitude: 1.988,
        z: 400, // zoom level
      },
      tilt: 20, // perspective in degrees
    },
  });

  const basemapToggle = new BasemapToggle({
    view: view,
    nextBasemap: "topo-vector",
    container: document.getElementById("change-cartography")!,
  });

  const track = new Track({
    view: view,
  });

  newSearch();

  droneLayer = new GraphicsLayer({ listMode: "hide" });
  droneModelLayer = new GraphicsLayer({ title: "Drones", listMode: "show" });


  dronePathsLayer = new GraphicsLayer({ title: "Drone Paths", listMode: "show" });
  layerFP = new GraphicsLayer({ title: "Flight Plan", listMode: "show" });
  layerFP2 = new GraphicsLayer({ listMode: "hide" });
  areaLayer = new GraphicsLayer({ title: "Area", listMode: "show" });
  areaLabels = new GraphicsLayer({ title: "Area Labels", listMode: "show" });
  waypointLabels = new GraphicsLayer({ title: "Waypoint Labels", listMode: "show" });
  footprintLayer = new GraphicsLayer({ title: "Flight Plan FootPrint", listMode: "show" });
  radLayer = new GraphicsLayer({ title: "Radiation Source (Simulated)", listMode: "hide" });
  radSourceLayer = new GraphicsLayer({ title: "Radiation Source", listMode: "show" });
  rayLayer = new GraphicsLayer({ title: "Drone LOS Ray", listMode: "hide" });

  layerFP.elevationInfo = { mode: "relative-to-ground" };
  waypointLabels.elevationInfo = { mode: "relative-to-ground" };
  layerFP2.elevationInfo = { mode: "relative-to-ground" };

  areaLayer.elevationInfo = { mode: "on-the-ground" };
  footprintLayer.elevationInfo = { mode: "on-the-ground" };
  radLayer.elevationInfo = { mode: "on-the-ground" };
  radSourceLayer.elevationInfo = { mode: "on-the-ground" };
  droneModelLayer.elevationInfo = { mode: "relative-to-ground" };
  dronePathsLayer.elevationInfo = { mode: "relative-to-ground" };
  rayLayer.elevationInfo = { mode: "relative-to-ground" };


  initializeFPcreator();
  initializeAreaCreator();
  initializeRadiation();

  map.addMany([droneLayer, droneModelLayer, dronePathsLayer,
    layerFP2, waypointLabels, layerFP, areaLayer, areaLabels,
    footprintLayer, radLayer, radSourceLayer, rayLayer]);

  view.ui.add(track, "top-left");

  const color = new Color("rgba(0, 255, 0, 0)"); //disabling all highlight since they interfere with labels
  view.highlightOptions = { color: color }

  setViewWatcher();

  setInterval(function () {
    updateMap();
  }, 250);
}

export function newSearch() {
  searchWidget = new Search({
    view: view,
    container: document.getElementById("searchDiv")!,
  });
}

export function updateMap() {
  //update drone's positions
  const addedDroneObject: Map<string, Drone> =
    vuexStore.getters["addedDroneObject/getAddedDroneObject"];
  if (addedDroneObject.size !== 0) updatePostitions(addedDroneObject);
}

export function addLayerToMap(layer: any) {
  map.add(layer);
}

export function updateSelectedDrone(
  selectedDroneId: string | null,
  oldDroneId: string | null
) {
  const addedDroneObject: Map<string, Drone> =
    vuexStore.getters["addedDroneObject/getAddedDroneObject"];

  //change graphics
  if (selectedDroneId != null && selectedDroneId != DUMMY_DRONE_ID)
    selectDrone(addedDroneObject.get(selectedDroneId)!);
  if (oldDroneId != null) unSelectDrone(oldDroneId);

  //change displayed fp and area
  if (selectedDroneId && selectedDroneId != DUMMY_DRONE_ID && addedDroneObject.get(selectedDroneId)!.flightPlan) {
    vuexStore.dispatch("state/setDisplayedFlightPlan", {
      systemId: selectedDroneId,
      flightPlan: addedDroneObject.get(selectedDroneId)!.flightPlan,
    });
  } else {
    vuexStore.dispatch("state/removeDisplayedFlightPlan");
  }
  updateGoto();
  updateHome();
}

function updateGoto() {
  const selectedDrone = vuexStore.getters["addedDroneObject/getSelectedDrone"];

  if (selectedDrone) {
    const goToWP = selectedDrone.goToWP;
    if (
      goToWP.latitude == null ||
      goToWP.longitude == null ||
      goToWP.altitude == null
    ) {
      removeGoToGraphic();
    } else {
      updateGoToGraphic(goToWP);
    }
  } else {
    removeGoToGraphic();
  }
}

function updateHome() {
  const selectedDrone: Drone =
    vuexStore.getters["addedDroneObject/getSelectedDrone"];
  if (selectedDrone) {
    const home = selectedDrone.home;
    if (home.altitude || home.latitude || home.longitude) {
      updateHomeGraphic(home);
    } else {
      removeHomeGraphic();
    }
  } else {
    removeHomeGraphic();
  }
}

export function goToPromise(systemId: string) {
  if (systemId === vuexStore.getters["addedDroneObject/getSelectedDroneId"]) {
    const prom = new Promise(function (resolve) {
      const goToWP =
        vuexStore.getters["addedDroneObject/getSelectedDrone"].goToWP;
      const interval = setInterval(function () {
        const newGoToWP =
          vuexStore.getters["addedDroneObject/getSelectedDrone"].goToWP;
        if (JSON.stringify(goToWP) !== JSON.stringify(newGoToWP)) {
          clearInterval(interval);
          resolve(undefined);
        }
      }, 500);
    });
    prom.then(() => updateGoto());
  }
}

export function homePromise(systemId: string) {
  if (systemId === vuexStore.getters["addedDroneObject/getSelectedDroneId"]) {
    const prom = new Promise(function (resolve) {
      const home = vuexStore.getters["addedDroneObject/getSelectedDrone"].home;
      const interval = setInterval(function () {
        const newHome =
          vuexStore.getters["addedDroneObject/getSelectedDrone"].home;
        if (JSON.stringify(home) !== JSON.stringify(newHome)) {
          clearInterval(interval);
          resolve(undefined);
        }
      }, 1000);
    });
    prom.then(() => updateHome());
  }
}
