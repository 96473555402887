import { SocketMessageTypes, WebSocketMessage } from "@/utils/WebsocketProtocol";
import { sendWebSocketMessage } from "@/websocket/websocket";
import axios from "axios";
import { MissionItem } from "./models/MissionItem";

export const MissionPlayerProtocolTypes = {
  START_RECORDING: "START_RECORDING",
  STOP_RECORDING: "STOP_RECORDING",
  REQUEST_MISSION_DATA: "REQUEST_MISSION_DATA",
  RADIATION_SOURCE: "RADIATION_SOURCE",
  CLEAR_SOURCES: "CLEAR_SOURCES",
  GET_SOURCES: "GET_SOURCES",
};

export async function getMissionList() {
  const loc =
    process.env.NODE_ENV === "production"
      ? "https://rima2.ac.upc.edu:8443"
      : window.location.origin;

  const response = await axios.get(
    loc + `/mission_player/mission_list`
  );
  return response.data.missionList;
}

export let isRecording = false;

export async function requestMissionData(mission: MissionItem) {
  const loc =
    process.env.NODE_ENV === "production"
      ? "https://rima2.ac.upc.edu:8443"
      : window.location.origin;

  const response = await axios.get(
    loc + `/mission_player/mission_data/${mission.missionId}`
  );
  return response.data;
}

export function sendStartRecording(missionId: string, systemId: string) {
  sendWebSocketMessage(
    new WebSocketMessage(
      SocketMessageTypes.MISSION_PLAYER_PROTOCOL,
      {
        type: MissionPlayerProtocolTypes.START_RECORDING,
        systemId,
        missionId,
      }
    )
  );
  isRecording = true;
}

export function sendStopRecording(systemId: string) {
  sendWebSocketMessage(
    new WebSocketMessage(
      SocketMessageTypes.MISSION_PLAYER_PROTOCOL,
      {
        type: MissionPlayerProtocolTypes.STOP_RECORDING,
        systemId,
      }
    )
  );
  isRecording = false;
  //console.log(telemetry_num);
}

export function sendRadiationSource(id: string, latitude: number, longitude: number) {
  sendWebSocketMessage(
    new WebSocketMessage(
      SocketMessageTypes.MISSION_PLAYER_PROTOCOL,
      {
        type: MissionPlayerProtocolTypes.RADIATION_SOURCE,
        id,
        latitude,
        longitude
      }
    )
  );
}

export function sendClearSources() {
  sendWebSocketMessage(
    new WebSocketMessage(
      SocketMessageTypes.MISSION_PLAYER_PROTOCOL,
      {
        type: MissionPlayerProtocolTypes.CLEAR_SOURCES,
      }
    )
  );
}