<template>
  <v-overlay absolute :value="popOverlay" opacity="0.8">
    <v-card>
      <v-tabs
        v-model="tab"
        background-color="grey darken-4"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Parameters
          <v-icon>mdi-tune</v-icon>
        </v-tab>

        <v-tab href="#tab-2">
          Scan-Mode
          <v-icon>mdi-magnify-scan</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1" :eager="true">
          <FPsettingsParams ref="Params" />
        </v-tab-item>
        <v-tab-item value="tab-2" :eager="true">
          <FPsettingsScan ref="Scan" />
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <v-btn color="success" class="ml-10 my-5" @click="submit">
          Compute
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" class="mr-10" @click.stop="overlay">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-overlay>
</template>

<script>
import { FPTypes } from "../../utils/FPDataModels";

import FPsettingsParams from "./FPsettingsParams";
import FPsettingsScan from "./FPsettingsScan";
import {
  setFPSettingsSocket,
  SocketMessageTypes,
  WebSocketMessage,
} from "../../utils/WebsocketProtocol";
import { sendWebSocketMessage } from "@/websocket/websocket";
import { mapGetters } from "vuex";
import { FlightPlanProtocolTypes } from "../../utils/FlightPlanProtocol";
import { DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { mapActions } from "vuex";
import { checkAreaExists } from '@/esriAPI/AreaManager/AreaUtils';

export default {
  components: {
    FPsettingsParams,
    FPsettingsScan,
  },
  computed: mapGetters({
    selectedDrone: "addedDroneObject/getSelectedDrone",
  }),

  mounted() {
    setFPSettingsSocket(this);
  },

  methods: {
    submit() {
      if (!checkAreaExists(true)) {
        this.overlay();
      } else {
        let paramsObject = this.$refs.Params;
        let scanObject = this.$refs.Scan;

        let scanSettings;
        if (scanObject.scanMode.value == FPTypes.BACK_AND_FORTH) {
          scanSettings = {
            exitCourse: scanObject.checkboxExitCourse,
            heading: scanObject.heading,
            width: scanObject.width,
            offset: scanObject.offset,
            optimization: scanObject.optimization.value,
            entryDirection: scanObject.entryDirection.value,
            exitDirection: scanObject.exitDirection.value,
          };
        } else if (scanObject.scanMode.value == FPTypes.SMART_RANDOM) {
          scanSettings = {
            passes: scanObject.passes,
            generations: scanObject.generations,
            time: scanObject.time,
          };
        } else {
          scanSettings = {
            passes: scanObject.passes,
          };
        }
        let data = {
          flightPlanParameters: {
            altitude:
              paramsObject.altitude == 0 ? 0.00001 : paramsObject.altitude,
            speed: paramsObject.speed,
            sensorRadius: paramsObject.radius,
            endMission: paramsObject.endMission.value,
            scanMode: scanObject.scanMode.value,
            scanSettings: scanSettings,
          },
          systemId: this.selectedDrone
            ? this.selectedDrone.systemId
            : DUMMY_DRONE_ID,
          type: FlightPlanProtocolTypes.COMPUTED_FP,
        };

        this.setSensorRadius(paramsObject.radius);
        this.setSensorFOV(paramsObject.fov);

        sendWebSocketMessage(
          new WebSocketMessage(SocketMessageTypes.FLIGHT_PLAN_PROTOCOL, data)
        );
        this.overlay();
      }
    },
    overlay() {
      this.popOverlay = !this.popOverlay;
    },
    ...mapActions({
      setSensorRadius: "addedDroneObject/setSensorRadius",
      setSensorFOV: "addedDroneObject/setSensorFOV"
    }),
  },
  data() {
    return {
      tab: "tab-1",
      popOverlay: false,
    };
  },
};
</script>


