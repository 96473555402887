import { vuexStore } from "@/main";
import { Drone, DUMMY_DRONE_ID } from "@/utils/DroneDataModel";
import { addDrone, deleteDrone, newRadPoint } from "@/esriAPI/ManageDroneLayer";
import { updateMap, updateSelectedDrone } from "@/esriAPI/EsriMap";
import {
  WebSocketMessage,
  SocketMessageTypes,
} from "@/utils/WebsocketProtocol";
import { Point } from "@arcgis/core/geometry";

import { sendWebSocketMessage } from "@/websocket/websocket";

interface AddedDroneObjectState {
  addedDroneObject: Map<string, Drone>;
  selectedDrone: Drone | null;
  selectedDroneId: string | null;
  sensorRadius: number | null;
  sensorFOV: number | null;
  radPoints: Point[];
  recording: boolean;
  recordingStart: Date | null;
}

const state: AddedDroneObjectState = {
  addedDroneObject: new Map<string, Drone>(),

  selectedDrone: null,
  selectedDroneId: null,

  sensorRadius: 1,
  sensorFOV: 2,
  radPoints: [],
  recording: false,
  recordingStart: null,
};

const getters = {
  getAddedDroneObject: (state: AddedDroneObjectState) => {
    return state.addedDroneObject;
  },

  getSelectedDrone: (state: AddedDroneObjectState) => {
    return state.selectedDrone;
  },
  getSelectedDroneId: (state: AddedDroneObjectState) => {
    return state.selectedDroneId;
  },
  getSensorRadius: (state: AddedDroneObjectState) => {
    return state.sensorRadius;
  },
  getSensorFOV: (state: AddedDroneObjectState) => {
    return state.sensorFOV;
  },
  getRadPoint: (state: AddedDroneObjectState) => {
    return state.radPoints;
  },
  getRecording: (state: AddedDroneObjectState) => {
    return state.recording;
  },
  getRecordingStart: (state: AddedDroneObjectState) => {
    return state.recordingStart;
  }
};

const mutations = {
  // ADD_DRONE(state: AddedDroneObjectState, drone: Drone) {},

  REMOVE_DRONE(state: AddedDroneObjectState, droneId: string) {
    state.addedDroneObject.delete(droneId);

    if (state.selectedDrone && state.selectedDrone.systemId === droneId) {
      state.selectedDrone = null;
      state.selectedDroneId = null;
    }
  },

  UPDATE_DRONE(state: AddedDroneObjectState, drone: Drone) {
    if (state.addedDroneObject.has(drone.systemId)) {
      state.addedDroneObject.set(drone.systemId, drone);
      if (state.selectedDrone != null) {
        if (drone.systemId === state.selectedDrone.systemId) {
          state.selectedDrone = drone;
          newRadPoint(state.selectedDrone);
        }
      }
    } else {
      state.addedDroneObject.set(drone.systemId, drone);
      addDrone(drone);
      vuexStore.dispatch("detectedDroneList/setPloted", drone.systemId);
    }
  },

  CHANGE_SELECTED_DRONE(state: AddedDroneObjectState, droneId: string) {
    state.selectedDrone = state.addedDroneObject.get(droneId)!;
    state.selectedDroneId = droneId;
  },

  UNSELECT_DRONE(state: AddedDroneObjectState) {
    state.selectedDrone = null;
    state.selectedDroneId = null;
  },

  UPDATE_SENSOR_RADIUS(state: AddedDroneObjectState, radius: number) {
    state.sensorRadius = radius;
  },
  UPDATE_SENSOR_FOV(state: AddedDroneObjectState, fov: number) {
    state.sensorFOV = fov;
  },
  UPDATE_RAD_POINTS(state: AddedDroneObjectState, radPoints: Point[]) {
    state.radPoints = radPoints;
  },
  START_RECORDING(state: AddedDroneObjectState, date: Date) {
    state.recording = true;
    state.recordingStart = date;
  },
  STOP_RECORDING(state: AddedDroneObjectState) {
    state.recording = false;
  }

};

const actions = {
  addDrone(context: any, droneId: string) {
    if (context.state.addedDroneObject.has(droneId)) {
      if (context.state.selectedDroneId === droneId) {
        context.dispatch("changeSelectedDrone", droneId);
      }
      context.dispatch("removeDrone", droneId);
    } else {
      const subscribeMsg = new WebSocketMessage(
        SocketMessageTypes.TELEMETRY_SENDER_PROTOCOL,
        { type: "subscribe", systemId: droneId }
      );
      sendWebSocketMessage(subscribeMsg);
    }
  },

  removeDrone(context: any, droneId: string) {
    if (context.state.addedDroneObject.has(droneId)) {
      const subscribeMsg = new WebSocketMessage(
        SocketMessageTypes.TELEMETRY_SENDER_PROTOCOL,
        { type: "unsubscribe", systemId: droneId }
      );
      sendWebSocketMessage(subscribeMsg);
      context.commit("REMOVE_DRONE", droneId);
      deleteDrone(droneId);
    }
  },

  changeSelectedDrone(context: any, droneId: string) {
    if (
      context.state.selectedDrone != null &&
      context.state.selectedDrone.systemId === droneId
    ) {
      const previousSelectedDroneId = context.getters["getSelectedDroneId"];
      console.log({ prevSelDroneId: previousSelectedDroneId });
      context.commit("UNSELECT_DRONE");
      updateSelectedDrone(DUMMY_DRONE_ID, previousSelectedDroneId);
    } else {
      const previousSelectedDroneId = context.getters["getSelectedDroneId"];
      context.commit("CHANGE_SELECTED_DRONE", droneId);
      updateSelectedDrone(droneId, previousSelectedDroneId);
    }
    updateMap();
  },

  setTelemetry(context: any, data: Drone) {
    context.commit("UPDATE_DRONE", new Drone(data));
  },

  sendAllSubscriptions(context: any) {
    const addedDroneObject: Map<string, Drone> =
      context.getters["getAddedDroneObject"];

    addedDroneObject.forEach((drone: Drone) => {
      const subscribeMsg = new WebSocketMessage(
        SocketMessageTypes.TELEMETRY_SENDER_PROTOCOL,
        { type: "subscribe", systemId: drone.systemId }
      );
      sendWebSocketMessage(subscribeMsg);
    });
  },

  setSensorRadius(context: any, radius: number) {
    context.commit("UPDATE_SENSOR_RADIUS", radius);
  },
  setSensorFOV(context: any, fov: number) {
    context.commit("UPDATE_SENSOR_FOV", fov);
  },
  setRadPoint(context: any, radPoints: Point[]) {
    context.commit("UPDATE_RAD_POINTS", radPoints);
  },

  startRecording(context: any, date: Date) {
    context.commit("START_RECORDING", date);
  },
  stopRecording(context: any) {
    context.commit("STOP_RECORDING");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
