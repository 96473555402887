import { Geometry, Point, Polygon, Polyline } from "@arcgis/core/geometry";
import Circle from "@arcgis/core/geometry/Circle";
import { buffer, union } from "@arcgis/core/geometry/geometryEngineAsync";
import Graphic from "@arcgis/core/Graphic";
import { vuexStore } from "../../main";
import {
  footprintLayer,
  spatialReference
} from "../EsriMap";

const footprintSymbol: any = {
  type: "simple-fill",
  color: [0, 153, 255, 0.4],
  outline: {
    color: [0, 153, 255],
    width: 2,
  },
};

//Foot print of FP
export async function plotScanfootPrint(polyline: Polyline) {
  const coneSlope = Math.tan(0.5 * (vuexStore.getters["addedDroneObject/getSensorFOV"] * Math.PI) / 180.0);
  const minZ = 0.01 / coneSlope;
  const buffers: Promise<Geometry>[] = [];
  for (let i = 0; i < polyline.paths[0].length - 1; i++) {
    let point = polyline.getPoint(0, i);
    let lastPoint = polyline.getPoint(0, i + 1);
    if (point.z !== lastPoint.z) {
      // Start from lower point
      if (lastPoint.z < point.z) {
        const tmp = point;
        point = lastPoint;
        lastPoint = tmp;
      }
      if (lastPoint.z < minZ) continue;

      const Dz = lastPoint.z - point.z;
      const Dx = lastPoint.x - point.x;
      const Dy = lastPoint.y - point.y;
      const Dh = Math.sqrt(Dx * Dx + Dy * Dy);
      const alpha = Math.tan(Dz / Dh);
      const beta = Math.atan2(Dy, Dx);
      const tanA = Math.tan(alpha);
      const cosB = Math.cos(beta);
      const sinB = Math.sin(beta);

      if (point.z < minZ) {
        const dz = minZ - point.z;
        const dh = dz / tanA;
        const dx = dh * cosB;
        const dy = dh * sinB;

        point.x += dx;
        point.y += dy;
        point.z += dz;
      }

      const radius1 = point.z * coneSlope;
      const radius2 = lastPoint.z * coneSlope;
      const polygon = new Polygon({
        hasZ: false,
        rings: [[
          [point.x + radius1 * sinB, point.y - radius1 * cosB],
          [point.x - radius1 * sinB, point.y + radius1 * cosB],
          [lastPoint.x - radius2 * sinB, lastPoint.y + radius2 * cosB],
          [lastPoint.x + radius2 * sinB, lastPoint.y - radius2 * cosB],
        ]],
        spatialReference: spatialReference,
      });
      const circle1 = new Circle({
        center: new Point({
          hasZ: false,
          x: point.x,
          y: point.y,
          spatialReference: spatialReference,
        }),
        radius: radius1,
        radiusUnit: "meters",
        spatialReference: spatialReference,
      }) as Polygon;
      const circle2 = new Circle({
        center: new Point({
          hasZ: false,
          x: lastPoint.x,
          y: lastPoint.y,
          spatialReference: spatialReference,
        }),
        radius: radius2,
        radiusUnit: "meters",
        spatialReference: spatialReference,
      }) as Polygon;

      buffers.push(union([polygon, circle1, circle2]) as Promise<Geometry>);
    } else {
      const segment = new Polyline({
        hasZ: true,
        paths: [polyline.paths[0].slice(i, i + 2)],
        spatialReference: spatialReference,
      });
      if (point.z > minZ) {
        const radius = coneSlope * point.z;
        buffers.push(buffer(segment, radius, "meters") as Promise<Geometry>);
      }
    }
  }

  Promise.all(buffers).then(async (areas) => {
    const geometry = await union(areas);

    const buffGx = new Graphic({
      geometry: geometry,
      symbol: footprintSymbol,
    });

    footprintLayer.add(buffGx);
  });
}


