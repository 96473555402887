<template>
  <div class="container">
    <div id="viewDiv"></div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { initializeMap } from "@/mission_player/esri/esriMap";
export default {
  name: "web-map",

  mounted() {
    initializeMap();
  },
  beforeDestroy() {
    this.unselectMission();
    
    if (this.view) {
      // destroy the map view
      console.log("VIEW");
      this.view.map = null;
      this.view.container = null;
      this.view.destroy();
    }
  },
  methods: {
    ...mapActions({
      unselectMission: "missionPlayer/unselectMission",
    }),
  }
};
</script>

<style scoped>
#viewDiv {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
