import { vuexStore } from "../main";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import { addLayerToMap, radSourceLayer, rayLayer, spatialReference } from "./EsriMap";
import { sendWebSocketMessage } from "@/websocket/websocket";
import { SocketMessageTypes, WebSocketMessage } from "@/utils/WebsocketProtocol";
import { MissionPlayerProtocolTypes } from "@/mission_player/protocol";
import { Point } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";

export const DEFAULT_THRESH1 = 25;
export const DEFAULT_THRESH2 = 5000;

export const SENSOR_HEIGHT = 0.6;

export const DEFAULT_RAD = 0;
const radTypes = ["radiationCPS", "radiationCPSN"]


let currentThresh1 = DEFAULT_THRESH1;
let currentThresh2 = DEFAULT_THRESH2;
let currentRad = DEFAULT_RAD;

let radDetectionLayer: any;

export function updateRadPlot(newGeom: any) {
  radDetectionLayer.listMode = "show";
  rayLayer.listMode = "show";

  radDetectionLayer.applyEdits({
    addFeatures: [newGeom],
  });
}

export function removeRadPlot() {
  radDetectionLayer.features = [];
  radDetectionLayer.listMode = "hide";
  radDetectionLayer.visible = false;

}

export function changeThresholdRange(newRange: number[]) {
  const newRenderer = radDetectionLayer.renderer.clone();

  currentThresh1 = newRange[0];
  currentThresh2 = newRange[1];

  newRenderer.visualVariables =
    [{
      type: "color",
      field: radTypes[currentRad],
      stops: [
        {
          value: newRange[0],
          color: "yellow",
        },
        {
          value: newRange[1],
          color: "red",
        }
      ]
    },
    {
      type: "size",
      field: radTypes[currentRad],
      stops: [
        {
          value: newRange[0],
          size: 1,
        },
        {
          value: newRange[1],
          size: 3,
        }
      ],
      axis: "height"
    },
    {
      type: "size",
      field: "relativeWidth",
      axis: "width-and-depth",
      minDataValue: 0.1,
      maxDataValue: 20,
      minSize: "0.1",
      maxSize: "20"
    }];

  radDetectionLayer.renderer = newRenderer;
}

export function changeRadiationType(index: number) {
  currentRad = index;
  const newRenderer = radDetectionLayer.renderer.clone();


  newRenderer.visualVariables = [{
    type: "color",
    field: radTypes[index],
    stops: [
      {
        value: currentThresh1,
        color: "yellow",
      },
      {
        value: currentThresh2,
        color: "red",
      }
    ]
  },
  {
    type: "size",
    field: radTypes[index],
    stops: [
      {
        value: currentThresh1,
        size: 1,
      },
      {
        value: currentThresh2,
        size: 3,
      }
    ],
    axis: "height"
  },
  {
    type: "size",
    field: "relativeWidth",
    axis: "width-and-depth",
    minDataValue: 0.1,
    maxDataValue: 20,
    minSize: "0.1",
    maxSize: "20"
  }];

  radDetectionLayer.renderer = newRenderer;
}

export function initializeRadiation() {

  // const features = simulateFeatures();
  const features: any = [];

  const renderer: any = {
    type: "simple",
    symbol: {
      type: "point-3d",
      symbolLayers: [
        {
          width: 0.4,

          type: "object",
          resource: {
            primitive: "cylinder"
          },

        }
      ]
    },
  };

  renderer.visualVariables = [{
    type: "color",
    field: radTypes[DEFAULT_RAD],
    stops: [
      {
        value: DEFAULT_THRESH1,
        color: "yellow",
      },
      {
        value: DEFAULT_THRESH2,
        color: "red",
      }
    ]
  },
  {
    type: "size",
    field: radTypes[DEFAULT_RAD],
    stops: [
      {
        value: DEFAULT_THRESH1,
        size: 1,
      },
      {
        value: DEFAULT_THRESH2,
        size: 3,
      }
    ],
    axis: "height"
  },
  {
    type: "size",
    field: "relativeWidth",
    axis: "width-and-depth",
    minDataValue: 0.1,
    maxDataValue: 20,
    minSize: "0.1",
    maxSize: "20"
  }];



  radDetectionLayer = new FeatureLayer({
    spatialReference: spatialReference,
    source: features,
    title: "Radiation Detected",
    listMode: "hide",
    geometryType: "point",
    hasZ: true,
    renderer: renderer,
    objectIdField: "ObjectID",
    fields: [{
      name: "ObjectID",
      type: "oid"
    }, {
      name: radTypes[0],
      type: "double"
    },
    {
      name: radTypes[1],
      type: "double"
    },
    {
      name: "relativeWidth",
      type: "double"
    }],
  });
  radDetectionLayer.elevationInfo = { mode: "on-the-ground" };


  addLayerToMap(radDetectionLayer);
}

export function placeRadiationSource(id: string, latitude: number, longitude: number) {
  const point = new Point({
    spatialReference: spatialReference,
    latitude: latitude,
    longitude: longitude,
  });
  const symbol = {
    type: "point-3d",
    symbolLayers: [
      {
        type: "object",
        width: 0.5,
        height: 2,
        depth: 0.5,
        resource: { primitive: "cylinder" },
        material: { color: "blue" },
      },
    ],
  };
  const graphic = new Graphic({
    geometry: point,
    // @ts-ignore
    symbol: symbol,
  });
  radSourceLayer.add(graphic);
}


function simulateFeatures() {

  const features = [];
  for (let i = 0; i < 10; i++) {
    features[i] = {
      geometry: {
        type: "point",
        spatialReference: spatialReference,
        hasZ: true,
        latitude: 41.2748 + i * Math.random() / 10000,
        longitude: 1.988 + i * Math.random() / 10000,
        z: 100,

      }, attributes: {
        radiation: 50 + i * 500,
        relativeWidth: 2,
        maxRadiation: 1,
        ObjectID: i,
      }
    };
  }
  return features;

}
