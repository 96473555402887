import router from "@/router";
import { LoginError } from "@/utils/DataModels";
import axios from "axios";

interface AuthState {
  loggedIn: boolean;
  connected: boolean;
  email: string | null;
  roles: string[] | null;
  loginErrors: LoginError[] | null;
}

const state: AuthState = {
  loggedIn: false,
  connected: false,
  email: null,
  roles: null,
  loginErrors: null,
};

const getters = {
  getLoggedIn: (state: AuthState) => {
    return state.loggedIn;
  },
  getConnected: (state: AuthState) => {
    return state.connected;
  },
  getEmail: (state: AuthState) => {
    return state.email;
  },
  getRoles: (state: AuthState) => {
    return state.roles;
  },
  getLoginErrors: (state: AuthState) => {
    return state.loginErrors;
  },
};

const mutations = {
  LOGIN(
    state: AuthState,
    parameters: { email: string, roles: string[] }
  ) {
    const email = parameters.email;
    const roles = parameters.roles;
    state.loggedIn = true;
    state.email = email;
    state.roles = roles;
  },
  LOGOUT(state: AuthState) {
    state.loggedIn = false;
    state.connected = false;
    state.email = null;
    state.roles = null;
  },
  CONNECT(state: AuthState) {
    state.connected = true;
  },
  DISCONNECT(state: AuthState) {
    state.connected = false;
  },
  SET_LOGINERRORS(state: AuthState, loginErrors: LoginError[]) {
    state.loginErrors = loginErrors;
  }
};

const actions = {
  async login(
    context: any,
    parameters: { email: string, password: string }
  ) {
    const errors: LoginError[] = [];
    const email = parameters.email;
    const password = parameters.password;
    const loc =
      process.env.NODE_ENV === "production"
        ? "https://rima2.ac.upc.edu:8443"
        : window.location.origin;

    const response = await axios.post(loc + "/auth/login", {
      email: email,
      password: password,
    });
    if (response.status === 200) {
      if (response.data.roles === undefined) {
        // Messages
        errors.push(new LoginError("warning", response.data.msg));
      } else {
        // Good login
        const roles = response.data.roles.map((role: any) => role.name);
        console.log(roles);
        context.commit("LOGIN", { email, roles });
        if (roles.includes("operator")) {
          router.push("/");
        } else {
          router.push("/missionplayer");
        }
      }
    } else {
      // Errors
      response.data.errors.array.forEach((element: any) => {
        errors.push(new LoginError("error", element));
      });
    }
    if (errors) {
      context.dispatch("setLoginErrors", errors);
    }
  },
  logout(context: any) {
    context.commit("LOGOUT");
  },
  connect(context: any) {
    context.commit("CONNECT");
  },
  disconnect(context: any) {
    context.commit("DISCONNECT");
  },
  setLoginErrors(context: any, loginErrors: LoginError[]) {
    context.commit("SET_LOGINERRORS", loginErrors);
  },
  async recover(context: any) {
    const loc =
      process.env.NODE_ENV === "production"
        ? "https://rima2.ac.upc.edu:8443"
        : window.location.origin;

    await axios.post(loc + "/auth/recover").then((response) => {
      if (response.data.email) {
        // Good recovery
        const email = response.data.email;
        const roles = response.data.roles.map((role: any) => role.name);
        console.log(roles);
        context.commit("LOGIN", { email, roles });
        if (roles.includes("operator")) {
          router.push("/");
        }
        else {
          router.push("/missionplayer");
        }
      }
    });

  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
