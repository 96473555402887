export class FlightPlan {
  id: number;
  name: string;
  description: string;
  waypoints: Waypoint[];
  area: AreaPoint[];
  constructor() {
    this.id = Math.floor(Math.random() * 10000) + 1; //random number between 1 and 10000,
    this.name = "Add a name";
    this.description = "Write a Description";
    this.waypoints = [];
    this.area = [];
  }
}

export class Waypoint {
  order: number;
  lat: number;
  lon: number;
  speed: number;
  altitude: number;
  relativeAltitude: boolean;
  flyOver: boolean;
  stage: string;
  actions: Action[];
  constructor(
    order: number,
    lat: number,
    lon: number,
    altitude: number,
    stage: string,
    speed: number,
  ) {
    this.order = order;
    this.lat = lat == 0 ? 0.0000000001 : parseFloat(lat.toFixed(9));
    this.lon = lon == 0 ? 0.0000000001 : parseFloat(lon.toFixed(9));
    this.speed = speed > 0 ? speed : 1;
    this.altitude = altitude == 0 ? 0.0001 : altitude;
    this.relativeAltitude = true;
    this.flyOver = true;
    this.stage = stage == "" ? "-" : stage;
    this.actions = [new Action("default")];
  }
}

export class Action {
  type: string;
  [key: string]: any;

  constructor(type: string) {
    this.type = type;
    this.action = { type: type };
  }
  getObject(): Action {
    return this;
  }
}

export class AreaPoint {
  lat: number;
  lon: number;
  constructor(lat: number, lon: number) {
    this.lat = lat;
    this.lon = lon;
  }
}

export class Alert {
  id: number;
  type: string;
  message: string;
  timeout: ReturnType<typeof setTimeout> | undefined;

  constructor(
    id: number,
    type: string,
    message: string,
    timeout?: ReturnType<typeof setTimeout>
  ) {
    this.id = id;
    this.type = type;
    this.message = message;
    this.timeout = timeout || undefined;
  }
}

export class LoginError {
  type: string;
  message: string;

  constructor(type: string, message: string) {
    this.type = type;
    this.message = message;
  }
}