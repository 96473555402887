<template>
  <div>
    <v-menu
      v-model="openMenu"
      offset-y
      :open-on-click="false"
      transition="slide-y-transition"
      :close-on-content-click="false"
      :close-on-click="false"
      dark
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          @click.stop="createPolyline"
          :disabled="appState == 'FLIGHT_PLAN_CREATION' || appState == 'AREA_CREATION'"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon> mdi-vector-polyline-edit </v-icon>
        </v-btn>
      </template>

      <v-card>
        <span>
          <v-tooltip
            bottom
            v-for="(button, index) in buttons"
            v-bind:key="index"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                icon
                fab
                v-on="on"
                class="ml-1"
                @click.stop="button.method"
                small
              >
                <v-icon>
                  {{ button.icon }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ button.name }}</span>
          </v-tooltip>
        </span>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import {
  createFP,
  saveFP,
  deleteFP,
} from "../../esriAPI/FlightPlanManager/FlightPlanEditor";

import { AppState } from "../../utils/StateUtils";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      buttons: [
        { name: "Save", method: saveFP, icon: "mdi-check" },
        { name: "Remove", method: deleteFP, icon: "mdi-delete" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      appState: "state/getAppState",
    }),
    openMenu: function () {
      return this.appState == "FLIGHT_PLAN_CREATION";
    },
  },
  methods: {
    ...mapActions({
      changeAppState: "state/changeAppstate",
    }),
    createPolyline() {
      this.changeAppState(AppState.FLIGHT_PLAN_CREATION);
      createFP();
      this.$store.dispatch("alert/popAlert", {
        id: 20,
        type: "info",
        message: "Exit Polyline mode by double-clicking or pressing Enter",
      });
    },
  
    savePolyline() {
      saveFP();
    },
   
    deletePolyline() {
      deleteFP();
    },
  },
};
</script>
