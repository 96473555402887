<template>
  <v-data-table
    :headers="headers"
    :items="users"
    sort-by="email"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Users</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <CreateRole />
        <DeleteRole @role-deleted="getUsers" />
        <NewUser ref="newUser" @user-created="getUsers" />
        <ChangeEmail ref="changeEmail" :user="selectedUser" @email-changed="getUsers"/>
        <ChangePassword ref="changePassword" :user="selectedUser" @password-changed="getUsers" />
        <ChangeRoles ref="changeRoles" :user="selectedUser" @roles-changed="getUsers" />
        <DeleteUser ref="deleteUser" :user="selectedUser" @user-deleted="getUsers" />
      </v-toolbar>
    </template>
    <template v-slot:item.roles="{ item }">
      <v-chip
        v-for="(role, index) in item.roles"
        :key="index"
      >
        {{ role.name }}
      </v-chip>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="changeEmail(item)"
      >
        mdi-email
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="changePassword(item)"
      >
        mdi-lock
      </v-icon>
      <v-icon
        small
        class="mr-2"
        @click="changeRoles(item)"
      >
        mdi-pencil-box-multiple
      </v-icon>
      <v-icon
        small
        @click="deleteUser(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn
        color="primary"
        @click="getUsers"
      >
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import axios from 'axios';
import NewUser from './NewUser';
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import ChangeRoles from './ChangeRoles';
import DeleteUser from './DeleteUser';
import CreateRole from './CreateRole';
import DeleteRole from './DeleteRole';

export default {
  name: "UsersPanel",
  components: {
    NewUser,
    ChangeEmail,
    ChangePassword,
    ChangeRoles,
    DeleteUser,
    CreateRole,
    DeleteRole,
  },
  data() {
    return {
      dialogDelete: false,
      headers: [
        {
          text: 'Email',
          align: 'start',
          sortable: true,
          value: 'email',
        },
        {
          text: 'Roles',
          sortable: false,
          value: 'roles',
        },
        {
          text: 'Actions', 
          value: 'actions',
          sortable: false
        },
      ],
      users: [],
      selectedUser: null,
    }
  },
  watch: {
    dialogDelete (val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    getUsers() {
      const loc =
      process.env.NODE_ENV === "production"
        ? "https://rima2.ac.upc.edu:8443"
        : window.location.origin;
    
      axios.get(
        loc + "/auth/all_users"
      ).then((response) => {
        if (response.status !== 200) {
          console.log("Couldn't stablish connection");
          return;
        }

        this.users = response.data.users;
      }).catch((error) => {
        console.log(error);
        return;
      });
    },
    changeEmail(user) {
      this.selectedUser = user;
      this.$refs.changeEmail.dialog = true;
    },
    changePassword(user) {
      this.selectedUser = user;
      this.$refs.changePassword.dialog = true;
    },
    changeRoles(user) {
      this.selectedUser = user;
      this.$refs.changeRoles.dialog = true;
    },
    deleteUser(user) {
      this.selectedUser = user;
      this.$refs.deleteUser.dialog = true;
    },
  },
}
</script>

<style>

</style>