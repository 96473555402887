<template>
  <v-fade-transition>
    <v-card
      width="300"
      rounded="lg"
    >
      <v-container>
        <v-card-title class="h3 pl-1 py-0">
          New Radiation Source
        </v-card-title>
        <v-divider class="pb-2"></v-divider>
        <v-card
          max-height="200"
          class="overflow-y-auto"
          flat
        >
          <v-form v-model="valid" lazy-validation ref="form">
            <div>
              <h4>Position:</h4>

              <v-text-field
                v-model="id"
                class="mt-0 pt-0 centered-input"
              ></v-text-field>

              <v-text-field
                v-model="position"
                suffix="º"
                class="mt-0 pt-0 centered-input"
                :rules="[rules.position]"
                readonly
              ></v-text-field>

              <v-divider class="pb-2"></v-divider>
            </div>
          </v-form>
        </v-card>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            @click.stop="sendCommand"
            >Send</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn small @click.stop="cancelCommand">Cancel</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-fade-transition>
</template>

<script>
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import {
  view,
  droneLayer,
  spatialReference,
  map,
  radSourceLayer,
} from "../../esriAPI/EsriMap";
import { Extent, Point } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import { sendRadiationSource } from '@/mission_player/protocol';

let sketchViewModel = null;
let graphic = null;

function newSketchViewModel(that) {
  sketchViewModel = new SketchViewModel({
    view: view,
    layer: droneLayer,
    pointSymbol: {
      type: "simple-marker",
      style: "circle",
      size: 20,
      color: [255, 255, 0],
      outline: {
        color: [50, 50, 50],
        width: 5,
      },
    },
    defaultCreateOptions: {
      hasZ: false, // default value
    },
    defaultUpdateOptions: {
      enableZ: false,
    },
  });
  sketchViewModel.create("point");

  graphic = null;

  sketchViewModel.on("create", function (event) {
    if (event.state === "complete") {
      setValues(that, event.graphic);
      sketchViewModel.update(graphic);
    }
  });
  sketchViewModel.on("update", function (event) {
    setValues(that, event.graphics[0]);
  });

  sketchViewModel.on("delete", function () {
    sketchViewModel.create("point");
    that.altitude = 0;
    that.latitude = 0;
    that.longitude = 0;
  });
}

function setValues(that, graph) {
  graphic = graph;
  that.latitude = graphic.geometry.latitude;
  that.longitude = graphic.geometry.longitude;
  const point = new Point({
    spatialReference: spatialReference,
    hasZ: true,
    latitude: that.latitude,
    longitude: that.longitude,
    z: 0,
  });
  const extent = new Extent({
    xmax: point.x + 20, xmin: point.x - 20,
    ymax: point.y + 20, ymin: point.y - 20,
    spatialReference: spatialReference
  });

  map.ground
    .load()
    .then(function () {
      // create an elevation sampler from a given extent
      return view.map.ground.createElevationSampler(extent);
    })
    .then(function (elevationSampler) {
      // use the elevation sampler to get z-values for a point, multipoint or polyline geometry
      let zEnrichedGeometry = elevationSampler.queryElevation(point);
      that.altitude = zEnrichedGeometry.z;
      point.x = that.altitude;
    });
}

export default {
  data() {
    return {
      id: "",
      latitude: 0,
      longitude: 0,
      altitude: 0,
      valid: true,
      rules: {
        position: () =>
          (this.longitude != 0 && this.latitude != 0) ||
          "Click map to select a position",
      },
    };
  },
  created() {
    newSketchViewModel(this);
  },
  destroyed() {
    droneLayer.remove(graphic);
    sketchViewModel.destroy();
  },
  computed: {
    position: function () {
      return `${this.latitude}, ${this.longitude}`;
    },
  },
  methods: {
    sendCommand() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const point = new Point({
        spatialReference: spatialReference,
        latitude: this.latitude,
        longitude: this.longitude,
      });
      const symbol = {
        type: "point-3d",
        symbolLayers: [
          {
            type: "object",
            width: 0.5,
            height: 2,
            depth: 0.5,
            resource: { primitive: "cylinder" },
            material: { color: "blue" },
          },
        ],
      };
      const graphic = new Graphic({
        geometry: point,
        symbol: symbol,
      });
      radSourceLayer.add(graphic);

      sendRadiationSource(this.id, this.latitude, this.longitude);
      this.$emit("placed");
    },
    cancelCommand() {
      this.$emit("placed");
    }
  }
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>