import { vuexStore } from "@/main";
import { isProductionelectron } from "@/utils/Electron";
import geometry, { Extent, Point, Polyline } from "@arcgis/core/geometry";
import Graphic from "@arcgis/core/Graphic";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import { MissionData } from "../models/MissionData";
import * as watchUtils from "@arcgis/core/core/watchUtils";
import ObjectSymbol3DLayer from "@arcgis/core/symbols/ObjectSymbol3DLayer";
import PointSymbol3D from "@arcgis/core/symbols/PointSymbol3D";
import { layerFP, layerFP2 } from "@/esriAPI/EsriMap";
import { droneLayer, droneModelLayer, dronePathsLayer, pathProjectionLayer, focusOn, spatialReference, view, sensorRayLayer } from "./esriMap";
import { SENSOR_HEIGHT } from "./RadiationManager";
import LatLon, { Ned } from "geodesy/latlon-nvector-ellipsoidal";

const pathSymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [0, 152, 255],
  width: 3,
});

const pathProjectionSymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [60, 60, 60, 0.5],
  width: 5,
});

const raySymbol: SimpleLineSymbol = new SimpleLineSymbol({
  color: [120, 0, 120],
  width: 3,
});

const selectedDroneSymbol = new PictureMarkerSymbol({
  url: (isProductionelectron() ? "app://" : "") + "./assets/selectedDrone.png",
  width: "64px",
  height: "64px",
});

const droneSymbol3DResource = {
  // href: (isProductionelectron() ? "app://" : "") + "./assets/drone3D.gltf",
  href: (isProductionelectron() ? "app://" : "") + "./assets/drone3DNew.glb",

};

const drone3DModelSize = 1;
const drone3Danchor = -0.2;

const distanceTo3DChange = 400; //distance that graphics will toggle from 2D to 3D in km

let pathPoints: Point[];
let pathTimes: number[];
let pathProjections: Point[];
let droneGraphic: Graphic;
let pathPolyline: Polyline;
let currentSliceEnd: number;
export let dronePathGraphic: Graphic;

export function initializePath(missionData: MissionData) {
  pathPoints = [];
  pathTimes = [];
  pathProjections = [];
  missionData.telemetryList.forEach(telemetry => {
    pathPoints.push(new Point({
      spatialReference: spatialReference,
      latitude: telemetry.latitude,
      longitude: telemetry.longitude,
      hasZ: true,
      z: telemetry.relativeAltitude,
    }));
    pathTimes.push(
      telemetry.timestamp
    );

    const heading: number = telemetry.yaw;
    const yLocal = Math.tan(telemetry.pitch) * telemetry.relativeAltitude;
    const xLocal = -Math.tan(telemetry.roll) * telemetry.relativeAltitude;
    const yAbs = Math.cos(heading) * yLocal + Math.sin(heading) * xLocal;
    const xAbs = Math.sin(heading) * yLocal + Math.cos(heading) * xLocal;

    const SMR = new LatLon(telemetry.latitude, telemetry.longitude, 0);
    const ned = new Ned(yAbs, xAbs, 0);
    const pointLOS = SMR.destinationPoint(ned);
    pathProjections.push(new Point({
      spatialReference: spatialReference,
      latitude: pointLOS.latitude,
      longitude: pointLOS.longitude,
      hasZ: true,
      z: 0,
    }));
  });
  console.log("Calculations done");
  pathPolyline = new Polyline({
    spatialReference: spatialReference,
    hasZ: true,
  });
  pathPolyline.addPath(pathPoints);

  dronePathGraphic = new Graphic({
    geometry: pathPolyline,
    symbol: pathSymbol
  });
  const pathProjectionGraphic = new Graphic ({
    geometry: pathPolyline,
    symbol: pathProjectionSymbol
  });

  dronePathsLayer.add(dronePathGraphic);
  pathProjectionLayer.add(pathProjectionGraphic);
  currentSliceEnd = pathPoints.length;
  createDrone();
}

export function updatePath(index: number) {
  if (currentSliceEnd == index) {
    return;
  }

  pathPolyline = new Polyline({
    spatialReference: spatialReference,
    hasZ: true,
  });
  pathPolyline.addPath(pathPoints.slice(0, index + 1));

  dronePathsLayer.graphics.forEach((pathGraphic: Graphic) => {
    pathGraphic.geometry = pathPolyline;
  });

  if (sensorRayLayer.visible) {
    const rayPolyline = new Polyline({
      spatialReference: spatialReference,
      hasZ: true,
    });
    rayPolyline.addPath([
      pathPoints[index],
      pathProjections[index],
    ]);

    const rayGraphic = new Graphic({
      geometry: rayPolyline,
      symbol: raySymbol,
    });

    sensorRayLayer.removeAll();
    sensorRayLayer.add(rayGraphic);
  }

  currentSliceEnd = index;
}

export function createDrone() {
  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: pathPoints[0].latitude,
    longitude: pathPoints[0].longitude,
    hasZ: true,
    z: pathPoints[0].z,
  });

  const newGraphic = new Graphic({
    geometry: newPoint,
    symbol: selectedDroneSymbol,
    attributes: {
      car: false,
    },
  });
  droneGraphic = newGraphic;
  droneModelLayer.add(droneGraphic);

  focusDrone();
  setViewWatcher();
}

export function updateDrone(index: number) {
  const telemetry = vuexStore.getters["missionPlayer/getCurrentTelemetry"];
  const newPoint = new Point({
    spatialReference: spatialReference,
    latitude: telemetry.latitude,
    longitude: telemetry.longitude,
    hasZ: true,
    z: telemetry.relativeAltitude,
  });

  droneGraphic.geometry = newPoint;

  if (droneGraphic.symbol.type === "point-3d") {
    droneGraphic.symbol = new PointSymbol3D({
      symbolLayers: [
        new ObjectSymbol3DLayer({
          height: droneGraphic.getAttribute("car") ? 2 : drone3DModelSize,
          // anchor: graphic.getAttribute("car") ? "bottom" : "center",
          anchor: "relative",
          anchorPosition: { x: 0, y: 0, z: drone3Danchor },
          resource: droneSymbol3DResource,
          heading: telemetry.track,
          tilt: telemetry.pitch,
          roll: telemetry.roll,
        }),
      ],
    });
  }
}

export function setViewWatcher() {
  watchUtils.whenTrue(view, "stationary", function () {
    if (droneGraphic == null) return;
    if (view.camera.position.distance(droneGraphic.geometry as Point) < distanceTo3DChange) {
      makeDrone3D();
    } else {
      makeDrone2D();
    }
  });
}

function makeDrone3D() {
  const telemetry = vuexStore.getters["missionPlayer/getCurrentTelemetry"];
  droneGraphic.symbol = new PointSymbol3D({
    symbolLayers: [
      new ObjectSymbol3DLayer({
        height: drone3DModelSize,
        //anchor: graphic.getAttribute("car") ? "bottom" : "center",
        anchor: "relative",
        anchorPosition: { x: 0, y: 0, z: drone3Danchor },
        resource: droneSymbol3DResource,
        heading: telemetry.track,
        tilt: telemetry.pitch,
        roll: telemetry.roll,
      }),
    ],
  });
}

function makeDrone2D() {
  droneGraphic.symbol = selectedDroneSymbol;
}

export function clearPath() {
  pathPoints = [];
  pathTimes = [];
  pathProjections = [];
  layerFP.removeAll();
  layerFP2.removeAll();
  pathProjectionLayer.removeAll();
  sensorRayLayer.removeAll();
  console.log("paths destroyed");
}

export function clearDrone() {
  droneLayer.removeAll();
  droneModelLayer.removeAll();
  console.log("drone destroyed");
}

export function focusDrone() {
  const point = new Point();
  point.copy(droneGraphic.geometry as Point);
  point.z = point.z + 75;
  point.y = point.y - 125;
  focusOn(point);
}