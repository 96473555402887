<template>
  <div>
    <h4>Pitch Angle:</h4>
    <v-slider
      v-model="pitch"
      class="align-center"
      :max="pitchMax"
      :min="pitchMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="pitch"
          suffix="º"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 60px"
          :rules="[rules.pitchMax, rules.pitchMin]"
        ></v-text-field>
      </template>
    </v-slider>
    <h4>Roll Angle:</h4>
    <v-slider
      v-model="roll"
      class="align-center"
      :max="rollMax"
      :min="rollMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="roll"
          suffix="º"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 60px"
          :rules="[rules.rollMax, rules.rollMin]"
        ></v-text-field>
      </template>
    </v-slider>
    <h4>Yaw Angle:</h4>
    <v-slider
      v-model="yaw"
      class="align-center"
      :max="yawMax"
      :min="yawMin"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          v-model="yaw"
          suffix="º"
          class="mt-0 pt-0"
          single-line
          type="number"
          style="width: 60px"
          :rules="[rules.yawMax, rules.yawMin]"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script>
import { getMagnitudeBounds } from "../../utils/Commands";
export default {
  data() {
    return {
      pitchMin: 0,
      pitchMax: 100,
      rollMin: 0,
      rollMax: 100,
      yawMin: 0,
      yawMax: 100,
      pitch: 0,
      roll: 0,
      yaw: 0,
      rules: {
        pitchMax: (value) => value <= this.pitchMax || "Too large",
        pitchMin: (value) => value >= this.pitchMin || "Too small",
        rollMax: (value) => value <= this.rollMax || "Too large",
        rollMin: (value) => value >= this.rollMin || "Too small",
        yawMax: (value) => value <= this.yawMax || "Too large",
        yawMin: (value) => value >= this.yawMin || "Too small",
      },
    };
  },

  created: function () {
    this.pitchMin = getMagnitudeBounds("gimbalturn").pitch.min;
    this.pitchMax = getMagnitudeBounds("gimbalturn").pitch.max;
    this.pitch = getMagnitudeBounds("gimbalturn").pitch.default;

    this.rollMin = getMagnitudeBounds("gimbalturn").roll.min;
    this.rollMax = getMagnitudeBounds("gimbalturn").roll.max;
    this.roll = getMagnitudeBounds("gimbalturn").roll.default;

    this.yawMin = getMagnitudeBounds("gimbalturn").yaw.min;
    this.yawMax = getMagnitudeBounds("gimbalturn").yaw.max;
    this.yaw = getMagnitudeBounds("gimbalturn").yaw.default;
  },

  methods: {
    emitParameters() {
      this.$emit("command-parameters", {
        pitch: this.pitch,
        roll: this.roll,
        yaw: this.yaw,
      });
    },
  },
};
</script>